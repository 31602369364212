import React from 'react';
import {configData} from "../config.js";
import '../styles.scss';

import { 
  formatDate, fetchSigned,  
} from '../shared/Utilities.js'
import DelayedTooltip from "../components/general/DelayedTooltip.js";
import UnpublishedEditsIcon from "../components/general/UnpublishedEditsIcon.js";
import {
  OwcTable, OwcTableHeader, OwcTableHeaderCell,
  OwcTableBody, OwcTableRow, OwcTableCell,
  OwcTypography, OwcPagination,
  OwcInput, OwcIconButton, OwcIcon, OwcButton
} from '@one/react';

/**
 * The a navigation control for selecting use cases
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class UseCaseNavigation extends React.Component {

  ACCOUNT_MAPPINGS_NEED_ATTENTION = "Account Mappings";
  DEVICE_MAPPINGS_NEED_ATTENTION = "Device Mappings";
  NEEDS_ATTENTION_FLAG = "Needs Attention";
  ANY_REASON_FLAG = "Any Reason";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      searchBy: "Name",
      searchTerm: "",
      sortBy: "Date Created",
      sortList: ["Date Created",
        "Date Updated",
        "Name",],
      searchList: ["Name",
        "Contact Email",
        "Description",
        "Data Description",],
      purposeFilter: "-1",
      purposeList: [],
      governanceApprovalFilter: "All",
      governanceApprovalList: ["All", "Approved", "Not Recorded", "Denied"],
      statusFilter: "All",
      statusList: ["All", "Published", "All Unpublished Edits", "Published With Unpublished Edits"],
      page: 1,
      rowsPerPage: 10,
      totalPages: 1,
    };
  }

  /*** helper method to clear all of the filter options and return the dictionary to be applied to the state
   * @param inputDict optional a dictionary to make the changes to
   */
  clearFilters(inputDict={}){
    inputDict.searchBy = "Name";
    inputDict.searchTerm = "";
    inputDict.sortBy = "Date Created";
    inputDict.purposeFilter="-1";
    inputDict.governanceApprovalFilter="All";
    inputDict.statusFilter="All";
    return inputDict;
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    let mustRefreshResults = false;

    // we have been signalled to update
    if ((this.props.updateFlag !== prevProps.updateFlag) || mustRefreshResults) {
      this.loadSearchResults();
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the purpose list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredPurposeList = result.filter(value =>
            value.type === "Use Case Purpose"
          );
          // Add the all option
          filteredPurposeList.unshift({refListId:-1,description:"All"});
          const newStateChanges = { purposeList: filteredPurposeList };
          this.setState(newStateChanges, () => this.loadSearchResults());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error: "Error Loading Reference Data " + error
          });
        }
      )
  }

  /**
   * Loads the results of the search from the DB into the state
   * @param resetPage True if the pagination of the results should be reset to 1 (default true)
   */
  loadSearchResults(resetPage=true) {
    const pageNumber = resetPage? 1: this.state.page
    this.setState({
      isLoaded: false,
      page: pageNumber,
      error: null
    });
    //gather searchData
    const searchData = {
      searchBy: this.state.searchBy,
      searchTerm: this.state.searchTerm,
      sortBy: this.state.sortBy,
      purposeFilter: this.state.purposeFilter === "-1" ? null : this.state.purposeFilter,
      governanceApprovalFilter: this.state.governanceApprovalFilter === "All" ? null : this.state.governanceApprovalFilter,
      statusFilter: this.state.statusFilter === "All" ? null : this.state.statusFilter,
      limit: this.state.rowsPerPage,
      offset: (pageNumber - 1) * this.state.rowsPerPage,
    };

    console.log(JSON.stringify(searchData));

    fetchSigned(configData.USECASE_API_URL + "search/", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(searchData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          const maxPage = Math.ceil(result.totalResultCount / this.state.rowsPerPage);
          this.setState({
            isLoaded: true,            
            totalPages: maxPage,
            items: result.searchResults,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (errorMsg) => {
          this.setState({
            isLoaded: true,
            error: "Error Loading use cases" + errorMsg
          });
        }
      )
  }

  /**
   * Gets the refListId for a reference list text value
   * @param value The text value of the reference list entry
   * @param refdataList The in memory reference list to search for the corresponding refListId
   * @returns The matching refListId or null if a match cannot be found
   */
  getIdForRefData(value, refdataList) {
    let retVal = null;
    const searchText = value.endsWith(configData.REFDATA_DEPRECATED)? 
                          value.slice(0, -configData.REFDATA_DEPRECATED.length)
                          : value;

    const refDataItem = refdataList.find(({ description }) => description === searchText);
    //get the record
    if (refDataItem !== undefined) {
      retVal = refDataItem.refListId;
    }
    return retVal;
  }

  /**
   * Handles the click of the reset button
   */
  handleResetClick() {
    const newState = this.clearFilters();
    this.setState(newState, ()  => this.loadSearchResults());
  }

  /**
   * Handles changes to the pagination of the results
   * @param {*} ev The event object that describes the change
   */
  handlePageChange(ev) {
    const stateChanges = {};
    if (ev.pageSize !== this.state.rowsPerPage) {
      stateChanges.rowsPerPage = ev.pageSize;
    }
    if (ev.page !== this.state.page) {
      stateChanges.page = ev.page;
    }
    //if there are any state changes
    if (stateChanges)
    {
      this.setState(stateChanges, () => this.loadSearchResults(false));
    }
  }

  
  /**
   * Handles changes to the sort listbox
   * @param {*} ev The event object that describes the change
   */
  handleSortChange(ev) {
    if (ev.target.value !== this.state.sortBy) {
      this.setState(
        {
          sortBy: ev.target.value,
          page: 1,
        },
        () => this.loadSearchResults());
    }
  }

  /**
   * Handles clicks on the search button
   * @param {*} ev The event object that describes the change
   */
  handleSearchClick(ev) {
    this.loadSearchResults();
  }

  /**
   * Handles Pressing return in the search textbox to trigger a search
   * @param {*} ev The event object that describes the change
   */
  handleSearchKeyUp(ev) {
    // Number 13 is the "Enter" key on the keyboard
    this.setState({ searchTerm: ev.target.value });
    if (ev.keyCode === 13) {
      // Cancel the default action, if needed
      ev.preventDefault();
      // Start the search
      this.loadSearchResults();
    }
  }

  /**
   * Handles clicks on the edit button for a use case
   * Passthrough method to this.props.onEditClick(ev, useCaseId)
   * @param {*} ev The event object that describes the change
   * @param useCaseId The useCaseId of the use case to be edited
   */
  handleEditClick(ev, useCaseId){
      this.props.onEditClick(ev, useCaseId);
  }


  /**
   * Renders the use case table control
   * @returns The JSX of the controls
   */
  renderTable() {
    const error = this.state.error;
    const isLoaded = this.state.isLoaded;
    const items = this.state.items;

    if (error) {
      return <div style={{ paddingTop: "1em", paddingBottom: "23em" , color: "red"}}>Error: {error}</div>;
    } else {
      return (
        <OwcTable key={"UseCaseNavigationTable"} style={{ display: "block"}} size='small' elevated showRowExpanderColumn >
          <OwcTableHeader sticky>
              <OwcTableHeaderCell key="IdHeader" resizable width="10%">Use Case Id</OwcTableHeaderCell>
              <OwcTableHeaderCell key="NameHeader" resizable>Use Case Name</OwcTableHeaderCell>
              <OwcTableHeaderCell key="StatusHeader" resizable width="10%">Status</OwcTableHeaderCell>
              <OwcTableHeaderCell key="ActionsHeader" resizable width="9%">Actions</OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody loading={!isLoaded}>
            {items.map(item => this.renderTableRow(item))}
          </OwcTableBody>
        </OwcTable>
      );
    }
  }


  /**
   * Renders a row in the agreement serach results table
   * @param {*} item The data item for the agreement
   * @returns The JSX of rendered components
   */
  renderTableRow(item){
    const keyEnding = item.useCaseId;
    let rowColour = configData.COLOUR_WHITE;
    let tooltip = "";

    let approvedIconName = "circle_help";
    let approvedIconColour = "var(--one-color-orange-600)";
    let approvedTooltip = "Data access decision is not recorded";
    if (item.dataAccessApproved !== null) {
      if (item.dataAccessApproved === true) {
        approvedIconName = "circle_confirm";
        approvedIconColour = "var(--one-color-green-500)";
        approvedTooltip = "Data access approved";
      } else {
        approvedIconName = "circle_clear";
        approvedIconColour = "var(--one-color-red-500)";
        approvedTooltip = "Data access denied";
      }
    }

    return (
      <OwcTableRow
          key={"row" + keyEnding}
          id={"row" + keyEnding} 
          expandable 
          style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour}: {}}
          ondblclick={(ev) => this.handleEditClick(ev, keyEnding)}>
        <OwcTableCell 
            style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"idCell" + keyEnding}>
          {item.useCaseId}
        </OwcTableCell>
        <OwcTableCell 
            style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"titleCell" + keyEnding}>
          {item.name}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"useCaseStatusCell" + keyEnding}>
          <div style={{ display: "flex"}}>
            <OwcIcon key={"iconApprovedStatus" + keyEnding} 
              id={"iconApprovedStatus" + keyEnding} 
              name={approvedIconName} family="filled"
              style={{fontSize:"1.5em", color: approvedIconColour}} />
            <DelayedTooltip key={"iconApprovedStatusTooltip" + keyEnding} anchor={"iconApprovedStatus" + keyEnding}>
              {approvedTooltip}</DelayedTooltip>          
            {item.versionNo !== null
            ?<>
                <OwcIcon key={"iconPublishedStatus" + keyEnding} 
                  id={"iconPublishedStatus" + keyEnding} 
                  name="certificate" variant="success"
                  style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} />
                <DelayedTooltip key={"iconPublishedStatusTooltip" + keyEnding} anchor={"iconPublishedStatus" + keyEnding}>
                  Use case version {item.publishedVersionNo} has been published on {formatDate(item.datePublished)}</DelayedTooltip>          
              </>
            :<div key={"iconPublishedStatusSpacer" + keyEnding} style={{height:"0.95em", width:"1.5em"}} />
            }
            {((item.datePublished !== null && item.dateUpdated > item.datePublished) || item.datePublished === null)
            ? 
              <>
              <div key={"iconVerifiedStatusSpacer" + keyEnding} style={{height:"0.95em", width:"0.2em"}} />
              <UnpublishedEditsIcon id={keyEnding} 
                iconSize="1.5em"
                iconTooltip={"Use case contains unpublished edits, last edited on " + formatDate(item.dateUpdated)}/>
              </>
            :
              <div key={"iconVerifiedStatusSpacer" + keyEnding} style={{height:"0.95em", width:"1.5em"}} />
            }
          </div>
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"useCaseActionsCell" + keyEnding}>
          <div style={{ display: 'flex', flexDirection:"row" }}>
            <OwcIconButton style={{display: "inline-block"}}  
                key={"iconBtnEdit" + keyEnding} 
                id={"iconBtnEdit" + keyEnding}
                elevated={!this.props.iconFlat}
                variant="secondary"
                size="xSmall"
                onClick={(ev) => this.handleEditClick(ev, keyEnding)}>
              {
                this.props.isReadOnly 
                  ? <OwcIcon name="element_show" />
                  : <OwcIcon name="edit" />
              }
            </OwcIconButton>
            <DelayedTooltip key={"iconBtnEditTooltip" + keyEnding} anchor={"iconBtnEdit" + keyEnding}>
              Edit this use case
            </DelayedTooltip>    
          </div>
        </OwcTableCell>
        <div key={"expandedRow" + keyEnding} slot="expanded" style={{ display: 'flex', flexDirection:"column" }}>
          {this.renderTableRowExtended(item)}
        </div>
        <DelayedTooltip key={"rowUseCaseToolTip" + keyEnding}
            disableTriggers = {(tooltip === "")}
            anchor={"row" + keyEnding}>
         {tooltip}
        </DelayedTooltip>
      </OwcTableRow>
    );
  }

  renderTableRowExtended(item){
    return (
      <>
        <div>
          <b>Valid from:</b> {item.validFrom === null ? "undefined date" : formatDate(item.validFrom)}&nbsp; 
          <b>to:</b> {item.validTo === null ? "undefined date" : formatDate(item.validTo)}
        </div>
        <div style={{whiteSpace:"pre-wrap"}}><b>Use Case Description:</b> <br/>{item.description===null?" ":item.description}</div>
        <div style={{whiteSpace:"pre-wrap"}}><b>Data Description:</b> <br/>{item.dataDescription===null?" ":item.dataDescription}</div>
      </>
    );
  }

  /**
   * Renders the tab specific filter controls for the Map to Accounts Tab
   * @returns The JSX of the controls
   */
  renderSelectFilter(title, stateKey, optionList, widthString="100%", textField=undefined, keyField=undefined) {
    return(
      <tr>
        <td> <OwcTypography>{title}</OwcTypography> </td>
        <td>
          <select style={{ width: widthString, position: "relative", zIndex:"2"}}
            value={this.state[stateKey]}
            onChange={(ev) => {
                if (ev.target.value !== this.state[stateKey]) {
                const newState = {};
                newState[stateKey] = ev.target.value;
                this.setState(newState, () => this.loadSearchResults());
              }
            }}
          >
            {optionList.map((item,index) => {
              if (textField === undefined) {
                return(
                  <option key={"filter" + stateKey + index}>{item}</option>
                );
              } else if (keyField === undefined) {
                return(
                  <option key={"filter" + stateKey + index}
                  >{item[textField]}</option>
                );
              } else {
                return(
                  <option key={"filter" + stateKey + index}
                  value={item[keyField]}
                  >{item[textField]}</option>
                );
              }
            }
            )}
          </select>
        </td>
      </tr>
    );
  }
  
  /**
 * Renders the affiliate filter control
 * @returns The JSX of the controls
 */
  renderSearchAndSort() {
    return (
      <div style={{display:"flex", justifyContent:"space-between", 
                   marginTop:"0.8em", alignItems: "flex-start"}}>
        <div style={{border:"1px solid lightgrey", position: "relative", zIndex:"0"}}>
          <div style={{
                position: "absolute",
                top: "-1em",
                fontSize: "0.75em",
                backgroundColor: "white",
                border: "1px solid white", zIndex:"1"
              }}>
            Search
          </div>
          <table>
            <tbody>
              <tr>
                <td> <OwcTypography style={{position: "relative", zIndex:"2"}}>Search by:</OwcTypography> </td>
                <td>
                  <select style={{ width: "100%", position: "relative", zIndex:"2" }}
                      value={this.state.searchBy}
                      onChange={(ev) => { this.setState({ searchBy: ev.target.value }) }} >
                    {this.state.searchList.map(item => (<option key={"NavSearch" + item}>{item}</option>))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <div style={{display:"flex", alignItems:"center"}}>
                    <OwcInput
                        style={{ width: "100%" }}
                        placeholder="Press Enter to Search..."
                        round={false}
                        variant="filled"
                        no-clear-icon
                        value={this.state.searchTerm}
                        onValueChange={(ev) => this.setState({ searchTerm: ev.detail })}
                        onKeyUp={(ev) => this.handleSearchKeyUp(ev)}>
                      <OwcIconButton slot="prefix" icon="search" onClick={(ev) => this.handleSearchClick(ev)} />
                    </OwcInput>
                    <OwcButton style={{marginLeft:"0.5em"}} elevated
                        onClick={(ev) => this.handleSearchClick(ev)} >
                      Search
                    </OwcButton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style={{border:"1px solid lightgrey", position: "relative", zIndex:"0"}}>
          <div style={{
              position: "absolute",
              top: "-1em",
              fontSize: "0.75em",
              backgroundColor: "white",
              border: "1px solid white", zIndex:"1"}}>
            Filter by
          </div>
            <table><tbody>
            {this.renderSelectFilter("Governance Approval:","governanceApprovalFilter", this.state.governanceApprovalList)}
            {this.renderSelectFilter("Purpose:","purposeFilter",this.state.purposeList, "100%", "description", "refListId")}
            {this.renderSelectFilter("Status:","statusFilter", this.state.statusList)}
          </tbody></table>
        </div>


        <div>
          <table>
            <tbody>          
              <tr>
                <td> <OwcTypography>Sort by:</OwcTypography> </td>
                <td>
                  <select style={{ width: "100%" }}
                      value={this.state.sortBy}
                      onChange={(ev) => this.handleSortChange(ev)}>
                    {this.state.sortList.map(item => (
                      <option key={"NavSort" + item}>{item}</option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
              <td align="left"> 
                      <OwcButton style={{whiteSpace:"nowrap"}} flat
                          onClick={(ev) => this.props.handleAddUseCaseClick(ev)}>
                        <OwcTypography variant="body">Add New Use Case</OwcTypography>
                      </OwcButton>
                    </td>
                <td align="right"> 
                  <OwcButton elevated onClick={() => this.handleResetClick()}>Reset</OwcButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }


  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    //decide based on the space for this control needs to be squeezed
    let fontSize = 0.75;

    // width of the div below is set to 99% to avoid the "wobbles" that can happen when expanding a table row without a slight expansion room to the side.
    return (
      <div className="AgreementNavigation" style={{width:"99%", display:"flex", flexDirection:"column"}}>
        {this.renderSearchAndSort()}
        <br />
        {this.renderTable()}
        {
          this.state.items.length > 0 &&
          (this.state.error === null || this.state.error === undefined || this.state.error.length === 0) && 
          (
            <OwcPagination page={this.state.page} pageSize={this.state.rowsPerPage}
                hidePageSize={false} total={this.state.totalPages} pageSizeOptions={[10,20,50]}
                onPageChange={(ev) => this.handlePageChange(ev.detail)}
                style={{display:"flex", marginLeft: "auto"}}>
              <div slot="rows-per-page"><OwcTypography style={{fontSize:fontSize + "em"}}>Rows per Page</OwcTypography></div>
              <div ><OwcTypography style={{fontSize:fontSize + "em"}}> {this.state.page} of {this.state.totalPages} </OwcTypography></div>
            </OwcPagination>
          )
        }
      </div>
    );
  }
}

export default UseCaseNavigation;
