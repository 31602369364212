import React from 'react';
import { configData } from "../config.js";
import '../styles.scss';

import { 
  formatDate, fetchSigned, convertToDate, truncateDate,
  createTimedMessage, extractTimedMessage, isRecentMessage
} from "../shared/Utilities.js"

import CustomerAgreementDetails from "../components/agreement/CustomerAgreementDetails.js";
import TextWithLookup from "../components/general/TextWithLookup.js";
import DeviceDetails from "../components/device/DeviceDetails.js";
import DelayedTooltip from "../components/general/DelayedTooltip.js";
import DeviceMoveIcon from "../components/device/DeviceMoveIcon.js";
import ProtectedIcon from "../components/device/ProtectedIcon.js";
import AgreementProtectedBanner from "../components/agreement/AgreementProtectedBanner.js";

import {
  OwcButton, OwcExpandable,
  OwcTypography, OwcInput, OwcCheckbox,
  OwcTable, OwcTableHeader, OwcTableHeaderCell, OwcPagination,
  OwcTableBody, OwcTableRow, OwcTableCell, OwcIconButton, OwcDatepicker,
  OwcIcon, OwcProgressSpinner, OwcAssistiveText
} from '@one/react';

/**
 * The interactive form for mapping connected devices an agreement
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class MapDevices extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";
  INST_TYPE_BLANK_VALUE = "Select an Instrument Type";
  COUNTRY_BLANK_VALUE = "Select a Country";
  CONFIRMED_STATUS_CONFIRMED = 1;
  CONFIRMED_STATUS_UNCONFIRMED_CHANGE = 2;
  CONFIRMED_STATUS_NOT_CONFIRMED = 3;
  SEARCH_ROW_LIMIT = 100;

  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedAgreementLoading: false,
      selectedAgreementId: this.props.customerAgreementId,
      submissionState: null,
      agreement: {},
      mappedAccounts: [],
      mappedLocalAgreements: [],
      mappedDevices: [],
      refListsComplete: {},
      searchInstType: this.INST_TYPE_BLANK_VALUE,
      searchSerialNo: "",
      searchLocalAgreementId: "",
      searchLocalAgreementCountry: this.COUNTRY_BLANK_VALUE,
      localAgreementCountries: [],
      localAgreementCountriesLoaded: false,
      searchResults: [],
      manualSearchResults: [],
      searchOngoing: null,
      searchByContractOngoing: null,
      searchError: "",
      searchContractError: "",
      refdataLoaded: false,
      displayDeviceDetails: null,
      isCustomerAgreementExpanded: true,
      isSearchAdditionalExpanded: false,
      isRelatedButNotMappedExpanded: true,
      isMappedAccountsExpanded: true,
      selectedMappingsLoading: true,
      relatedMappingsLoading: true,
      localAgreementsMappingsLoading: true,
      rowsPerPage: 10,
      page: 1,
      totalPages: 1,
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
      this.updateForm(null, this.props.customerAgreementId, true);
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const systemTypesList = result.filter(value =>
            value.type === "Instrument Type"
          );
          this.setState({
            refListsComplete: result,
            referenceTypes: systemTypesList,
            refdataLoaded: true
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

    // load the countries list for local agreements
    fetchSigned(configData.CONTRACT_LINKS_API_URL + "countrylist/")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          localAgreementCountries: result,
          localAgreementCountriesLoaded: true
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )

    this.updateForm(null, this.props.customerAgreementId, true);

  }

  /** Handles the click event on the selection table row
   * @param {*} ev the click event
   * @param {number} agreementId the selected agreement id
   */
  updateForm(ev, agreementId, forceUpdate = false) {
    // if the agreementId is changed then
    if (forceUpdate || (agreementId !== this.state.selectedAgreementId)) {
      // wipe the last agreement
      this.setState({
        selectedAgreementId: agreementId,
        agreement: {},
        submissionState: null,
        mappedAccounts: [],
        mappedDevices: [],
        searchInstType: this.INST_TYPE_BLANK_VALUE,
        searchSerialNo: "",
        searchResults: [],
        searchOngoing: null,
        searchError: "",
        totalPages: 1,
        page: 1,
        searchLocalAgreementId: "",
        searchLocalAgreementCountry: this.COUNTRY_BLANK_VALUE,
        searchByContractOngoing: null,
      });
      this.props.onUnsavedChangesChange(false);
      // load the new agreement data
      this.loadMappedLocalAgreementData(agreementId);
      this.loadAgreementData(agreementId, ()=>{
        this.loadMappedAccountsData(
          agreementId, 
          ()=>{
            this.loadMappedDevicesData(agreementId);
            this.loadRelatedDevicesData(agreementId);
            this.loadMappedLocalAgreementData(agreementId)
          })
        });
    }
  }


  /**
   * Loads the agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   */
  loadAgreementData(agreementId, callback) {
    this.setState({ selectedAgreementLoading: true });
    //get the agreement details
    let url = configData.CONTRACTS_API_URL + agreementId + "/";
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          const row = result[0];
          console.log('Agreement data loaded');
          this.setState({
            agreement: row,
            performSearch: true,
            selectedAgreementLoading: false
          }, ()=>callback());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Loads mapped accounts for agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   */
  loadMappedAccountsData(agreementId, callback) {
    console.log("Loading mapped accounts for ", agreementId)
    this.setState({
      mappedAccounts: [],
      selectedAccountMappingsLoading: true
    });
    //get the agreement details
    let url = configData.ACCOUNT_MAPPING_API_URL + "mapped_accounts/" + agreementId + "/";
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          console.log('Mapped Accounts data loaded');
          this.setState({
            mappedAccounts: result,
            selectedAccountMappingsLoading: false
          }, ()=>callback());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }


   /**
   * Loads mapped local agreements for agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   */
    loadMappedLocalAgreementData(agreementId, callback=()=>{}) {
      console.log("Loading mapped local agreements for ", agreementId)
      this.setState({
        mappedLocalAgreements: [],
        localAgreementsMappingsLoading: true
      });
      //get the agreement details
      let url = configData.CONTRACT_LINKS_API_URL + "mapped-contracts/" + agreementId + "/";
      fetchSigned(url)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            console.log('Mapped local agreements data loaded');
            this.setState({
              mappedLocalAgreements: result,
              localAgreementsMappingsLoading: false
            }, ()=>callback());
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        )
    }

  /**
   * Loads mapped devices for agreement and stores the data in the state
   * @param {*} agreementId the selected agreement id
   */
   loadMappedDevicesData(agreementId) {
    console.log("Loading mapped devices for ", agreementId)
    this.setState({
      mappedDevices: [],
      selectedMappingsLoading: true
    });

    // load the related devices list
    fetchSigned(configData.DEVICE_MAPPING_API_URL + "mapped/" + agreementId)
    .then(res => res.json())
    .then(
      (result) => {
        let changesMade = false;
        console.log('Mapped Device data loaded - starting processing');
        result.forEach(item=>{
            if (this.setMoveSignal(item) === true) {
              changesMade = true;
            };
            if (item.validityStartDate !== null) {
              item.validityStartDate = convertToDate(item.validityStartDate);
            };
            if (item.validityEndDate !== null) {
              item.validityEndDate = convertToDate(item.validityEndDate);
            };
            if (item.localAgreementIds === null) {
              item.localAgreementIds = undefined;
            };
        });
        
        const maxPage = Math.ceil(this.countValidMappings(result) / this.state.rowsPerPage);
        let newState = {
          mappedDevices: result,
          totalPages: maxPage,
          selectedMappingsLoading: false
        };
        console.log('Mapped Device data loaded - completed processing');
        if (changesMade === true) {
          newState.submissionState = this.UNSAVED_CHANGES_MESSAGE;
          this.props.onUnsavedChangesChange(true);
        }
        this.setState(newState);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error,
          selectedMappingsLoading: false
        });
      }
    )
  }


  /**
   * Loads mapped devices for agreement and stores the data in the state
   * @param {*} agreementId the selected agreement id
   */
  loadRelatedDevicesData(agreementId) {
    console.log("Loading related devices for ", agreementId)
    this.setState({
      searchResults: [],
      loadRelatedDevicesOngoing: true
    });

    // load the related devices list
    fetchSigned(configData.DEVICE_MAPPING_API_URL + "related/" + agreementId)
    .then(res => res.json())
    .then(
      (result) => {
        result.forEach(item=>{
              if (item.localAgreementIds === null) {
                item.localAgreementIds = undefined;
              };
          });
        console.log('Related devices data Loaded');
        this.setState({
          searchResults: result,
          loadRelatedDevicesOngoing: false
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error,
          loadRelatedDevicesOngoing: false
        });
      }
    )
  }



  /**
   * Sets a move signal if the device has moved since the last baseline
   * @param {*} row The data defining this device
   * @returns True if changes t the underlying data were made, otherwise false
   */
  setMoveSignal(row) {
    let changesMade = false;
    if (row.lastBaselineDate !== null) {
      if (convertToDate(row.deviceChangeDateCurrent) > convertToDate(row.lastBaselineDate)) {
        if (row.sourceLocationAccountNo !== row.baselineLocationAccountNo && 
            row.sourceLabId !== row.baselineLabId) {
          row.moveSignal = true;
          // this can only happen for an already mapped device, so this could only be the mapping validity ending
          // don't change existing end dates
          if (row.confirmedStatusChangedBy !== null && 
              row.confirmedStatusChangedBy.includes("new instrument")){
            if (row.validityStartDate === null) {
              // we may be able to set the start date
              row.validityStartDate = row.deviceChangeDate;
              row.validityStartDateSuggested = true;
              row.changed = true;
              changesMade = true;
            }
          } else if (this.state.mappedAccounts.findIndex(account => account.accountNumber === row.sourceLocationAccountNo) !== -1) {
            //the device is located at a mapped account
            this.guessValidityDates(row);
          } else if ((this.state.mappedAccounts.findIndex(account => account.accountNumber === row.baselineLocationAccountNo) !== -1) &&
              (this.state.mappedAccounts.findIndex(account => account.accountNumber === row.sourceLocationAccountNo) === -1)) {
            if (row.validityEndDate === null) {
              // we may be able to set the end date
              this.setValidityEndDate(row.deviceId, row.lastBaselineDate, row.baselineLocationAccountNo, row.baselineLabId);
            }
          }
        }
      }
    return changesMade;
    }
  }

  /**
   * Queries the device lifecycle and adds suggested end dates  if appropriate
   * @param {*} deviceId The device id for this device
   * @param {*} lastBaselineDate The date time of the last baseline
   * @param {*} baslineLocationAccountNo The location account of the last baseline
   * @param {*} baselineLabId The lab_id of the last baseline
   */
  setValidityEndDate(deviceId, lastBaselineDate, baslineLocationAccountNo, baselineLabId) {
    const agreementEnd = this.state.agreement.terminationDate;

    //get the device_history records
    fetchSigned(configData.DEVICE_DETAILS_API_URL + "lifecycle/" + deviceId + "/")
    .then(res => res.json())
    .then(
      (result) => {
        let possibleEndDate = null;
        result.forEach(row=> {
          // loop over the records from newest to oldest, extracting the date of the last record that gives a move signal from the baseline
          if (convertToDate(row.changeDate) > convertToDate(lastBaselineDate)) {
            if (row.instrumentLocationAccountNo !== baslineLocationAccountNo && 
                row.LabId !== baselineLabId) {
                  possibleEndDate = row.changeDate;
            }
          }
        });
        
        if (possibleEndDate !== null) {
          const newMappedDevices = this.state.mappedDevices.slice();
          const result = newMappedDevices.find(item => item.deviceId === deviceId);
          if (result !== undefined) {
            // set the dates if they will shorten that agreement validity
            if (possibleEndDate < agreementEnd || agreementEnd === null) {
              result.validityEndDate = convertToDate(possibleEndDate);
              result.validityEndDateSuggested = true;
              result.changed = true;
              this.setState({mappedDevices :newMappedDevices,
                submissionState: this.UNSAVED_CHANGES_MESSAGE
              });
              this.props.onUnsavedChangesChange(true);
            }
          }
        }

      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.error(`error retrieving device lifecycle ${error}`);
      }
    );
  }


  /**
   * Validates the form and submits it to the API if valid
   */
  submitChanges() {
    if (this.state.selectedAgreementId !== null) {
      const submissionData = {
        mappings: this.state.mappedDevices.filter(mapping =>
          mapping.changed === true),
        agreement:  {customerAgreementId: this.state.selectedAgreementId,
          mappingComments: this.state.agreement.deviceMappingComment
          }
      };
  
      // for all the date fields truncate the time section off and convert to UTC to prevent issues with time zones.
      const dateFields = ["validityStartDate", "validityEndDate"];
      submissionData.mappings.forEach((deviceMapping) =>{
        dateFields.forEach((fieldName) => {
          const date = deviceMapping[fieldName];
          if (date !== null && date !== undefined){
            deviceMapping[fieldName] = truncateDate(date);
          }
        });
      });

      console.log("Submitting ...", submissionData);
  
      this.setState({ submissionState: "Saving ..." });
  
      fetchSigned(configData.DEVICE_MAPPING_API_URL + "mapped/", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submissionData)
        })
          .then((response) => {
            console.log(response);
            if (response.status === 201) // inserted successfully
            {
              response.json().then((json) => {
                this.updateForm(null, this.state.selectedAgreementId);
                this.setState({ submissionState: "Device mappings successfully saved" })
                this.props.onAgreementListChanged(this.state.selectedAgreementId);
                console.log("API Response" + JSON.stringify(json));
                this.updateForm(null, this.state.selectedAgreementId, true);
              }).catch((error) => {
                this.setState({ submissionState: "Error saving device mappings " + error });
                console.error(error);
              });
            } else {
              response.json().then((json) => {
                console.log("API Response" + JSON.stringify(json));
                this.setState({ submissionState: "Error saving device mappings " + json.errorText });
              }).catch((error) => {
                this.setState({ submissionState: "Error saving device mappings " + error })
                console.error(error);
              });
            }
          });
      };
  }

  /**
   * Forces the current rows to store to the database and refresh
   * @param {*} ev the event object
   */
  handleSubmitClick(ev) {
    if (this.state.selectedAgreementId !== null) {
      this.submitChanges();
    }
  }

  /**
   * Searches for mappable devices based on the search options
   * @param {*} ev the event object
   */
  handleSearchClick() {
    if (this.state.searchSerialNo !== null) {
      const serialNo = this.state.searchSerialNo.trim();
      const instType = this.state.searchInstType.endsWith(configData.REFDATA_DEPRECATED)?
                          this.state.searchInstType.slice(0, -configData.REFDATA_DEPRECATED.length)
                          : this.state.searchInstType;
      if (serialNo.length === 0 && instType === this.INST_TYPE_BLANK_VALUE)
      {
        this.setState({ searchError: "Please provide both instrument type and serial number"});
      } else if (serialNo.length === 0){
        this.setState({ searchError: "Please provide the serial number"});
      } else if (instType === this.INST_TYPE_BLANK_VALUE) {
        this.setState({ searchError: "Please provide the instrument type"});
      } else {
        this.setState({ searchError: "",
                        searchContractError: "",
                        manualSearchResults: [],
                        searchOngoing: true});

        const submissionData = {
          referenceSystemType: instType,
          serialNo:  serialNo,
          rowLimit:  this.SEARCH_ROW_LIMIT,
        };
    
        console.log("Searching for connected devices ...", submissionData);

        fetchSigned(configData.DEVICE_MAPPING_API_URL + "search-device/", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submissionData)
        })
        .then(res => res.json())
        .then(
          (result) => {
            result.forEach(item=>{
                if (item.localAgreementIds === null) {
                  item.localAgreementIds = undefined;
                };
            });
            this.setState({ manualSearchResults: result,
              searchOngoing: false});
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        )
      }
    }
    
  }


  /**
   * Searches for mappable devices based on the search contract options
   * @param {*} ev the event object
   */
  handleSearchContractClick() {
    if (this.state.searchSerialNo !== null) {
      const localAgreementId = this.state.searchLocalAgreementId.trim().toUpperCase();
      const localAgreementCountry = this.state.searchLocalAgreementCountry;
      if (localAgreementId.length === 0 && localAgreementCountry === this.COUNTRY_BLANK_VALUE)
      {
        this.setState({ searchContractError: "Please provide both country and local agreement id"});
      } else if (localAgreementId.length === 0){
        this.setState({ searchContractError: "Please provide the local agreement id"});
      } else if (localAgreementCountry === this.COUNTRY_BLANK_VALUE) {
        this.setState({ searchContractError: "Please provide the country"});
      } else {
        this.setState({ searchContractError: "",
                        searchError: "",
                        manualSearchResults: [],
                        searchByContractOngoing: true});

        const submissionData = {
          localAgreementId: localAgreementId,
          localAgreementCountry:  localAgreementCountry,
          rowLimit:  this.SEARCH_ROW_LIMIT,
        };
    
        console.log("Searching for connected devices ...", submissionData);

        fetchSigned(configData.CONTRACT_LINKS_API_URL + "search-device/", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submissionData)
        })
        .then(res => res.json())
        .then(
          (result) => {
            result.forEach(item=>{
                if (item.localAgreementIds === null) {
                  item.localAgreementIds = undefined;
                };
            });
            this.setState({ manualSearchResults: result,
                            searchByContractOngoing: false});
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        )
      }
    }
  }


  /**
   * Forces the current rows to refresh from the database
   * @param {*} ev the event object
   */
  handleCancelClick(ev) {
    if (this.state.selectedAgreementId !== null) {
      this.updateForm(ev, this.state.selectedAgreementId, true);
    }
  }


  /**
   * handles toggling of the confirmed checkbox
   * @param {*} ev the event
   * @param {*} deviceId the device id of the selected row
   */
  handleMappingCheckBoxChange(ev, deviceId) {
    const newMappedDevices = this.state.mappedDevices.slice();
    const result = newMappedDevices.find(item => item.deviceId === deviceId);
    if (result !== undefined) {
      if ((ev.type === "inputChange") && (ev.detail === null)) {
        // don't update anything - prevent null entries
        return;
      }
      if ((ev.type === "inputChange") && (ev.detail === false) 
        &&  (result.confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE)) {
        // don't update anything - this only occurs during the unconfirmation of a record in code
        // it cannot happen manually where the transition from unconfirmed will always be to confirmed
        return;
      }
      // store the original value
      if (result.originalStatusCode === undefined) {
        result.originalStatusCode = result.confirmedStatusCode;
        result.changed = true;
      }
      // set the value
      result.confirmedStatusCode = ev.detail ? this.CONFIRMED_STATUS_CONFIRMED : this.CONFIRMED_STATUS_NOT_CONFIRMED;
      result.confirmedStatusChangeDate = new Date(Date.now());
      result.confirmedStatusChangedBy = this.props.userName;
      result.protectedFromUnconfirmation = false;

      if (result.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
        // if this is not supported by any matched accounts or local agreeements then protect it
        if (this.getMatchedByMessageList(result.sourceShipTo,
                                         result.sourceSoldTo,
                                         result.sourceBillTo,
                                         result.sourceLocationAccountNo,
                                         this.getMatchingLocalAgreements(result.localAgreementIds),
                                         true
                                        ).length === 0) {
          result.protectedFromUnconfirmation = true;
        }
        // save the original mapped values
        result.originalAccountName = result.mappedAccountName;
        result.originalInstrumentAddress = result.mappedInstrumentAddress;
        result.originalIDeviceChangeDate = result.deviceChangeDate;
        // set the mapped name and address to match the source name and address
        result.mappedAccountName = result.sourceAccountName;
        result.mappedInstrumentAddress = result.sourceInstrumentAddress;
        result.deviceChangeDate = result.deviceChangeDateCurrent;
      }
      if ((result.confirmedStatusCode === this.CONFIRMED_STATUS_NOT_CONFIRMED) &&
        ("originalAccountName" in result)) {
        // restore the original mapped values
        result.mappedAccountName = result.originalAccountName;
        result.mappedInstrumentAddress = result.originalInstrumentAddress;
        result.deviceChangeDate = result.originalIDeviceChangeDate;
      }

      this.setState({
        mappedDevices: newMappedDevices,
        submissionState: this.UNSAVED_CHANGES_MESSAGE
      });
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * handles removing a mapped account
   * @param {*} ev the event
   * @param {*} deviceId the device Id of the selected row
   */
  handleRemoveMappingClick(deviceId) {
    const newMappedDevices = this.state.mappedDevices.slice();
    const deletedMappedDevice = newMappedDevices.find(item => item.deviceId === deviceId);
    if (deletedMappedDevice !== undefined) {
      // mark the entry as deleted
      deletedMappedDevice.deleted = true;
      deletedMappedDevice.changed = true;

      //mark it as intentionally deleted in the search results
      const newSearchResults = this.state.searchResults.slice();
      const matchingSearchResult = newSearchResults.find(item => item.deviceId === deviceId);
      if (matchingSearchResult !== undefined) {
        matchingSearchResult.deleted = true;
      }
      const maxPage = Math.ceil(this.countValidMappings(newMappedDevices) / this.state.rowsPerPage);
      const newPage = this.state.page > maxPage? 1 : this.state.page;
      this.setState({
        mappedDevices: newMappedDevices,
        searchResults: newSearchResults,
        totalPages: maxPage,
        page: newPage,
        submissionState: this.UNSAVED_CHANGES_MESSAGE,
      });
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * Handles requests to map a device to this account
   * @param {*} row The device to map
   * @returns True if the mapping succeeded otherwise false
   */
  handleMapClick(row) {
    const newMappedDevices = this.state.mappedDevices.slice();
    // only add the device if it is not already present
    const matchedDeviceMapping = newMappedDevices.find(item => item.deviceId === row.deviceId);
    if (matchedDeviceMapping === undefined) {

      let protectedFromUnconfirmation = false;
      // if this is not supported by any matched accounts or local agreeements then protect it
      if (this.getMatchedByMessageList(row.sourceShipTo,
          row.sourceSoldTo,
          row.sourceBillTo,
          row.sourceLocationAccountNo,
          row.localAgreementIds === undefined?undefined:row.localAgreementIds.split(", "), 
          true
          ).length === 0) {
          protectedFromUnconfirmation = true;
        }

      // add a new mapped accountsourceAccountNumber
      const newMapping = {
        customerAgreementId: this.state.selectedAgreementId,
        deviceId: row.deviceId,
        deviceChangeDate: row.deviceChangeDateCurrent,
        deviceChangeDateCurrent: row.deviceChangeDateCurrent,
        serialNo: row.serialNo,
        referenceSystemType: row.referenceSystemType,
        referenceSystemTypeIsActive: row.referenceSystemTypeIsActive,
        systemType: row.systemType,
        accountNumber: row.sourceAccountNumber,
        sourceAccountNumber: row.sourceAccountNumber,
        mappedAccountName: row.sourceAccountName,
        sourceAccountName: row.sourceAccountName,
        mappedInstrumentAddress: row.sourceInstrumentAddress,
        sourceInstrumentAddress: row.sourceInstrumentAddress,
        sourceShipTo: row.sourceShipTo,
        sourceSoldTo: row.sourceSoldTo,
        localAgreementIds: row.localAgreementIds,
        validityStartDate: null,
        validityEndDate: null,
        confirmedStatusCode: this.CONFIRMED_STATUS_CONFIRMED,
        confirmedStatusChangeDate: new Date(Date.now()),
        confirmedStatusChangedBy: this.props.userName,
        deleted: false,
        changed: true,
        protectedFromUnconfirmation: protectedFromUnconfirmation,
      }


      newMappedDevices.push(newMapping);
      const maxPage = Math.ceil(this.countValidMappings(newMappedDevices) / this.state.rowsPerPage);
      this.setState({
        mappedDevices: newMappedDevices,
        submissionState: this.UNSAVED_CHANGES_MESSAGE,
        totalPages: maxPage,
      }, ()=>this.guessValidityDates(row));
      this.props.onUnsavedChangesChange(true);

      return true;
    } else {
      //we found an existing record, but it may be deleted
      if (matchedDeviceMapping.deleted === true) {
        matchedDeviceMapping.deleted = false;
        matchedDeviceMapping.changed = true;
        matchedDeviceMapping.confirmedStatusCode = this.CONFIRMED_STATUS_CONFIRMED;

        const maxPage = Math.ceil(this.countValidMappings(newMappedDevices) / this.state.rowsPerPage);
        let protectedFromUnconfirmation = false;
        // if this is not supported by any matched accounts or local agreeements then protect it
        if (this.getMatchedByMessageList(row.sourceShipTo,
          row.sourceSoldTo,
          row.sourceBillTo,
          row.sourceLocationAccountNo,
          row.localAgreementIds === undefined?undefined:row.localAgreementIds.split(", "), 
          true
          ).length === 0) {
          protectedFromUnconfirmation = true;
        }
        matchedDeviceMapping.protectedFromUnconfirmation = protectedFromUnconfirmation;
        this.setState({
          mappedDevices: newMappedDevices,
          submissionState: this.UNSAVED_CHANGES_MESSAGE,
          totalPages: maxPage,
        }, ()=>this.guessValidityDates(row));
        this.props.onUnsavedChangesChange(true);
        return true;
      } else {
        return false;
      }
    }
  }


  /**
   * Display an overlay with the device detils for the selected device id
   * @param {*} deviceId the selected device id
   */
  handleDisplayDeviceDetails(deviceId) {
    this.setState({displayDeviceDetails:createTimedMessage(deviceId)});
  }

  /**
   * Handle a date change in a date control
   * @param {*} newDate The new date set
   * @param {*} deviceId The device id related
   * @param {*} fieldName The fieldname of this control
   */
  handleDateChange(newDate, deviceId, fieldName) {
    const newMappedDevices = this.state.mappedDevices.slice();
    const result = newMappedDevices.find(item => item.deviceId === deviceId);
    if (result !== undefined) {
      if (newDate === null)
      {
        result[fieldName] = null;
        result.userSetDateNull = true;
        result.changed = true;
        result[fieldName + "Suggested"] = undefined;
        this.setState({mappedDevices: newMappedDevices,
                       submissionState: this.UNSAVED_CHANGES_MESSAGE
        });
        this.props.onUnsavedChangesChange(true);
      } else {
        const newDateParsed = convertToDate(newDate);
        if (newDateParsed !== result[fieldName]) {
          result[fieldName] = newDateParsed;
          result.changed = true;
          result[fieldName + "Suggested"] = undefined;
          //save the change
          this.setState({mappedDevices: newMappedDevices,
                          submissionState: this.UNSAVED_CHANGES_MESSAGE
          });
          this.props.onUnsavedChangesChange(true);
        }
      }
    }
  }


  /**
   * Handles changes to the pagination of the results
   * @param {*} ev The event object that describes the change
   */
  handlePageChange(ev) {
    const stateChanges = {};
    if (ev.pageSize !== this.state.rowsPerPage) {
      stateChanges.rowsPerPage = ev.pageSize;
      const maxPage = Math.ceil(this.countValidMappings(this.state.mappedDevices) / stateChanges.rowsPerPage);
      stateChanges.totalPages = maxPage;
      stateChanges.page=1;
    }
    if (ev.page !== this.state.page) {
      stateChanges.page = ev.page;
    }
      //if there are any state changes
      if (stateChanges)
      {
        this.setState(stateChanges);
      }
  }


  /**
   * Handles when device exclusion has been changed in the device details pop up
   * @param {*} deviceId The changed deviceid
   * @param {*} excluded The current exclusion state
   * @param {*} comment The new comment
   */
  handleDeviceExclusion(deviceId, excluded, comment) {
    console.log(deviceId, excluded, comment);
    if ((this.state.mappedDevices !== undefined) && (this.state.mappedDevices !== null)) {
      const newMappedDevices = this.state.mappedDevices.slice();
      let result = newMappedDevices.find(item => item.deviceId === deviceId);
      if (result !== undefined) {
        result.excluded = excluded;
        result.deviceComment = comment;
        if (result.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED && excluded === true) {
          // if the device is exluded then the trigger will have updated this record to be unconfirmed
          // update the in memory data to match
          result.confirmedStatusCode = this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE;
          result.confirmedStatusChangeDate = new Date(Date.now());
          result.confirmedStatusChangedBy = "device being excluded from mapping";
        }
        this.setState({ mappedDevices: newMappedDevices });
      }
      const newSearchResults = this.state.searchResults.slice();
      result = newSearchResults.find(item => item.deviceId === deviceId);
      if (result !== undefined) {
        result.excluded = excluded;
        result.deviceComment = comment;
        this.setState({ searchResults: newSearchResults });
      }

      const manualSearchResults = this.state.manualSearchResults.slice();
      result = manualSearchResults.find(item => item.deviceId === deviceId);
      if (result !== undefined) {
        result.excluded = excluded;
        result.deviceComment = comment;
        this.setState({ manualSearchResults: manualSearchResults });
      }
    }
  }

  
  /**
   * Counts the number of valid - not deleted records in a list of mappings
   * @param {*} list 
   * @returns 
   */
  countValidMappings(list) {
    const validRecords = list.filter(mapping =>
      mapping.deleted !== true);
    return validRecords.length;
  }

    
  /**
   * For historic records attempt to guess device validity dates based on the presence of mapped accounts in the device lifecycle history
   * @param {*} row The data row to be mapped
   */
  guessValidityDates(row){
    const agreementStart = this.state.agreement.validFrom;
    const agreementEnd = this.state.agreement.terminationDate;

    const deviceId = row.deviceId;
    const matchedDateLatest = row.deviceChangeDate;

    let possibleStartDate = agreementStart;
    let possibleEndDate = agreementEnd;
    const mappedAccounts = this.state.mappedAccounts;

    //get the device_history records
    fetchSigned(configData.DEVICE_DETAILS_API_URL + "lifecycle/" + deviceId + "/")
    .then(res => res.json())
    .then(
      (result) => {
        result.forEach((row, index)=> {
          // loop over the records from newest to oldest, extracting the earliest date these accounts matched
          // and the date after the latest time these records matched
          console.log(row.changeDate);
          let accountMatches = false;
          if ((mappedAccounts.findIndex(account => account.accountNumber === row.shipToAccountNo) !== -1) ||
              (mappedAccounts.findIndex(account => account.accountNumber === row.soldToAccountNo) !== -1) ||
              (mappedAccounts.findIndex(account => account.accountNumber === row.billToAccountNo) !== -1) ||
              (mappedAccounts.findIndex(account => account.accountNumber === row.instrumentLocationAccountNo) !== -1) ) {
                //this record matches the accounts
                accountMatches = true;
              }
          if (row.changeDate > agreementStart && accountMatches) {
            //never set a date if this is the earliest lifecycle record, as we have no information that is was anywhere else before
            if (index !== (result.length-1)) {
              possibleStartDate = row.changeDate;
            } else {
              // the first record in the history was at a matching account, we cannot set a start date (as it might have been there earlier).
              // as we scan from newest to oldest we will only ever run this for the last record, so we can just set the value back to the default
              possibleStartDate = agreementStart;
            }
          }
          // this record is after the latest match and does not match any account
          if ( !accountMatches && row.changeDate > matchedDateLatest) {
            possibleEndDate = row.changeDate;
          }
        });
        if ((possibleStartDate > agreementStart) || (possibleEndDate < agreementEnd)) {
          //a change needs to be made to the state
          const newMappedDevices = this.state.mappedDevices.slice();
          const result = newMappedDevices.find(item => item.deviceId === deviceId);
          if (result !== undefined) {
            // set the dates if they will shorten that agreement validity and a start date has not already been set
            if ((possibleStartDate > agreementStart) && (result.validityStartDate === null)) {
              result.validityStartDate = convertToDate(possibleStartDate);
              result.validityStartDateSuggested = true;
              result.changed = true;
            }
            if ((possibleEndDate < agreementEnd || agreementEnd === null) && (result.validityEndDate === null)) {
              result.validityEndDate = convertToDate(possibleEndDate);
              result.validityEndDateSuggested = true;
              result.changed = true;
            }
            if (result.validityStartDateSuggested === true || result.validityEndDateSuggested === true) {
              this.setState({mappedDevices :newMappedDevices,
                            submissionState: this.UNSAVED_CHANGES_MESSAGE});
              this.props.onUnsavedChangesChange(true);
            }
          }
        }

      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.error(`error retrieving device lifecycle ${error}`);
      }
    );
  }


  /**
   * Gets a formatted string of the mapped and source values
   * @param {*} mappedValue the value when it was mapped
   * @param {*} sourceValue the current value in the source data
   * @returns 
   */
  getMappedInfoMessage(mappedValue, sourceValue, mappedText="Mapped") {
    if (mappedValue === sourceValue) {
      return (<span>{mappedValue}</span>);
    }
    else {
      return (
        <div>
          <OwcTypography  variant="caption" style={{marginTop:"0",marginBottom:"0", whiteSpace: "nowrap"}}>{mappedText}:</OwcTypography>
          <OwcTypography  variant="caption" style={{marginTop:"0",marginBottom:"0", marginLeft:"1em"}}>{mappedValue}</OwcTypography>
          <br/>
          <OwcTypography  variant="caption" style={{marginTop:"0",marginBottom:"0"}}>Now:</OwcTypography>
          <OwcTypography  variant="caption" style={{marginTop:"0",marginBottom:"0", marginLeft:"1em"}}>{sourceValue}</OwcTypography>
        </div>
      );
    }
  }


  /**
   * Gets a formatted string of the mapped and source values for use in a tooltip
   * @param {*} fieldName the name of the field for the tooltip
   * @param {*} mappedValue the value when it was mapped
   * @param {*} sourceValue the current value in the source data
   * @returns 
   */
  getMappedInfoTooltip(fieldName, mappedValue, sourceValue) {
    if (mappedValue === sourceValue) {
      return mappedValue;
    }
    else {
      return `${fieldName} has changed at source since it was mapped`
    }
  }

  /**
   * Gets a formatted string of the confirmed staus for use in a tooltip
   * @param {*} confirmedStatusCode the confirmed status code
   * @param {*} confirmedStatusChangeDate the date the status changed
   * @param {*} confirmedStatusChangeBy who made the change
   */
  getConfirmedStatusTooltip(confirmedStatusCode, confirmedStatusChangeDate, confirmedStatusChangeBy) {
    let dateString = formatDate(confirmedStatusChangeDate, false);
    if (confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
      return `Confirmed on ${dateString} by ${confirmedStatusChangeBy}`;
    }
    else {
      if (confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE) {
        return `Unconfirmed change on ${dateString} by ${confirmedStatusChangeBy}, the mapping needs to be re-confirmed`;
      } else {
        return `Not Confirmed set on ${dateString} by ${confirmedStatusChangeBy}`;
      }
    }
  }


  /**
   * Gets the structured text for how the mapped accounts relate to the device accounts
   * @param {*} item The device data row
   * @param {*} mappedText The term to use for the relation of these accounts (default "Mapped")
   * @returns The JSX of the controls
   */
  getMappedAccountsText(item, mappedText="Mapped") {
    if (item.mappedShipTo !== item.sourceShipTo ||
        item.mappedSoldTo !== item.sourceSoldTo ||
        item.mappedBillTo !== item.sourceBillTo ||
        item.mappedLocationAccountNo !== item.sourceLocationAccountNo) {
          const keyEnding = `${item.deviceId}!${Math.random()}`
      return (
        <span key={"acTextSpan" + keyEnding}>
          <pre key={"acTextPre1-" + keyEnding} style={{marginTop:"0",marginBottom:"0"}}>{mappedText}:</pre>
          <div key={"acTextDiv1-" + keyEnding} style={{marginTop:"0",marginBottom:"0", marginLeft:"1em"}}>
          {this.getMappedAccountsTextForAccounts(item.mappedShipTo,
                                                  item.mappedSoldTo,
                                                  item.mappedBillTo,
                                                  item.mappedLocationAccountNo)}
                                                  
          </div>
          <pre key={"acTextPre2-" + keyEnding} style={{marginTop:"0",marginBottom:"0"}}>Now:</pre>
          <div key={"acTextDiv2-" + keyEnding} style={{marginTop:"0",marginBottom:"0", marginLeft:"1em"}}>
          {this.getMappedAccountsTextForAccounts(item.sourceShipTo,
                                                  item.sourceSoldTo,
                                                  item.sourceBillTo,
                                                  item.sourceLocationAccountNo,
                                                  item.localAgreementIds)}
          </div>
        </span>
      );
    } else {
      return this.getMappedAccountsTextForAccounts(item.sourceShipTo,
                                                  item.sourceSoldTo,
                                                  item.sourceBillTo,
                                                  item.sourceLocationAccountNo,
                                                  item.localAgreementIds);
    }

  }

  /**
   * Gets the text for how the mapped accounts relate to the device accounts
   * @param {*} sourceShipTo The current ship to account number
   * @param {*} sourceSoldTo The current sold to account number
   * @param {*} sourceBillTo The current bill to account number
   * @param {*} sourceLocationAccountNo The current location account number
   * @param {*} sourceLocalAgreementList An optional list of local agreement id's
   * @param {*} matchingLocalAgrementList An optional list of matchung local agreement id's if this is provided sourceLocalAgreementList is not used
   * @returns The text wrapped in a span
   */
  getMappedAccountsTextForAccounts(sourceShipTo, sourceSoldTo, sourceBillTo, sourceLocationAccountNo,
                                   sourceLocalAgreements = undefined, matchingLocalAgrementList = undefined) {
    if (matchingLocalAgrementList === undefined) {
      matchingLocalAgrementList = this.getMatchingLocalAgreements(sourceLocalAgreements)
    }
    const messageList = this.getMatchedByMessageList(sourceShipTo, sourceSoldTo, sourceBillTo, sourceLocationAccountNo, matchingLocalAgrementList);
    if (messageList.length > 0){
      return(
        <span>
          {messageList.map((message) =>(<pre key={sourceShipTo + sourceLocationAccountNo +  "!" + Math.random()} 
            style={{marginTop:"0",marginBottom:"0"}}>{message}</pre>))}
        </span>);
    } else {
      return(
        <span key={sourceShipTo + sourceLocationAccountNo +  "!" + Math.random()} 
          style={{color:"red", overflowWrap:"normal"}}>
          None
        </span>
      );
    }

  }

  /**
   * Returns a list of all the accounts that support this mapping
   * @param {*} sourceShipTo the ship to account for this device
   * @param {*} sourceSoldTo the sold to account for this device
   * @param {*} sourceBillTo the bill to account for this device
   * @param {*} sourceLocationAccountNo the location account for this device
   * @param {*} sourceLocalAgreementList the list of local agreements for this device
   * @param {*} onlyConfirmedAccounts optional if set to true only allow confirmed accounts to create entries
   */
  getMatchedByMessageList(sourceShipTo, sourceSoldTo, sourceBillTo, 
                          sourceLocationAccountNo, matchingLocalAgrementList = [],
                          onlyConfirmedAccounts=false) {
    const messageList = [];
    this.getMatchedAccountMessage(sourceShipTo, "shipTo", onlyConfirmedAccounts, messageList);
    this.getMatchedAccountMessage(sourceSoldTo, "soldTo", onlyConfirmedAccounts, messageList);
    this.getMatchedAccountMessage(sourceBillTo, "billTo", onlyConfirmedAccounts, messageList);
    this.getMatchedAccountMessage(sourceLocationAccountNo, "Location", onlyConfirmedAccounts, messageList);

    if (matchingLocalAgrementList.length > 0) {
      messageList.push("Agreement: " + matchingLocalAgrementList.join(", "));
    }
    return messageList;
  }

  getMatchedAccountMessage(sourceAccountNo, accountNameString, onlyConfirmedAccounts, messageList) {
    const mappedAccounts = this.state.mappedAccounts;
    let matchedAccount = undefined;
    matchedAccount = mappedAccounts.find(account => account.accountNumber === sourceAccountNo);
    if (matchedAccount !== undefined) {
      if (onlyConfirmedAccounts === true) {
        if (matchedAccount.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
          messageList.push(`${accountNameString}: ${sourceAccountNo}`);
        }
      } else {
        messageList.push(`${accountNameString}: ${sourceAccountNo} ${this.getStatusString(matchedAccount.confirmedStatusCode)}`);
      }
    }
  }

  getStatusString(status){
    if (status === this.CONFIRMED_STATUS_CONFIRMED) {
      return "";
    } else if (status === this.CONFIRMED_STATUS_NOT_CONFIRMED) {
      return "[Not confirmed]";
    } else if (status === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE) {
      return "[Unconfirmed]";
    }
  }

  getMatchingLocalAgreements(localAgreementIds = undefined) {
    const sourceLocalAgreementList = localAgreementIds === undefined?undefined:localAgreementIds.split(", ")
    let matchingLocalAgrementList = [];
    if (sourceLocalAgreementList !== undefined) {
      const mappedLocalAgreements = this.state.mappedLocalAgreements;
      sourceLocalAgreementList.forEach((sourceLocalAgreementId) => {
        if (mappedLocalAgreements.findIndex(element => element === sourceLocalAgreementId) !== -1) {
          matchingLocalAgrementList.push(sourceLocalAgreementId);
        }
      });
    }
    return matchingLocalAgrementList;
  }

  /**
   * Renders the Mapped Devices details
   * @returns The JSX of the controls
   */
  renderMappedDevices() {
    const mappedDevices = this.state.mappedDevices;
    if (this.state.selectedMappingsLoading) {
      return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <OwcProgressSpinner style={{ marginTop: '30px' }} />
        </div>
      );
    }
    if (mappedDevices.length === 0) {
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>No Mapped Devices</OwcTypography>
      );
    }
    
    let deletedRecordsSoFar = 0;
    return (
      <div>
        <OwcTable style={{ display: "block" }} size='small' showRowExpanderColumn>
          <OwcTableHeader elevated sticky shrink>
            <OwcTableHeaderCell width="10%" resizable>Confirmed</OwcTableHeaderCell>
            <OwcTableHeaderCell resizable>Instrument Type</OwcTableHeaderCell>
            <OwcTableHeaderCell resizable>Serial Number</OwcTableHeaderCell>
            <OwcTableHeaderCell resizable>Current Device Location</OwcTableHeaderCell>
            <OwcTableHeaderCell resizable>Current Matched By</OwcTableHeaderCell>
            <OwcTableHeaderCell resizable>Current Account Name</OwcTableHeaderCell>
            <OwcTableHeaderCell></OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody>
            {mappedDevices.map((item, index) => {
              if (("deleted" in item) && (item.deleted === true)) {
                  deletedRecordsSoFar++;
                }
                return this.renderMappingRow(item, index, deletedRecordsSoFar);
              })
            }
          </OwcTableBody>
          <div slot="footer">            
            <OwcPagination page={this.state.page} pageSize={this.state.rowsPerPage}
                hidePageSize={false} total={this.state.totalPages} pageSizeOptions={[10,20,50]}
                onPageChange={(ev) => this.handlePageChange(ev.detail)}
                style={{display:"flex", marginLeft: "auto"}}>
              <div slot="rows-per-page"><OwcTypography style={{fontSize:"0.8em"}}>Rows per Page</OwcTypography></div>
              <div ><OwcTypography style={{fontSize:"0.8em"}}> {this.state.page} of {this.state.totalPages} </OwcTypography></div>
            </OwcPagination>
          </div>
        </OwcTable>
      </div>
    );
  }

  /**
   * Renders a mapped device row
   * @param {*} item The mapped device data entry
   * @param {*} index The index of this row in the dataset
   * @param {*} deletedRecordsSoFar The count of deleted records before this one in the dataset
   * @returns The JSX of the controls
   */
  renderMappingRow(item, index, deletedRecordsSoFar=0) {
    if (("deleted" in item) && (item.deleted === true)) {
      return;
    } else {
      // check if this records in in the current page
      const rowNum = index - deletedRecordsSoFar;
      if (rowNum >= ((this.state.page-1) * this.state.rowsPerPage) &&
          rowNum < (this.state.page * this.state.rowsPerPage)) {
        // determine the status and therefore colour of the row
        let rowColour = configData.COLOUR_WHITE
        if (item.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
          if ((item.mappedInstrumentAddress !== item.sourceInstrumentAddress) ||
              (item.mappedAccountName !== item.sourceAccountName)) {
            rowColour = configData.COLOUR_YELLOW;
          } else {
            rowColour = configData.COLOUR_GREEN;
          }
        } 
        
        if (item.confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE) {
          rowColour = configData.COLOUR_YELLOW;
        }

        if (item.excluded === true) {
          rowColour = configData.COLOUR_EXCLUDED;
        }

        const matchingLocalAgrementList = this.getMatchingLocalAgreements(item.localAgreementIds);
        const isSupportedByLocalAgreement = matchingLocalAgrementList.length === 0? false: true;

        const rowId = item.deviceId;
        const addressText = this.getMappedInfoMessage(item.mappedInstrumentAddress, item.sourceInstrumentAddress);
        const accountNameText = this.getMappedInfoMessage(item.mappedAccountName, item.sourceAccountName);
        return (
          <OwcTableRow key={"MappedAccountRow" + rowId} 
            expandable 
            expanded={item.validityStartDate !== null || item.validityEndDate !== null || item.userSetDateNull === true}
          >
            <OwcTableCell key={"ConfirmedCell" + rowId}  id={"ConfirmedCell" + rowId} valign="middle" style={{ backgroundColor: rowColour }}>
              <div style={{display: "flex", flexDirection:"row", alignItems:"center"}}>
                <div id={"ConfirmedCellDiv" + rowId} >
                <OwcCheckbox key={"ConfirmedCellCheckBox" + rowId}
                    id={"ConfirmedCellCheckBox" + rowId}
                    checked={item.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED}
                    disabled={isSupportedByLocalAgreement}
                    indeterminate={item.confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE}
                    onValueChange={(ev) => this.handleMappingCheckBoxChange(ev, item.deviceId)}
                     />
                </div>
                {
                  item.changed === true
                  ?(
                  <div>
                    <OwcIcon id={"mappingChangedIcon" + rowId}
                      key={"mappingChangedIcon" + rowId}
                      name="save" style={{ display: "inline-block", verticalAlign: "top", fontSize:"1.75em"}} />
                    <DelayedTooltip key={"ConfirmedCellToolTip" + rowId}
                        anchor={"mappingChangedIcon" + rowId} 
                        placement="left">
                      This mapping has been changed and will be stored when you click Save Changes.
                    </DelayedTooltip>
                  </div>
                  )
                  : ""
                }
                {
                  item.protectedFromUnconfirmation === true
                  ? ( 
                    <ProtectedIcon  key={"MappedAccountRowProtectedIcon" + rowId} 
                      id={"MappedAccountRowProtectedIcon" + rowId}  
                      iconTooltip="This device mapping is protected from automated unconfirmation as it is not supported by any confirmed accounts or local agreements."
                      iconSize="1.75em">
                    </ProtectedIcon>
                  )
                  : ""
                }
              </div>
            </OwcTableCell>
            <DelayedTooltip key={"ConfirmedCellToolTip" + rowId}
                anchor={"ConfirmedCellDiv" + rowId}
                placement="left">
              {(item.excluded === true)?
                (
                <>
                {item.deviceComment === null?
                  "This device is marked as excluded"
                :
                  `This device is marked as excluded from the data product, with the comment "${item.deviceComment}"`
                }
                </>
                )
              :
                this.getConfirmedStatusTooltip(item.confirmedStatusCode, item.confirmedStatusChangeDate, item.confirmedStatusChangedBy)
              }
            </DelayedTooltip>
            <OwcTableCell key={"systemTypeCell" + rowId} valign="middle" style={{ backgroundColor: rowColour, wordBreak:"normal" }}>
              {item.referenceSystemType + (item.referenceSystemTypeIsActive===false?configData.REFDATA_DEPRECATED:"")}
            </OwcTableCell>
            <OwcTableCell key={"SerialNoCell" + rowId} valign="middle" style={{ backgroundColor: rowColour }}>
              <div style={{display:"flex", flexDirection:"row"}} >
                <TextWithLookup 
                  text={item.serialNo} 
                  iconTooltip="Show details for this device"
                  onClick={()=>this.handleDisplayDeviceDetails(item.deviceId)} />
                {this.renderDeviceMoveSignal(item)}
              </div>
            </OwcTableCell> 
            <OwcTableCell key={"InstAddrCell" + rowId}
              valign="middle" style={{ backgroundColor: rowColour, wordBreak:"normal"}}>
              {addressText}
            </OwcTableCell>
            <DelayedTooltip key={"InstAddrToolTip" + rowId}
              anchor={"InstAddrCellText" + rowId}>
              {this.getMappedInfoTooltip("Address", item.mappedInstrumentAddress, item.sourceInstrumentAddress)}
            </DelayedTooltip>         
            <OwcTableCell key={"MappedAccountsCell" + rowId} valign="middle" style={{ backgroundColor: rowColour }}>
              {this.getMappedAccountsTextForAccounts(
                item.sourceShipTo,
                item.sourceSoldTo,
                item.sourceBillTo,
                item.sourceLocationAccountNo,
                item.localAgreementIds,
                matchingLocalAgrementList
              )}
            </OwcTableCell>
            <OwcTableCell key={"AccountNameCell" + rowId}
                valign="middle" style={{ backgroundColor: rowColour, wordBreak:"normal"}}>
              {accountNameText}
            </OwcTableCell>
            <DelayedTooltip key={"AccountNameToolTip" + rowId}
              anchor={"AccountNameCellText" + rowId}>
              {this.getMappedInfoTooltip("Name", item.mappedAccountName, item.sourceAccountName)}
            </DelayedTooltip>
            <OwcTableCell key={"DeviceRemoveCell" + rowId} valign="middle" style={{ backgroundColor: rowColour }}>
              <OwcIconButton id={"DeviceRemovBtn" + rowId}
                key={"DeviceRemovBtn" + rowId}
                icon="circle_clear" style={{ display: "inline-block", verticalAlign: "top" }}
                disabled={isSupportedByLocalAgreement}
                onclick={() => this.handleRemoveMappingClick(item.deviceId)} />
            </OwcTableCell>
            <DelayedTooltip key={"DeviceRemovBtnToolTip" + rowId}
              anchor={"DeviceRemovBtn" + rowId}
              placement="right">
              Remove this mapped device
            </DelayedTooltip>
            <div key={"expandedRow" + rowId} 
                style={{display:"flex", flexDirection:"row", alignItems:"baseline", gap:"1em", marginBottom:"0.5em"}} 
                slot="expanded" >
              <OwcTypography>Coverage Start</OwcTypography>
              <OwcDatepicker autoClose label="Validity Start"
                  value={item.validityStartDate} format={"dd-MMM-yyyy"}
                  maxDate={this.getMaxDate(this.state.agreement, item, isSupportedByLocalAgreement)
                  }
                  onValueChange={(ev) => this.handleDateChange(ev.detail, item.deviceId, "validityStartDate")}
                  disabled={isSupportedByLocalAgreement}
                  validity={isSupportedByLocalAgreement===false?{
                    state:
                        (
                          this.state.agreement.validFrom !== null &&
                          item.validityStartDate !== null &&
                          convertToDate(item.validityStartDate) < convertToDate(this.state.agreement.validFrom)
                        ) || item.validityStartDateSuggested === true
                      ? "warning" : "valid"
                  }:null}>
                <OwcAssistiveText wrap>
                  {item.validityStartDateSuggested === true
                    ? `Suggested start date, not saved yet`
                    : `Agreement valid from ${
                      this.state.agreement.validFrom === null
                        ? "undefined date" 
                        : formatDate(this.state.agreement.validFrom)}`
                  }
                </OwcAssistiveText>
              </OwcDatepicker>
              <OwcTypography>Coverage End</OwcTypography>
              <OwcDatepicker autoClose label="Validity End"
                  value={item.validityEndDate} format={"dd-MMM-yyyy"}
                  minDate={this.getMinDate(this.state.agreement, item, isSupportedByLocalAgreement)}
                  disabled={isSupportedByLocalAgreement}
                  validity={isSupportedByLocalAgreement?{
                    state: 
                      (
                        this.state.agreement.terminationDate !== null &&
                        item.validityEndDate !== null &&
                        this.state.agreement.entitlementDateValidityVaries !== true &&
                        convertToDate(item.validityEndDate) > convertToDate(this.state.agreement.terminationDate)
                      ) || item.validityEndDateSuggested === true
                      ? "warning" : "valid"
                  }:null}
                  onValueChange={(ev) => this.handleDateChange(ev.detail, item.deviceId, "validityEndDate")}>
                <OwcAssistiveText wrap>
                  {
                    item.validityEndDateSuggested === true
                    ? `Suggested end date, not saved yet`
                    : `Agreement Termination Date ${
                      this.state.agreement.terminationDate === null
                      ? "is not defined"
                      : this.state.agreement.entitlementDateValidityVaries === true
                        ? "varies by entitilement"
                        : formatDate(this.state.agreement.terminationDate)
                      }`
                  }
                </OwcAssistiveText>
              </OwcDatepicker>
            </div>
          </OwcTableRow>
        );
      }
    }
  }

  getMinDate(agreement, item, isSupportedByLocalAgreement) {
    let retVal = -8640000000000000; // default value
    if (isSupportedByLocalAgreement === false) {
      if (agreement.validFrom === null) {
        retVal = item.validityStartDate
      } else {
        if (convertToDate(agreement.validFrom) < item.validityStartDate) {
          retVal = convertToDate(agreement.validFrom)
        } else {
          retVal = item.validityStartDate
        }
      }
    }
    return retVal
  }

  getMaxDate(agreement, item, isSupportedByLocalAgreement) {
    let retVal = 8640000000000000; // default value
    if (isSupportedByLocalAgreement === false) {
      if (agreement.terminationDate === null) {
        retVal = item.validityEndDate
      } else {
        if (convertToDate(agreement.terminationDate) < item.validityEndDate) {
          retVal = convertToDate(agreement.terminationDate)
        } else {
          retVal = item.validityEndDate
        }
      }
    }
    return retVal
  }

  /**
   * Renders a device move signal indicator
   * @param {*} row The dewvice data row
   * @returns the jsx of he device move icon
   */
  renderDeviceMoveSignal(row) {
    if (row.moveSignal !== undefined) {
      return (
        <DeviceMoveIcon
          id={`${row.deviceId}|${row.changeDate}`}
          iconTooltip={`Device move signal since the last location baseline of ${formatDate(row.lastBaselineDate)}`}
        />
      );
    }
  }


  /**
   * Renders the current mapped Devices table
   * @returns The JSX of the controls
   */
  renderMappingComments() {
    return (
      <OwcInput
        key="mappingCommentsTextArea"
        rows={this.state.agreement.deviceMappingComment == null
          ? 4
          : Math.max(4, this.state.agreement.deviceMappingComment.split(/\r\n|\r|\n/).length)}
        cols="100"
        label="Mapping Comments"
        style={{display:"flex", width:"100%"}}
        value={this.state.agreement.deviceMappingComment}
        onValueChange={(ev) => {
          const updatedAgreement = { ...this.state.agreement };
          updatedAgreement.deviceMappingComment = ev.detail;
          this.setState({
            agreement: updatedAgreement,
            submissionState: this.UNSAVED_CHANGES_MESSAGE,
          });
          this.props.onUnsavedChangesChange(true);
        }}
        type="textarea" resizable={false}
      />
    );
  }


  /**
   * Renders the search options controls
   * @returns The JSX of the controls
   */
  renderSearchOptions() {
    return (
      <div>
        <table width="100%" cellSpacing="0">
          <tbody>
            <tr valign="centre" id="SearchBySerialNoTitleRow">
              <td colSpan="5" 
                  style={{ borderTopStyle:"solid",borderLeftStyle:"solid",borderRightStyle:"solid", borderWidth:"1px", borderColor:"silver"}}
              ><OwcTypography style={{"fontWeight": "bold"}}>Search By Serial No</OwcTypography></td>
            </tr>
            <tr valign="centre" id="SearchBySerialNoRow">
              <td style={{ "fontWeight": "bold", paddingRight:"1em", borderBottomStyle:"solid",borderLeftStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>Instrument Type</td>
              <td style={{paddingRight:"1em", borderBottomStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>
                {this.renderInstTypeSelectList()}
              </td>
              <td style={{ "fontWeight": "bold", paddingRight:"1em", borderBottomStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>Serial Number</td>
              <td  style={{paddingRight:"1em", borderBottomStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>
                <OwcInput id="SerialNumberInput" style={{ width: "auto" }}
                  value={this.state.searchSerialNo}
                  placeholder="Enter the serial number"
                  onValueChange={(ev) => this.setState({ searchSerialNo: ev.detail })} />
              </td>
              <td align="left" style={{borderBottomStyle:"solid", borderRightStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>
                <OwcButton style={{ width: "fit-content" }}
                  onclick={() => this.handleSearchClick()}
                  disabled={this.state.searchOngoing}
                >
                  {this.state.searchOngoing ? "Searching ..." : "Search"}
                </OwcButton>
              </td>
            </tr>
            {this.state.searchError.length > 0?
              ( <tr>
                  <td colSpan="5">
                    <OwcTypography variant="button" 
                      key="SearchErrorMessage" 
                      style={{"fontWeight": "bold", align:"left", color:"red", paddingLeft:"0.2em" }}
                    >
                      {this.state.searchError}
                    </OwcTypography>
                  </td>
                </tr>
              )
              :""}
            <tr valign="centre" id="SearchByContractTitleRow">
              <td colspan="5" 
                  style={{borderTopStyle:"solid",borderLeftStyle:"solid", borderRightStyle:"solid", borderWidth:"1px", borderColor:"silver"}}
              ><OwcTypography style={{"fontWeight": "bold"}}>Search By Local Agreement</OwcTypography></td>
            </tr>
            <tr valign="centre" id="SearchByContractRow">
              <td style={{ "fontWeight": "bold", paddingRight:"1em", borderBottomStyle:"solid", borderLeftStyle:"solid", borderWidth:"1px", borderColor:"silver"}}
              >Country</td>
              <td style={{paddingRight:"1em", borderBottomStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>
                {this.renderCountrySelectList()}
              </td>
              <td style={{ "fontWeight": "bold", paddingRight:"1em", borderBottomStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>Local Agreement Id</td>
              <td style={{paddingRight:"1em", borderBottomStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>
                <OwcInput id="LocalAgreementIdInput" style={{ width: "auto" }}
                  value={this.state.searchLocalAgreementId}
                  placeholder="Enter the local agreement id"
                  onValueChange={(ev) => this.setState({ searchLocalAgreementId: ev.detail })} />
              </td>
              <td align="left" style={{borderBottomStyle:"solid", borderRightStyle:"solid", borderWidth:"1px", borderColor:"silver"}}>
              <OwcButton style={{ width: "fit-content" }}
                  onclick={() => this.handleSearchContractClick()}
                  disabled={this.state.searchByContractOngoing}
                >
                  {this.state.searchByContractOngoing ? "Searching ..." : "Search"}
                </OwcButton>
              </td>
            </tr>
            {this.state.searchContractError.length > 0?
              ( <tr>
                  <td colSpan="5">
                    <OwcTypography variant="button" 
                      key="SearchErrorMessage" 
                      style={{"fontWeight": "bold", align:"left", color:"red", paddingLeft:"0.2em" }}
                    >
                      {this.state.searchContractError}
                    </OwcTypography>
                  </td>
                </tr>
              )
              :""}
          </tbody>
        </table>
      </div>
    );
  }

  
  /**
   * Renders a table of search results
   * @returns the jsx of the control
   */
  renderSearchResults() {
    const searchResults = this.state.manualSearchResults;
    if (this.state.searchOngoing === null && this.state.searchByContractOngoing === null) {
      // no search performed yet
      return;
    }
    if ((searchResults === undefined) || (searchResults === null) || (searchResults.length === 0)) {
      return (
        <>
        <OwcTypography style={{ fontWeight: "bold" }}>Search Results</OwcTypography>
        <br/>
        <OwcTypography>No matching devices found.</OwcTypography>
        </>
      );
    }
    return (
      <>
      <OwcTypography style={{ fontWeight: "bold" }}>
        Search Results
      </OwcTypography>
      <br/>
      {
        searchResults.length >= this.SEARCH_ROW_LIMIT
        ?<OwcTypography key="SaerchResultsRowLimitWarning" style={{ color: "orange" }}>
            More than {this.SEARCH_ROW_LIMIT} devices found, showing the first {this.SEARCH_ROW_LIMIT}
          </OwcTypography>
        :""
      }
      <OwcTable style={{ display: "block" }} size='small' >
        <OwcTableHeader elevated sticky shrink>
          <OwcTableHeaderCell width="10%" resizable>Instrument Type</OwcTableHeaderCell>
          <OwcTableHeaderCell width="10%" resizable>Serial Number</OwcTableHeaderCell>
          <OwcTableHeaderCell width="15%" resizable>Local Agreements</OwcTableHeaderCell>
          <OwcTableHeaderCell width="15%" resizable>Device Location</OwcTableHeaderCell>
          <OwcTableHeaderCell width="20%" resizable>Matched By</OwcTableHeaderCell>
          <OwcTableHeaderCell width="20%" resizable>Account Name</OwcTableHeaderCell>
          <OwcTableHeaderCell width="10%"></OwcTableHeaderCell>
        </OwcTableHeader>
        <OwcTableBody>
          {searchResults.map(row => this.renderSearchDevicesRow(row))}
        </OwcTableBody>
      </OwcTable>
      </>
    );
  }

  
  /**
   * Renders a row of the search results table
   * @returns The JSX of the controls
   */
  renderSearchDevicesRow(row) {
    let colour = configData.COLOUR_WHITE;
    if (!this.isMapped(row.deviceId)) {
      const rowKey = row.referenceSystemType + row.serialNo + row.deviceId;
      const addressText = row.sourceInstrumentAddress;
      const accountNameText = row.sourceAccountName;
      let localAgreementList = [];
      if (row.localAgreementIds !== undefined 
          && row.localAgreementIds !== null
          && row.localAgreementIds.trim().length > 0) {
        localAgreementList = row.localAgreementIds.trim().split(",");
      }


      return (
        <OwcTableRow key={"SearchResultsRow" + rowKey} id={"ResultsRow" + rowKey}>
          <OwcTableCell key={"ResultsSystemTypeCell" + rowKey}
            valign="middle" style={{ backgroundColor: colour, wordBreak:"normal" }}>
            {row.referenceSystemType + (row.referenceSystemTypeIsActive===false?configData.REFDATA_DEPRECATED:"")}
          </OwcTableCell>
          <OwcTableCell key={"ResultSerialNoCell" + rowKey} valign="middle" style={{ backgroundColor: colour }}>
            {row.deviceId !== null?
              <TextWithLookup 
                text={row.serialNo} 
                iconTooltip="Show details for this device"
                onClick={()=>this.handleDisplayDeviceDetails(row.deviceId)}
              />
            :
              row.serialNo
            }
          </OwcTableCell>
          <OwcTableCell key={"ResultLocalAgreementCell" + rowKey} valign="middle" style={{ backgroundColor: colour, wordBreak:"normal" }}>
            <pre>
              {localAgreementList.map(agreement => {return (<>{agreement.trim()}<br/></>)})}
            </pre>
          </OwcTableCell>
          <OwcTableCell key={"ResultsInstAddressCell" + rowKey} valign="middle" style={{ backgroundColor: colour, wordBreak:"normal" }}>
            {addressText}
          </OwcTableCell>
          <OwcTableCell key={"ResultsMappedAccountsCell" + rowKey} id={"ResultsMappedAccountsCell" + rowKey} valign="middle" style={{ backgroundColor: colour }}>
            {this.getMappedAccountsTextForAccounts(row.sourceShipTo,
                                                   row.sourceSoldTo,
                                                   row.sourceBillTo,
                                                   row.sourceLocationAccountNo,
                                                   row.localAgreementIds)}
          </OwcTableCell>
          <OwcTableCell key={"ResultsAccountNameCell" + rowKey} valign="middle" style={{ backgroundColor: colour, wordBreak:"normal" }}>
            {accountNameText}
          </OwcTableCell>
          <OwcTableCell key={"ResultsMappingCell" + rowKey} valign="middle" style={{ backgroundColor: colour }}>
            {row.deviceId !== null
            ? ( row.excluded === true?
                  (<>Device<br/>Excluded</>)
                :
                  <OwcButton
                    key={"ResultsMappingButton" + rowKey}
                    style={{ width: "fit-content" }}
                    onclick={() => this.handleMapClick(row)}
                  >
                    Map
                  </OwcButton>
              )
            :
              (<>Device<br/>Not Connected</>)
            }
          </OwcTableCell>
        </OwcTableRow>
      );
    }
  }

  /**
   * Renders a select control for selecting the search system type
   * @returns the jsx of the control
   */
  renderInstTypeSelectList() {
    return (
      <select style={(this.state.searchInstType === null ||
        this.state.searchInstType === this.INST_TYPE_BLANK_VALUE) ?
        { width: "100%", color: "grey", paddingLeft:"0.75em", fontSize: "0.9em" } :
        { width: "100%", paddingLeft:"0.75em", fontSize: "0.9em"  }}
        id={"InstTypeSelect"}  key={"InstTypeSelect"}
        value={this.state.searchInstType}
        onChange={(ev) => {this.setState({searchInstType: ev.target.value})}}>
        <option key={"InstTypeSelectNull"} style={{ color: "grey" }}>
          {this.INST_TYPE_BLANK_VALUE}</option>
        {this.state.referenceTypes.map((item, index) => (
          <option
            key={"InstTypeSelect" + index} style={{ color: "black" }}>
            {item.description + (item.isActive===false?configData.REFDATA_DEPRECATED:"")}
          </option>
        ))}
      </select>
    );
  }

  
  /**
   * Renders a select control for selecting the search local agreement country
   * @returns the jsx of the control
   */
  renderCountrySelectList() {
    return (
      <select style={(this.state.searchLocalAgreementCountry === null ||
        this.state.searchLocalAgreementCountry === this.COUNTRY_BLANK_VALUE) ?
        { width: "100%", color: "grey", paddingLeft:"0.75em", fontSize: "0.9em" } :
        { width: "100%", paddingLeft:"0.75em", fontSize: "0.9em"  }}
        id={"LocalAgreementCountrySelect"}  key={"LocalAgreementCountrySelect"}
        value={this.state.searchLocalAgreementCountry}
        onChange={(ev) => {this.setState({searchLocalAgreementCountry: ev.target.value})}}>
        <option key={"LocalAgreementCountrySelectNull"} style={{ color: "grey" }}>
          {this.COUNTRY_BLANK_VALUE}</option>
        {this.state.localAgreementCountries.map((countryCode, index) => (
          <option
            key={"LocalAgreementCountrySelect" + index} style={{ color: "black" }}>
            {countryCode}
          </option>
        ))}
      </select>
    );
  }

  /**
   * Renders the search related devices
   * @returns The JSX of the controls
   */
  renderRelatedDevices() {
    return (
      <div>
        <OwcTypography style={{ fontWeight: "bold" }}>Devices related to an account or local agreement that has been mapped to this customer agreement</OwcTypography>
        <br />
        {this.renderRelatedDevicesTable()}
      </div>
    );
  }

  /**
   * Renders the related devices table
   * @returns The JSX of the controls
   */
  renderRelatedDevicesTable() {
    if (this.state.loadRelatedDevicesOngoing) {
      return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <OwcProgressSpinner style={{ marginTop: '30px' }} />
        </div>
      );
    }

    const searchResults = this.state.searchResults;
    if ((searchResults === undefined) || (searchResults === null) || (searchResults.length === 0)) {
      return (
        <OwcTypography>No related connected devices found.</OwcTypography>
      );
    }
    return (
      <OwcTable style={{ display: "block" }} size='small' elevated >
        <OwcTableHeader sticky>
          <OwcTableHeaderCell width="15%" resizable>Instrument Type</OwcTableHeaderCell>
          <OwcTableHeaderCell width="15%" resizable>Serial Number</OwcTableHeaderCell>
          <OwcTableHeaderCell width="15%" resizable>Device Location</OwcTableHeaderCell>
          <OwcTableHeaderCell width="20%" resizable>Matched By</OwcTableHeaderCell>
          <OwcTableHeaderCell width="20%" resizable>Account Name</OwcTableHeaderCell>
          <OwcTableHeaderCell width="15%"></OwcTableHeaderCell>
        </OwcTableHeader>
        <OwcTableBody>
          {searchResults.map(row => this.renderRelatedDevicesRow(row))}
        </OwcTableBody>
      </OwcTable>
    );
  }

  /**
   * returns true if the device_id is already in the mapped devices table (and not deleted)
   * @param {*} deviceId 
   */
  isMapped(deviceId) {
    let retVal = false;
    if (this.state.mappedDevices) {
      const mappedDevicesEntry = this.state.mappedDevices.find(item => item.deviceId === deviceId);
      if (mappedDevicesEntry !== undefined) {
        if (("deleted" in mappedDevicesEntry) && (mappedDevicesEntry.deleted === true)) {
          retVal = false;
        } else {
          retVal = true;
        }
      }
    }
    return retVal;
  }

  /**
   * Renders a row of a related device
   * @returns The JSX of the controls
   */
  renderRelatedDevicesRow(row) {
    let colour = configData.COLOUR_WHITE;
    if (row.deleted === true) {
      colour = configData.COLOUR_YELLOW;
    }

    if ((!this.isMapped(row.deviceId)) && (row.excluded !== true)) {
      const rowKey = row.deviceId;
      const addressText = this.getMappedInfoMessage(row.mappedInstrumentAddress, row.sourceInstrumentAddress, "Matched Historic Record");
      const accountNameText = this.getMappedInfoMessage(row.mappedAccountName, row.sourceAccountName, "Matched Historic Record");
      return (
        <OwcTableRow key={"ResultsRow" + rowKey} id={"ResultsRow" + rowKey}>
          <OwcTableCell key={"ResultsSystemTypeCell" + rowKey}
            valign="middle" style={{ backgroundColor: colour, wordBreak:"normal" }}>
            {row.referenceSystemType + (row.referenceSystemTypeIsActive===false?configData.REFDATA_DEPRECATED:"")}
          </OwcTableCell>
          <OwcTableCell key={"ResultSerialNoCell" + rowKey} valign="middle" style={{ backgroundColor: colour }}>
            <TextWithLookup 
              text={row.serialNo} 
              iconTooltip="Show details for this device"
              onClick={()=>this.handleDisplayDeviceDetails(row.deviceId)}
            />
          </OwcTableCell>
          <OwcTableCell key={"ResultsInstAddressCell" + rowKey} valign="middle" style={{ backgroundColor: colour, wordBreak:"normal" }}>
            {addressText}
          </OwcTableCell>
          <OwcTableCell key={"ResultsMappedAccountsCell" + rowKey} valign="middle" style={{ backgroundColor: colour }}>
            {this.getMappedAccountsText(row, "Matched Historic Record")}
          </OwcTableCell>
          <OwcTableCell key={"ResultsAccountNameCell" + rowKey} valign="middle" style={{ backgroundColor: colour, wordBreak:"normal" }}>
            {accountNameText}
          </OwcTableCell>
          <OwcTableCell key={"ResultsMappingCell" + rowKey} valign="middle" style={{ backgroundColor: colour }}>
            <OwcButton
              style={{ width: "fit-content" }}
              onclick={() => this.handleMapClick(row)}
            >
              Map
            </OwcButton>
          </OwcTableCell>
          {row.deleted === true?
            <DelayedTooltip key={"ResultsRowTooltip" + rowKey} anchor={"ResultsRow" + rowKey}>
              This device was previously mapped, but has been manually removed by a data steward
            </DelayedTooltip>
          : 
            ""
          }
        </OwcTableRow>
      );
    }
  }

  /**
   * Renders the form
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    if (this.state.selectedAgreementId === null) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an agreement in "Capture Agreement" first</OwcTypography>);
    }

    if (this.state.selectedAgreementLoading === true || this.state.refdataLoaded === false) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Loading ...</OwcTypography>);
    }

    let selectedDevice = null;
    if (isRecentMessage(this.state.displayDeviceDetails))
    {
      selectedDevice = extractTimedMessage(this.state.displayDeviceDetails);
    }

    return (
      <div style={{ display:"flex", flexDirection:"column" }}>
        {this.state.agreement.protected?<AgreementProtectedBanner/>:""}
        <DeviceDetails
          key="DeviceDetailsDisplay"
          deviceId={selectedDevice}
          showNow={this.state.displayDeviceDetails===null?null:this.state.displayDeviceDetails}
          onExclusionChange={(deviceId, excluded, comment) => {this.handleDeviceExclusion(deviceId, excluded, comment);
          }}
          disableNavigation={true}
        />
        <OwcExpandable variant="filled" roundedControl
            expanded={this.state.isCustomerAgreementExpanded}
            onExpandedChange={(ev) => this.setState({ isCustomerAgreementExpanded: ev.detail })}>
          <span slot="title">Customer Agreement</span>
          <span slot="content">
            <CustomerAgreementDetails agreement={this.state.agreement} 
              refLists={this.state.refListsComplete} 
              onAccountSearchClick={(arg) => this.props.onAccountSearchClick(arg)} />
          </span>
        </OwcExpandable>
        <OwcExpandable variant="filled" roundedControl
            expanded={this.state.isMappedAccountsExpanded}
            onExpandedChange={(ev) => this.setState({ isMappedAccountsExpanded: ev.detail })}>
          <span slot="title">Device(s) mapped as covered by the agreement</span>
          <span slot="content">
            {this.renderMappedDevices()}
            <br />
            {this.renderMappingComments()}
          </span>
        </OwcExpandable>
        <OwcExpandable variant="filled" roundedControl
            expanded={this.state.isSearchAdditionalExpanded}
            onExpandedChange={(ev) => this.setState({ isSearchAdditionalExpanded: ev.detail })} >
          <span key="searchTitle" slot="title">Search for additional devices</span>
          <span key="searchContent" slot="content">
            <div>
              {this.renderSearchOptions()}
              {this.renderSearchResults()}
            </div>
          </span>
        </OwcExpandable>
        <OwcExpandable variant="standard" round
            expanded={this.state.isRelatedButNotMappedExpanded}
            onExpandedChange={(ev) => this.setState({ isRelatedButNotMappedExpanded: ev.detail })} >
          <span key="searchResultsTitle" slot="title">Device(s) not currently mapped to the agreement</span>
          <span key="searchResultsContent" slot="content">
            {this.renderRelatedDevices()}
          </span>
        </OwcExpandable>
        <table width="100%">
          <tbody>
            <tr>
              <td align="left">
                <OwcButton elevated style={{ width: "fit-content" }}
                    onclick={() => this.handleCancelClick()} >
                  Clear Unsaved Changes
                </OwcButton>
              </td>
              <td align="right">
                <OwcButton style={{ width: "fit-content" }}
                  onclick={() => this.handleSubmitClick()}
                  disabled={((this.state.agreement.protected === true)
                              || ((this.state.submissionState !== "Saving ...") && (this.state.submissionState === null))) 
                              ? true : false}
                >
                  {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                </OwcButton>
              </td>
            </tr>
          </tbody>
        </table>
        {this.state.agreement.protected === true?
          ""
        :
          <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
            {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
          </OwcTypography>
        }
      </div>
    );

  }


  
}


export default MapDevices;
