import React from 'react';
import moment from 'moment';
import {configData} from "../config.js";
import DelayedTooltip from "../components/general/DelayedTooltip.js";
import AgreementProtectedBanner from "../components/agreement/AgreementProtectedBanner.js";
import '../styles.scss';

import {formatDate, fetchSigned} from "../shared/Utilities.js"

import {
  OwcButton, OwcTypography, OwcExpandableGroup, OwcExpandable,
  OwcDatepicker, OwcInput, OwcProgressSpinner,
  OwcIconButton, OwcCheckbox, OwcAssistiveText
} from '@one/react';

/**
 * The interactive form for extracting and capturing data agreement information
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class CaptureAgreement extends React.Component {
  AFFILIATE_BLANK_VALUE = "Select an Affiliate";
  COUNTRY_BLANK_VALUE = "Select a Country";
  VALIDATED_TEXT_CONTROLS = ["customerName", "rocheName", "title"];
  UNSAVED_CHANGES = "Unsaved changes, click Save Changes to save";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();
    if (props.customerAgreementId !== null)
    {
      this.state.isLoading = true;
    }
    this.state.affiliateList = [];
    this.state.localAgreementCountries = [];
    this.state.localAgreementCountriesLoaded = false;
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
      this.loadAgreementData(this.props.customerAgreementId, false);
    }
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredAffiliateList = result.filter(value =>
            value.type === "Affiliate"
          );
          const filteredActiveAffiliateList = filteredAffiliateList.filter(value =>
            value.isActive === true
          );
          this.setState({
              affiliateList: filteredActiveAffiliateList,
              affiliateListComplete: filteredAffiliateList
            },
            this.loadAgreementData(this.props.customerAgreementId, false)
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {this.setState({error})}
      )

    // load the countries list for local agreements
    fetchSigned(configData.CONTRACT_LINKS_API_URL + "countrylist/")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          localAgreementCountries: result,
          localAgreementCountriesLoaded: true
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )

  }

  /**
   * Sets the initial state
   * @returns the initial state dictionary
   */
  initialState() {
    return ({
      isLoading: false,
      customerName: null,
      customerCountry: null,
      customerAddress: null,
      customerAccountNo: null,
      rocheName: null,
      rocheCountry: null,
      rocheAddress: null,
      title: null,
      validFrom: null,
      terminationDate: null,
      dataClauses: Array(1).fill({ dataClauseNo: -1, dataClauseText: null}),
      submissionState: null,
      deleteEnabled: false,
      affiliateText: this.AFFILIATE_BLANK_VALUE,
      entitlementDateValidityVaries: false,
      publishedVersionCount: 0,
      entitlementCount: 0,
      localAgreementCountry: this.COUNTRY_BLANK_VALUE,
      localAgreementId: null,
      protected: false,
      protectedSavedValue: false,
    })
  }

  /**
   * Resets the form by setting the state back to the inital state
   */
  handleCancelClick() {
    //reset the state
    this.loadAgreementData(this.props.customerAgreementId);
    this.props.onUnsavedChangesChange(false);
  }

  /**
   * Handles the change of any of the form inputs, updating the state
   * @param {*} value The new value
   * @param {string} name The name of the control who's value has changed
   */
  handleInputChange(value, name) {
    let stateObject = {};

    if (value instanceof Date) {
      stateObject[name] = moment(value).valueOf();
    } else {
      if (Array.isArray(value) && value.length === 0) {
        stateObject[name] = null;
      } else {
        stateObject[name] = value;
      }
    }

    // the form has been changed clear any submission state
    if (stateObject[name] !== this.state[name]) {
      stateObject["submissionState"] = this.UNSAVED_CHANGES;
      this.props.onUnsavedChangesChange(true);
    }
    this.setState(stateObject);

    // check validation
    if (this.VALIDATED_TEXT_CONTROLS.includes(name)) {
      this.checkTextValidation(value, name);
    }
  }

  /**
   * Handles updates to the affilite dropdown
   * @param {*} ev 
   */
  handleAffiliateChange(ev){
    if (ev.target.value !== this.state.affiliateText) {
      this.setState({ affiliateText: ev.target.value,
                      submissionState: "Changes have been made, click Save Changes to save this agreement",
      });
    this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * Handles the state updates for Data Clause entries
   * @param {string} value The new text from the data clause control  
   * @param {number} index The index number of the data clause control
   */
  handleDataClauseChange(value, index) {
    if (this.state.dataClauses[index].dataClauseText !== value){
      var newDataClauses = this.state.dataClauses.slice();
      newDataClauses[index].dataClauseText = String(value);
      if (!("createdBy" in newDataClauses[index])) {
        newDataClauses[index].createdBy = this.props.userName;
      }
      this.setState({
        dataClauses: newDataClauses,
        submissionState: "Changes have been made, click Save Changes to save this agreement",
      });
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * Check the validation of the text input and sets the validation state and message
   * @param {string} text The text in the control
   * @param {string} controlName The name of the control
   */
  checkTextValidation(text, controlName) {
    let errorMessage = "Required";

    if ((text !== null) && (text.trim().length > 0)) {
      // alpha characters
      const testReAlpha = /[a-zA-Z]+/;
      if (testReAlpha.test(text)) {
        errorMessage = null;
      } else {
        errorMessage = "The entry must include letters"
      };
    };

    return errorMessage;
  }

  /**
   * checks the date validation for the validFrom and terminationDate fields
   * @returns true if the validation warning should be displayed
   */
   checkDateWarning() {
    // termination date
    if ((this.state.terminationDate !== null) && (this.state.terminationDate.length !== 0)) {
      if ((this.state.validFrom !== null) && (this.state.validFrom.length !== 0)) {
        if (this.state.terminationDate < this.state.validFrom) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Adds a new data clause control when the "Add data clause" button is pressed
   */
  handleAddDataClauseClick() {
    //take a copy of the dataclauses
    const newDataClauses = this.state.dataClauses.slice();
    newDataClauses.push({ dataClauseNo: -1, dataClauseText: null, createdBy: this.props.userName });
    this.setState({ dataClauses: newDataClauses });
    this.props.onUnsavedChangesChange(true);
  }

  /**
   * Removes a data clause
   * @param {*} index the index of the dataclause to remove
   */
  handleRemoveDataClause(index) {
    const newDataClauses = this.state.dataClauses.slice();

    if ((newDataClauses[index].dataClauseNo !== null) &&
      (newDataClauses[index].dataClauseNo >= 0)) {
      // This has been saved to the database, mark it for deletion
      newDataClauses[index].isDeleted = true;
      this.props.onUnsavedChangesChange(true);
      this.setState({ 
        dataClauses: newDataClauses,
        submissionState: "Changes have been made, click Save Changes to save this agreement",
      });
    } else {
      //not added yet, just remove it
      newDataClauses.splice(index, 1);
      this.setState({ dataClauses: newDataClauses });
    }
  }

  /**
   * Enables or disables the delete button
   * @param {*} ev 
   */
  handleDeleteCheckboxClick(ev) {
    this.setState({ deleteEnabled: ev.detail });
  }

  /**
   * Enables or disables the delete button
   * @param {*} ev 
   */
    handleDateValidityCheckboxChange(ev) {
      this.setState({
        entitlementDateValidityVaries: ev.detail,
        submissionState: this.UNSAVED_CHANGES
      });
      this.props.onUnsavedChangesChange(true);
    }

  /**
   * Deletes the currently selected agreement
   * @param {*} ev 
   */
  handleDeleteClick(ev) {
    const submitForm = () => {
      return fetchSigned(configData.CONTRACTS_API_URL + this.props.customerAgreementId + "/", {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 204) // done successfully
          {
            // reset the form
            this.setState(this.initialState);
            this.setState({ submissionState: "Successfully Deleted" });
            this.props.onUnsavedChangesChange(false);
            this.props.onAgreementListChanged(null);
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              this.setState({ submissionState: "Error saving " + json });
            }).catch((error) => {
              console.error(error);
              this.setState({ submissionState: "Error saving " + error });
            });
          }

        });
    };
    submitForm();
  }

  /**
   * checks all of the values
   * @returns an error messge detailing all those that are not true
   */
  CheckFormValidation() {
    // force the validation of all the controls
    let errorString = "";
    this.VALIDATED_TEXT_CONTROLS.forEach((controlName) => { 
      const errorMessage = this.checkTextValidation(this.state[controlName], controlName); 
      if (errorMessage !== null) {
        errorString = errorString + ", " + this.convertCamelCaseToSentenceCase(controlName);
      };
    })

    if (errorString.length > 0) {
      errorString = "Error - the following fields are invalid: " + errorString.slice(1);
    };

    return errorString;
  }

  /**
   * Converts a camelCase string to Sentence Case
   * @param {*} inputText The input text in camelCase
   */
  convertCamelCaseToSentenceCase(inputText) {
    const result = inputText.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  /**
   * Validates the form and submits it to the API if valid
   * @returns nothing
   */
  handleSubmitClick() {
    // check the form is valid
    const errorMessage = this.CheckFormValidation();
    if (errorMessage) {
      this.setState({ submissionState: errorMessage });
      return;
    }

    const submissionData = this.extractFormData();
    console.log(submissionData)

    this.setState({ submissionState: "Saving ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      const isUpdate = (submissionData.customerAgreementId !== null);
      const url = isUpdate ?
        configData.CONTRACTS_API_URL + submissionData.customerAgreementId + "/" :
        configData.CONTRACTS_API_URL;
      const method = isUpdate ? 'PUT' : 'POST';
      return fetchSigned(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
    };

    submitForm().then((response) => {
      response.json().then((json) => {
        console.log(`API Response: ${JSON.stringify(json)}`);
        if (response.status === 201) // inserted successfully
        {
          // load the data back from the database to update all fields and ids
          this.loadAgreementData(json["customerAgreementId"], false);
          this.props.onAgreementListChanged(json["customerAgreementId"]);
          this.props.onUnsavedChangesChange(false);
          this.setState({ submissionState: "Agreement Successfully Saved" });
        } else {
          if (json.errorText.startsWith("ERROR: duplicate key value violates unique constraint")) {
            this.setState({ submissionState: "Error: This agreement has already been entered." });
          } else {
            this.setState({ submissionState: `Error saving agreement ${json.errorText}` });
          }
        }
      });
    }).catch((error) => {
      this.setState({ submissionState: `Error saving agreement ${error}` });
      console.error(`Error when saving agreement: ${error}`);
    });
  }

  trimEntry(data, entry) {
    if (data[entry] !== null) {
      data[entry] = data[entry].trim();
    }
  }

  /**
   * Extracts the form data to submit to the API from the state
   * @returns The extracted form data
   */
  extractFormData() {
    const submissionData = { ...this.state };
    //remove the bits we don't want to include
    delete submissionData["deleteEnabled"];
    delete submissionData["affiliateList"];
    delete submissionData["affiliateListComplete"];
    delete submissionData["affiliateText"];
    delete submissionData["protectedSavedValue"];
    delete submissionData["submissionState"];
    
    //trim whitespace
    const entriesToTrim = ["customerName",
      "customerCountry",
      "customerAddress",
      "customerAccountNo",
      "rocheName",
      "rocheAddress",
      "rocheCountry",
      "title"]
    entriesToTrim.forEach(entry => this.trimEntry(submissionData, entry));
    submissionData.dataClauses.forEach(dataClause => this.trimEntry(dataClause, "dataClauseText"));

    submissionData.customerAgreementId = this.props.customerAgreementId;
    submissionData.createdBy = this.props.userName;

    //find the rocheAffiliateId
    if (this.state.affiliateText === this.AFFILIATE_BLANK_VALUE)
    {
      submissionData.rocheAffiliateId = null;
    } else {
      // remove the deprecated suffix if it is added
      const searchText = this.state.affiliateText.endsWith(configData.REFDATA_DEPRECATED)? 
                          this.state.affiliateText.slice(0, -configData.REFDATA_DEPRECATED.length)
                          : this.state.affiliateText;
      submissionData.rocheAffiliateId = this.getIdForRefData(searchText, this.state.affiliateList);
      if (submissionData.rocheAffiliateId === null)
      {
        submissionData.rocheAffiliateId = this.getIdForRefData(searchText, this.state.affiliateListComplete);
      }
    }

    if (submissionData.validFrom !== null) {
      submissionData.validFrom = moment(submissionData.validFrom).format("YYYY-MM-DD");
    }

    if (submissionData.terminationDate !== null) {
      submissionData.terminationDate = moment(submissionData.terminationDate).format("YYYY-MM-DD");
    }

    if (submissionData.localAgreementId !== null 
        && submissionData.localAgreementCountry !== null
        && submissionData.localAgreementCountry !== this.COUNTRY_BLANK_VALUE) {
      // split the string by comma
      let localAgreementList = submissionData.localAgreementId.split(",");
      localAgreementList.forEach((item, index, arr)=>{
        // strip whitespace and add country prefix
        arr[index] = submissionData.localAgreementCountry + "-" + item.trim();
      });
      // put back into a csv string
      submissionData.localAgreementId = localAgreementList.join(", ");
    }

    return submissionData;
  }

  /**
   * Gets the reference data id for a description
   * @param {*} value The description of trhe referece data item
   * @param {*} refdataList the list to search
   * @returns The ref data id or null if not found
   */
  getIdForRefData(value, refdataList) {
    let retVal = null;
    if (refdataList){
      const refDataItem = refdataList.find(({ description }) => description === value);
      //get the record
      if (refDataItem !== undefined) {
        retVal = refDataItem.refListId;
      }
    }
    return retVal;
  }

  /**
   * Gets the descrition for a reference data id
   * @param {*} value the ref list id value
   * @param {*} refdataList the list to search in
   * @returns the description or null if not found
   */
  getDescriptionForRefData(value, refdataList) {
    let retVal = null;
    if (refdataList !== undefined) {
      const refDataItem = refdataList.find(({ refListId }) => refListId === value);
      //get the record
      if (refDataItem !== undefined) {
        retVal = refDataItem.description;
      }
    }
    return retVal;
  }

  mapRowToState(row, clearSubmissionState) {
    console.log(`Fetched agreement data: ${JSON.stringify(row)}`);
    const state = this.initialState();

    const validFrom = Date.parse(row.validFrom);
    const terminationDate = Date.parse(row.terminationDate);
    if (!clearSubmissionState) {
      // don't overwrite the submission state
      delete state["submissionState"];
    }
    if (row.localAgreementIds !== null 
          && row.localAgreementIds !== undefined 
          && row.localAgreementIds.length > 3) { 
      const country = row.localAgreementIds.slice(0,2);
      if (this.state.localAgreementCountriesLoaded === false
          || this.state.localAgreementCountries.includes(country)) {
        // split the string by comma
        let localAgreementList =  row.localAgreementIds.split(",");
        localAgreementList.forEach((item, index, arr)=>{
          // strip whitespace and the country prefix
          const trimmed_item = item.trim();
          arr[index] = trimmed_item.replace(`${country}-`,"");
        });
        // put back into a csv string
        state.localAgreementId = localAgreementList.join(", ");
        state.localAgreementCountry = country;
      } else {
        state.localAgreementCountry = this.COUNTRY_BLANK_VALUE;
        state.localAgreementId = row.localAgreementIds
      }
    }
    state.customerName = row.customerName;
    state.customerCountry = row.customerCountry;
    state.customerAddress = row.customerAddress;
    state.customerAccountNo = row.customerAccountNo;
    state.rocheName = row.rocheName;
    state.rocheCountry = row.rocheCountry;
    state.rocheAddress = row.rocheAddress;
    state.rocheAffiliateId = row.rocheAffiliateId;
    state.protected = row.protected;
    state.protectedSavedValue = row.protected;
    if (row.rocheAffiliateId === null) {
      state.affiliateText = this.AFFILIATE_BLANK_VALUE;
    } else {
      state.affiliateText = this.getDescriptionForRefData(row.rocheAffiliateId, this.state.affiliateList);
      if (state.affiliateText === null){
        //the value is now obsolete
        state.affiliateText = this.getDescriptionForRefData(
          row.rocheAffiliateId, this.state.affiliateListComplete
        ) + configData.REFDATA_DEPRECATED;
      }
    }
    state.title = row.title;
    state.validFrom = isNaN(validFrom) ? null : validFrom;
    state.terminationDate = isNaN(terminationDate) ? null : terminationDate;
    state.entitlementDateValidityVaries = row.entitlementDateValidityVaries;
    state.dataClauses = row.dataClauses;
    state.publishedVersionCount = row.publishedVersionCount;
    state.entitlementCount = row.entitlementCount;
    state.isLoading = false;
    return state;
  }


  /**
   * handles the row click event from the agreementselection control
   * @param {*} ev the event
   * @param {*} agreementId  the selected agreeemntId
   */
  loadAgreementData(agreementId, clearSubmissionState = true) {
    this.setState({isLoading:true});
    console.log("Selected agreement id: " + agreementId);

    if (agreementId !== null) {
      fetchSigned(configData.CONTRACTS_API_URL + agreementId + "/")
        .then(res => res.json())
        .then(
          (result) => {
            const newState = this.mapRowToState(result[0], clearSubmissionState);            
            this.setState(
              newState,
              this.props.onUnsavedChangesChange(false));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => { this.setState({error}) }
      );
    } else {
      this.setState(this.initialState(), this.props.onUnsavedChangesChange(false));
    };    
  }

  /**
   * Renders a text input control with validation
   * @param {*} label The human readable label to use
   * @param {*} controlName the name of the control in the state
   * @param {*} maxLength the mas length of the data input
   * @param {*} assistiveText the assistive text to include
   * @returns The JSX of the controls
   */
  renderTextInput(label, controlName, maxLength = 255, assistiveText = "") {
    let errorMessage = null;
    if (this.VALIDATED_TEXT_CONTROLS.includes(controlName)) {
      errorMessage = this.checkTextValidation(this.state[controlName],controlName);
    }

    return (
      <OwcInput key={"input" + controlName} style={{ width: "100%" }}
          label={label} value={this.state[controlName]}
          onValueChange={(ev) => this.handleInputChange(ev.detail, controlName)}
          validity={{state: errorMessage !== null ? "error" : "valid"}}
          maxlength={maxLength} no-clear-icon>
        <OwcAssistiveText>
          {errorMessage !== null ? errorMessage : assistiveText}
        </OwcAssistiveText>
      </OwcInput>
    );
  }

  renderSelect() {
    return (
      <select value={this.state.affiliateText}
          style={
            this.state.affiliateText === this.AFFILIATE_BLANK_VALUE
            ? { width: "100%", paddingLeft:"1em", color:"grey" }
            : { width: "100%", paddingLeft:"1em" }
          }
          onChange={(ev) => this.handleAffiliateChange(ev)}>
        <option key="AgreementAffiliateBlank" style={{color:"grey"}}>{this.AFFILIATE_BLANK_VALUE}</option>
        { 
        this.state.affiliateText !== null && this.state.affiliateText.endsWith(configData.REFDATA_DEPRECATED) 
          ? <option key="AgreementAffiliateDeptecated" style={{color:"black"}}>{this.state.affiliateText}</option>
          : "" 
        }
        {this.state.affiliateList.map(item => (
          <option key={"AgreementAffiliate" + item.refListId} style={{color:"black"}}>{item.description}</option>
        ))}
      </select>
    );
  }

  /**
   * Renders the data clauses controls
   * @returns The JSX of the controls
   */
  renderDataClauses() {
    // create the right number of dataClauses
    // set the size of the box to match the contents
    if (this.state.dataClauses && this.state.dataClauses.length > 0) {
      return (
        <div>
          {this.state.dataClauses.map((value, index) => this.renderDataClause(value, index))}
        </div>
      )
    } else {
      return (
        <div style={{marginBottom:"0.5em"}}>
          <OwcTypography style={{fontWeight:"bold"}}>No Data Clauses</OwcTypography>
        </div>
      );
    }
  }

  /**
   * Renders a text box to capture a data clause
   * @param {*} value the value to put in the text box
   * @param {*} index the index vlue of the order of this data clause
   * @returns The JSX of the controls
   */
  renderDataClause(value, index) {
    if ("isDeleted" in value) {
      // this one is deleted do not render it
      return
    } else {
      return (
        <div key={"DataClauseDiv" + index} style={{ display: "flex", flexDirection: "row", verticalAlign: "top"}}>
          <OwcInput
              key={"DataClause" + index}
              style={{ display: "inline-block", marginRight: "1%" }}
              rows={value.dataClauseText == null ? 4 : Math.max(4, value.dataClauseText.split(/\r\n|\r|\n/).length)}
              cols="100"
              label={"Data Clause " + (index + 1) + " Text"}
              value={value.dataClauseText}
              onValueChange={(ev) => this.handleDataClauseChange(ev.detail, index)}
              type="textarea"
              no-clear-icon resizable="false">
            <OwcAssistiveText>
              Copy / Paste relevant sections from the agreement in the original language
            </OwcAssistiveText>
          </OwcInput>
          <OwcIconButton id={"DataClauseCancelBtn" + index}
            key={"DataClauseCancelBtn" + index}
            icon="circle_clear" style={{ display: "inline-block", verticalAlign: "top" }}
            onclick={() => this.handleRemoveDataClause(index)} />
          <DelayedTooltip key={"DataClauseCancelBtnToolTip" + index}
              anchor={"DataClauseCancelBtn" + index}
              placement="right">
            Remove this data clause
          </DelayedTooltip>
        </div>
      );
    }
  }

  /**
   * Renders the delete button if in edit mode
   * @returns The JSX of the controls
   */
  renderDeleteOption() {
    if (this.props.customerAgreementId !== null) {
      if (this.state.protected) {
        return (
          <td style={{verticalAlign:"top", textAlign:"center"}} align="centre">
                <OwcTypography variant="caption" style={{textAlign:"center"}}>This agreement is protected from editing or deletion</OwcTypography>
          </td>
        );
      }

      if (this.state.publishedVersionCount > 0 && this.props.dataSteward === false) {
        return (
          <td style={{verticalAlign:"top", textAlign:"center"}} align="centre">
                <OwcTypography variant="caption" style={{textAlign:"center"}}>Only Data Stewards can <br/>delete published agreements</OwcTypography>
          </td>
        );
      }
      else {
        let warningText = "";
        if (this.state.publishedVersionCount > 0){
          warningText = "this agreement is published";
        } else if (this.state.entitlementCount > 0) {
          warningText = "this agreement has entitlements defined";
        }
        return (
          <td style={{verticalAlign:"top"}} align="centre">
            <OwcButton style={{ width: "fit-content", marginBottom:"0.65em"}}
              key="DeleteButton"
              onclick={() => this.handleDeleteClick()} disabled={this.state.deleteEnabled ? false : true}
            >
              Delete Agreement
            </OwcButton>
            <OwcCheckbox
              key="DeleteButtonCheckbox"
              checked={this.state.deleteEnabled}
              onValueChange={(ev) => this.handleDeleteCheckboxClick(ev)}>
                <OwcTypography variant="caption">Enable Deletion</OwcTypography>
              </OwcCheckbox> 
              {warningText !== ""?
                <OwcTypography variant="caption" style={{color:"orange"}}>Warning: {warningText}</OwcTypography>
                : ""
              }
          </td>
        );
      }
    }
  }


  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    const exampleMinDate = new Date();
    exampleMinDate.setFullYear(exampleMinDate.getFullYear() - 10);
    const exampleMaxDate = new Date();
    exampleMaxDate.setFullYear(exampleMaxDate.getFullYear() + 10);

    const submissionState = this.state.submissionState;
    let submissionMessage = submissionState === "Saving ..." ? "" : submissionState;

    let disableSaveButton = false;
    if (this.state.protected === true) {
      if (this.state.protected !== this.state.protectedSavedValue) {
        // the value of protected has changed, allow saving to capture this
        disableSaveButton = false;
      } else {
        // saving is disabled
        disableSaveButton = true;
        submissionMessage = "";
      }
    } 
    if ((submissionState === null)
      || (submissionState === "Saving ...")
      || (submissionState.includes("Successfully"))) {
        //currently in a state when saving is not allowed
        disableSaveButton = true;
    }

 

    if (this.state.isLoading){
      return (
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
          <OwcProgressSpinner style={{marginTop:'30px'}}/>
        </div>);
    } else {
      return (
        <div className="ExtractAndCaptureAgreement">
          {this.state.protected?<AgreementProtectedBanner/>:""}
          <OwcExpandableGroup multiple>
            <OwcExpandable variant="filled" roundedControl expanded>
              <span slot="title" style={{width:"100%"}}>
                <div style={{display:"flex", justifyContent: "space-between", flexDirection:"row", width:"100%", alignItems:"center"}}>
                <div>Agreement</div>
                  <OwcCheckbox style={{backgroundColor:this.state.protected?"var(--one-banner-color-primary-warning-background)":""}}
                      onValueChange={(ev) => {this.setState({protected: !this.state.protected,
                                                            submissionState: this.UNSAVED_CHANGES});
                                              this.props.onUnsavedChangesChange(true);
                                    }}
                      checked={this.state.protected}>
                    <OwcTypography variant="caption">Protect from Editing</OwcTypography>
                  </OwcCheckbox>
                </div>
              </span>
              <span slot="content">
                {this.renderTextInput("Title", "title", 255, "Required")}
                <br />
                <OwcTypography variant="caption">Valid From</OwcTypography>
                <OwcDatepicker autoClose label="Valid From"
                    value={this.state.validFrom} format={"dd-MMM-yyyy"}
                    onValueChange={(ev) => this.handleInputChange(ev.detail, "validFrom")}>
                  <span slot="assistive-text">
                    {this.checkDateWarning()? "" : `e.g. ${formatDate(exampleMinDate)}`}
                  </span>
                </OwcDatepicker>
                {this.checkDateWarning()
                  ?<OwcTypography style={{"color":"orange"}}variant="caption">Termination Date is less than Valid From</OwcTypography>
                  :""}
                <br />
                <div style={{display:'flex', flexDirection:'row'}}>
                  <div>
                    <OwcTypography variant="caption">Termination Date</OwcTypography>
                    <OwcDatepicker autoClose label="Termination Date"
                      value={this.state.terminationDate} format={"dd-MMM-yyyy"}
                      onValueChange={(ev) => this.handleInputChange(ev.detail, "terminationDate")}
                    >
                      <span slot="assistive-text">e.g. {formatDate(exampleMaxDate)}</span>
                    </OwcDatepicker>
                    <br />
                  </div>
                  <div style={{marginLeft:"1em"}}>
                    <OwcCheckbox style={{marginTop:"0.7em"}}
                        onValueChange={(ev) => this.handleDateValidityCheckboxChange(ev)}
                        checked={this.state.entitlementDateValidityVaries}>
                      <OwcTypography variant="caption">Some data entitlements are valid beyond the agreement termination date</OwcTypography>
                    </OwcCheckbox>
                  </div>
                </div>
              </span>
            </OwcExpandable>
            <OwcExpandable variant="filled" roundedControl expanded >
              <span slot="title">Customer party as written in the agreement</span>
              <span slot="content">
                {this.renderTextInput("Name", "customerName", 255, "Required")}
                <br />
                {this.renderTextInput("Address", "customerAddress", 8000)}
                <br />
                {this.renderTextInput("Country", "customerCountry", 255)}
                <br />              
                {this.renderTextInput("Account Number", "customerAccountNo", 255, "If available in contract, use commas to seperate multiple account numbers")}
                <br />
                <OwcTypography style={{ fontWeight: "bold" }}>Local Agreement Links</OwcTypography>
                <table width="100%" cellSpacing="0" cellPadding="0">
                  <tbody>
                  <tr>
                    <td width="20%"><OwcTypography variant="caption">Local Agreement Country</OwcTypography></td>
                    <td style={{paddingLeft:"1em"}}><OwcTypography variant="caption" >Local Agreement Ids</OwcTypography></td>
                  </tr>
                  <tr>
                    <td style={{verticalAlign: "top"}}>
                      <select style={this.state.localAgreementCountry === this.COUNTRY_BLANK_VALUE?
                                      { height:"3.45em", width: "100%", color:"grey" }:
                                      { height:"3.45em", width: "100%"}}
                        value={this.state.localAgreementCountry}
                        onChange={(ev) => {this.setState({localAgreementCountry: ev.target.value,
                                                          submissionState: this.UNSAVED_CHANGES});
                                           this.props.onUnsavedChangesChange(true)}}
                      >
                        <option key="AgreementCountriesBlank" style={{color:"grey"}}>{this.COUNTRY_BLANK_VALUE}</option>
                        {this.state.localAgreementCountries.map(item => (
                          <option key={"AgreementCountries" + item} style={{color:"black"}}>{item}</option>
                        ))}
                      </select>
                    </td>
                
                    <td style={{ paddingLeft:"1em"}}>
                      {this.renderTextInput("Local Agreement Ids",
                                            "localAgreementId",
                                            255,
                                            "If available in contract, use commas to seperate multiple local agreement ids")}
                      {(this.state.localAgreementId !== null 
                 && this.state.localAgreementId.trim().search(/^[A-Z]{2}-/) !== -1) 
                 ?
                <OwcTypography style={{color:"orange", paddingLeft:"1.3em"}} variant="caption">The agreement id(s) appear to start with a country identifier, should that be removed?</OwcTypography> :
                "" }
                    </td>
                </tr>
                </tbody>
                </table>
                {(this.state.localAgreementCountry === this.COUNTRY_BLANK_VALUE 
                 && this.state.localAgreementId !== null 
                 && this.state.localAgreementId.trim().length > 0) 
                 ||
                 (this.state.localAgreementCountry !== this.COUNTRY_BLANK_VALUE 
                  && (this.state.localAgreementId === null 
                  || this.state.localAgreementId.trim().length === 0)) 
                 ?
                <OwcTypography style={{"color":"orange"}} variant="caption">To save local agreement links you must enter both a country and agreement id(s)</OwcTypography> :
                "" }
                <br />
              </span>
            </OwcExpandable>
            <OwcExpandable variant="filled" roundedControl expanded>
              <span slot="title">Roche party as written in the agreement</span>
              <span slot="content">
                {this.renderTextInput("Name", "rocheName", 255, "Required")}
                <br />
                {this.renderTextInput("Address", "rocheAddress", 8000)}
                <br />
                {this.renderTextInput("Country", "rocheCountry", 255)}
                <br />
                <OwcTypography variant="caption">Affiliate</OwcTypography>
                {this.renderSelect()}
              </span>
            </OwcExpandable>
            <OwcExpandable variant="filled" roundedControl expanded>
              <span slot="title">Provide all data clauses as written in the agreement</span>
              <span slot="content">
                {this.renderDataClauses()}
                <OwcButton style={{ width: "fit-content" }}
                    onclick={() => this.handleAddDataClauseClick()}>
                  Add data Clause
                </OwcButton>
              </span>
            </OwcExpandable>
          </OwcExpandableGroup>
          <div style={{ display:"flex", flexDirection:"column" }}>
            <table width="100%">
              <tbody>
                <tr>
                  <td style={{verticalAlign:"top"}} align="left">
                    <OwcButton elevated style={{ width: "fit-content" }}
                        onclick={() => this.handleCancelClick()}>
                      Clear Unsaved Changes
                    </OwcButton>
                  </td>
                  {this.renderDeleteOption()}
                  <td style={{verticalAlign:"top"}} align="right">
                    <OwcButton style={{ width: "fit-content" }}
                        disabled={disableSaveButton}
                        onclick={() => this.handleSubmitClick()}>
                      {submissionState === "Saving ..." ? submissionState : "Save Changes"}
                    </OwcButton>
                  </td>
                </tr>
              </tbody>
            </table>
            <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
              {submissionMessage}
            </OwcTypography>
          </div>
        </div>
      );
    }
  }
}

export default CaptureAgreement;
