import React from 'react';
import {configData} from "../config.js";
import '../styles.scss';

import DelayedTooltip from "../components/general/DelayedTooltip.js";
import AgreementProtectedBanner from "../components/agreement/AgreementProtectedBanner.js";
import {convertToDate, truncateDate, fetchSigned} from "../shared/Utilities.js"

import {
  OwcButton, OwcExpandable, OwcAssistiveText,
  OwcIconButton, OwcDatepicker,
  OwcTypography, OwcRadio, OwcInput,
  OwcTable, OwcTableHeader, OwcTableHeaderCell,
  OwcTableBody, OwcTableRow, OwcTableCell, OwcCheckbox
} from '@one/react';

/**
 * The interactive form for harmonizing the term pairs of an agreement to create data entitlements
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class Harmonize extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";

  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedAgreementLoading: false,
      isLoaded: false,
      selectedAgreementId: this.props.customerAgreementId,
      pairs: [],
      referenceLists: [],
      entitlements: [],
      AgreementSelectionLoaded: false,
      submissionState: null,
      selectedEntitlement: null,
      mappingComments: null,
      customerName: null,
      rocheName: null,
      title: null,
      validFrom: null,
      terminationDate: null,
      entitlementDateValidityVaries: false,
      isDataProcessingExpanded: true,
      isDataUseExpanded: true,
      isMappingCommentsExpanded: true,
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
      this.updateForm(null, this.props.customerAgreementId, true);
      //this has changed the agreement displayed, clear the submissionState
      this.setState({ submissionState: null });
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split into the three lists
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const dataCategoryList = result.filter(value =>
            value.type === "Data Category" && value.isActive === true
          );
          const dataUsageList = result.filter(value =>
            value.type === "Data Use" && value.isActive === true
          );
          const dataProcessingActionList = result.filter(value =>
            value.type === "Processing Action" && value.isActive === true
          );
          const structuredReferenceLists = {
            dataCategory: dataCategoryList,
            dataUsage: dataUsageList,
            dataProcessingAction: dataProcessingActionList,
            complete: result,
          }

          this.setState({ referenceLists: structuredReferenceLists });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

    this.updateForm(null, this.props.customerAgreementId, true);

  }

  /** Handles the click event on the selection table row
   * @param {*} ev the click event
   * @param {number} agreementId the selected agreement id
   */
  updateForm(ev, agreementId, forceUpdate = false) {
    // if the agreementId is changed then
    if (forceUpdate || (agreementId !== this.state.selectedAgreementId)) {

      this.loadTermPairs(agreementId);
      // wipe the last agreement
      this.setState({
        selectedAgreementId: agreementId,
        entitlements: [],
        mappingComments: null,
        selectedEntitlement: null,
        submissionState: null,
        validFrom: null,
        terminationDate: null,
        entitlementDateValidityVaries: false,
        isDataProcessingExpanded: true,
        isDataUseExpanded: true,
        isMappingCommentsExpanded: true,
        protected: false,
      });
      this.props.onUnsavedChangesChange(false);
      // load the new agreement data
      this.loadAgreementData(agreementId);
      // load the entitlements
      this.loadEntitlementData(agreementId);
    }
  }

  /**
   * Forces the current rows to store to the database and refresh
   * @param {*} ev the event object
   */
  handleSubmitClick(ev) {
    if (this.state.selectedAgreementId !== null) {
      this.submitChanges();
    }
  }

  /**
   * Forces the current rows to refresh from the database
   * @param {*} ev the event object
   */
  handleCancelClick(ev) {
    if (this.state.selectedAgreementId !== null) {
      this.updateForm(ev, this.state.selectedAgreementId, true);
    }
  }

  /**
   * Loads the agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   */
  loadAgreementData(agreementId) {
    //get the agreement details
    fetchSigned(configData.CONTRACTS_API_URL + agreementId + "/")
      .then(res => res.json())
      .then(
        (result) => {
          const row = result[0];
          this.setState({
            customerName: row.customerName,
            rocheName: row.rocheName,
            title: row.title,
            mappingComments: row.comment,
            validFrom: convertToDate(row.validFrom),
            terminationDate: convertToDate(row.terminationDate),
            entitlementDateValidityVaries: row.entitlementDateValidityVaries,
            protected: row.protected,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Loads the entitlements and stores them in the state
   * @param {*} agreementId the selected agreement id
   */
  loadEntitlementData(agreementId) {
    fetchSigned(configData.ENTITLEMENTS_API_URL + agreementId + "/")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          let selectedEntitlementId = this.state.selectedEntitlement;
          result.forEach(entitlement => {
            if (selectedEntitlementId === null) {
              selectedEntitlementId = entitlement.entitlementId;
            }
            // convert the dates
            entitlement.validFrom = convertToDate(entitlement.validFrom);
            entitlement.terminationDate = convertToDate(entitlement.terminationDate);

            if (entitlement.entitlementType === null) {
              // look up the type from the first mapped pair
              if (entitlement.mappedPairs.length > 0) {
                const mappedPairId = entitlement.mappedPairs[0];
                const mappedPair = this.state.pairs.find(({ termPairingNo }) => termPairingNo === mappedPairId);
                if (mappedPair !== undefined) {
                  if (mappedPair.pairingType === configData.ENTITLEMENT_PROCESSING_ACTION) {
                    entitlement.entitlementType = configData.ENTITLEMENT_PROCESSING_ACTION;
                  } else {
                    entitlement.entitlementType = configData.ENTITLEMENT_DATA_USE;
                  }
                }
              }
            }
          })

          this.setState({
            entitlements: result,
            selectedEntitlement: selectedEntitlementId
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /** Loads term pairs from the API
   * @param {number} agreementId The selected agreement ID
   */
  loadTermPairs(agreementId) {
    this.setState({ loading: true });
    fetchSigned(configData.TERMPAIRS_API_URL + agreementId + "/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            pairs: result,
            loading: false
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error,
            loading: false
          });
        }
      )
  }

  /**
   * Checks the validation for the form
   * @param {*} removeBlankEntitlements If true then blank entitlements are removed 
   * @returns Any vaidation error message or empty string for success
   */
  checkFormValidation() {
    let message = "";
    const validationEntitlements = this.filterBlankEntitlements(this.state.entitlements);

    if (validationEntitlements.length === 0) {
      message = "No entitlement to save, please create at least one and populate it appropriately.";
    }

    // check that all entitlementrs are mapped to something
    validationEntitlements.forEach(entitlement => {
      if ((entitlement.dataCategoryCode === null) || (entitlement.dataUsageCode === null)) {
        message = "Please pick a value for both elements of the entitlement and if needed remove the entitlement added by mistake";
      }
    })
    return message;
  }

  filterBlankEntitlements(entitlements) {
    return entitlements.filter(
      entitlement => !((entitlement.dataCategoryCode === null) &&
        (entitlement.dataUsageCode === null) &&
        (entitlement.mappedPairs.length === 0)));
  }

  /**
   * Validates the form and submits it to the API if valid
   */
  submitChanges() {
    // check the form is valid
    const errorMessage = this.checkFormValidation();
    if (errorMessage) {
      this.setState({ submissionState: errorMessage });
      return;
    }

    const submissionData = {
      entitlements: this.filterBlankEntitlements(this.state.entitlements),
      pairs: this.state.pairs,
      agreement: [{
        customerAgreementId: this.state.selectedAgreementId,
        comment: this.state.mappingComments
      }]
    };

    // more cleaning
    submissionData.entitlements.forEach((entitlemment)=>{
      // truncate the time part of the dates
      if (entitlemment.validFrom !== null) {
        if (entitlemment.validFrom instanceof Date) {
          entitlemment.validFrom = truncateDate(entitlemment.validFrom);
        } else if (typeof (entitlemment.validFrom) === "number") {
          entitlemment.validFrom = new Date(entitlemment.validFrom)
        }
      }
      if (entitlemment.terminationDate !== null) {
        if (entitlemment.terminationDate instanceof Date) {
          entitlemment.terminationDate = truncateDate(entitlemment.terminationDate);
        } else if (typeof (entitlemment.terminationDate) === "number") {
          entitlemment.terminationDate = new Date(entitlemment.terminationDate)
        }
      }
    });


    this.setState({ submissionState: "Saving ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      return fetchSigned(configData.ENTITLEMENTS_API_URL + this.state.selectedAgreementId + "/", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 201) // inserted successfully
          {
            response.json().then((json) => {
              this.updateForm(null, this.state.selectedAgreementId);
              this.setState({ submissionState: "Entitlements Successfully Saved" })
              console.log("API Response" + JSON.stringify(json));
              this.updateForm(null, this.state.selectedAgreementId, true);
            }).catch((error) => {
              this.setState({ submissionState: "Error saving entitlements " + error });
              console.error(error);
            });
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              if (json.errorText.includes('"unique_entitlements"'))
              {
                this.setState({ submissionState: "Error - cannot save duplicate entitlements, please change or delete one of them" });
              } else {
                this.setState({ submissionState: "Error saving entitlements " + json.errorText });
              }
            }).catch((error) => {
              this.setState({ submissionState: "Error saving entitlements " + error })
              console.error(error);
            });
          }
        });
    };
    submitForm();
  }

  /** handles the change of the selected entitlement */
  handleEntitlementSelectionChange(entitlementId) {
    if (this.state.selectedEntitlement !== entitlementId) {
      this.setState({ selectedEntitlement: entitlementId });
    }
  }

  /**
   * handles the addition of a new entitlement
   */
  handleAddEntitlementClick(entitlementType, notMapped = false) {
    const newEntitlements = this.state.entitlements.slice();
    const entitlementId = this.getNewEntitlementId();
    newEntitlements.push({
      customerAgreementId: this.state.selectedAgreementId,
      entitlementId: entitlementId,
      entitlementType: entitlementType,
      notMapped: notMapped,
      notMappedReason: null,
      dataCategoryCode: null,
      dataUsageCode: null,
      validFrom: this.state.validFrom,
      terminationDate: null,
      mappedPairs: [],
      createdBy: this.props.userName,
    })
    this.setState({
      entitlements: newEntitlements,
      selectedEntitlement: entitlementId,
      submissionState: this.UNSAVED_CHANGES_MESSAGE,
    });
    this.props.onUnsavedChangesChange(true);
  }

  /**
   * gets a unique negative number for a new entitlement
   * @returns one loewr than the lowest negative entitlement id in the entitlents
   */
  getNewEntitlementId() {
    let newId = -1;
    this.state.entitlements.forEach((entitlement) => {
      if (entitlement.entitlementId <= newId) {
        newId = entitlement.entitlementId - 1;
      }
    })
    return newId;
  }

  /**
   * removes an entitlement
   */
  handleRemoveEntitlementClick(selectedAgreementId, selectedEntitlement) {
    const newEntitlements = this.state.entitlements.slice();
    const index = newEntitlements.findIndex(({ customerAgreementId, entitlementId }) => customerAgreementId === selectedAgreementId &&
      entitlementId === selectedEntitlement);
    //get the index
    if (index > -1) {
      const entitlementToDelete = newEntitlements[index];
      if (entitlementToDelete.entitlementId > 0) {
        // this is already saved into the database
        // mark if for deletion
        entitlementToDelete.isDeleted = true;
        this.setState({ submissionState: this.UNSAVED_CHANGES_MESSAGE });
        this.props.onUnsavedChangesChange(true);
      }
      else {
        // just remove it
        newEntitlements.splice(index, 1);
      }
    }
    this.setState({ entitlements: newEntitlements });
  }

  /**
   * handles the change of the selection box in the pair table
   */
  handlePairSelectChange(ev, termPairingNo) {
    // get the selected entitlement
    if (this.state.selectedEntitlement === null) {
      //nothing to update
      return;
    } else {
      const newEntitlements = this.state.entitlements.slice();
      const result = newEntitlements.find(({ entitlementId }) => entitlementId === this.state.selectedEntitlement);
      if (result !== undefined) {
        // toggle the value
        const index = result.mappedPairs.indexOf(termPairingNo);
        const currentValue = index > -1 ? true : false;
        if ((ev.type === "selectChange") && (ev.detail === currentValue)) {
          // don't update anything - prevent runaway loop during rendering
          return;
        }

        // toggle the value
        if (index > -1) {
          // remove the selection
          result.mappedPairs.splice(index, 1);
        } else {
          // add the selection
          result.mappedPairs.push(termPairingNo);
        }
        this.setState({ entitlements: newEntitlements,
                        submissionState: this.UNSAVED_CHANGES_MESSAGE,  });
        this.props.onUnsavedChangesChange(true);
      }
    }
  }

  /**
   * Handles changes to a select listbox
   * @param {*} value The selected text in the listbox
   * @param {*} selectedEntitlement The selected entitlement this listbox applies to
   * @param {*} category The Data Category for this listbox
   */
  handleSelectChange(value, selectedEntitlement, category) {
    // find the id for this code
    const refList = this.state.referenceLists[category];
    const reflistItem = refList.find(({ description }) => description === value);
    let selectedRefListId = null;
    if (reflistItem !== undefined) {
      selectedRefListId = reflistItem.refListId;
    }

    // store the new value
    const newEntitlements = this.state.entitlements.slice();
    const entitlement = newEntitlements.find(({ entitlementId }) => entitlementId === selectedEntitlement);
    //get the index
    if (entitlement !== undefined) {
      if (category === "dataCategory") {
        entitlement.dataCategoryCode = selectedRefListId;
      }
      else {
        entitlement.dataUsageCode = selectedRefListId;
      }
    }
    this.setState({ entitlements: newEntitlements,
                    submissionState: this.UNSAVED_CHANGES_MESSAGE,  });
    this.props.onUnsavedChangesChange(true);
  }

  /**
   * Handles data changes for an entitlement
   * @param {*} value the value of the new date
   * @param {*} selectedEntitlement the selected entitlement
   * @param {*} fieldName The fieldname of this data control
   */
  handleEntitlementDateChange(value, selectedEntitlement, fieldName)
  {
     // store the new value
     const newEntitlements = this.state.entitlements.slice();
     const entitlement = newEntitlements.find(({ entitlementId }) => entitlementId === selectedEntitlement);
         //get the index
    if (entitlement !== undefined) {
      entitlement[fieldName] = value;
    }
    
    this.setState({ entitlements: newEntitlements,
      submissionState: this.UNSAVED_CHANGES_MESSAGE,  });
    this.props.onUnsavedChangesChange(true);
  }

  /**
   * handles toggling of the Cannot Map checkbox
   * @param {*} ev the event
   * @param {*} selectedTermPairingNo the term pairing id
   */
  handleCannotMapCheckBoxChange(ev, selectedTermPairingNo) {
    const newPairs = this.state.pairs.slice();
    const result = newPairs.find(({ termPairingNo }) => termPairingNo === selectedTermPairingNo);
    if (result !== undefined) {
      if ((ev.type === "inputChange") && (ev.detail === null)) {
        // don't update anything - prevent null entries
        return;
      }
      // toggle the value
      result.notMapped = ev.detail;
      if (result.notMapped === false) {
        // remove the reason if we are mapped
        result.notMappedReason = null;
      }
      this.setState({ pairs: newPairs,
                      submissionState: this.UNSAVED_CHANGES_MESSAGE,  });
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * Handles updating the cannot map reason
   * @param {*} ev the event
   * @param {*} selectedTermPairingNo the term pairing id
   */
  handleCannotMapReasonChange(ev, selectedTermPairingNo) {
    const newPairs = this.state.pairs.slice();
    const result = newPairs.find(({ termPairingNo }) => termPairingNo === selectedTermPairingNo);
    if (result !== undefined) {
      // toggle the value
      result.notMappedReason = ev.detail;

      this.setState({ pairs: newPairs,
                      submissionState: this.UNSAVED_CHANGES_MESSAGE, });
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * returns true if the pair is selected in the currently selected entitlement
   * @param {*} termPairingNo 
   */
  isPairSelected(termPairingNo) {
    if (this.selectedEntitlement !== null) {
      const result = this.state.entitlements.find(({ entitlementId }) => entitlementId === this.state.selectedEntitlement);
      if (result !== undefined) {
        return result.mappedPairs.includes(termPairingNo);
      }
    }
    return false;
  }

  /**
   * Gets a message to display for the mapping of this pair
   * @param {*} pair the term pair record
   * @returns A string describing the mapping status
   */
  getPairStatusMessage(pair) {
    let mappingCount = 0;
    this.state.entitlements.forEach(entitlement => {
      if (entitlement.mappedPairs.includes(pair.termPairingNo)) {
        mappingCount++;
      }
    })

    let message = "Not mapped yet";
    if (pair.notMapped) {
      message = "Cannot map";
    }
    if (mappingCount > 0) {
      message = mappingCount + " mapping";
      if (mappingCount > 1) {
        message += "s"
      }
      if (pair.notMapped) {
        message = "Warning: both mapped & Cannot map";
      }
    }

    return message;
  }

  /**
   * checks the date validation for the validFrom and terminationDate fields
   * @param {*} entitlement The entitlement
   * @returns true if the validation warning should be displayed
   */
   checkDateWarning(entitlement) {
    // termination date
    if ((entitlement.terminationDate !== null) && (entitlement.terminationDate.length !== 0)) {
      if ((entitlement.validFrom !== null) && (entitlement.validFrom.length !== 0)) {
        if (entitlement.terminationDate < entitlement.validFrom) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Renders the Validity date entry for an entitlement
   * @param {*} entitlement The entitlement
   * @returns the JSX of the controls
   */
  renderEntitlementDateValidity(entitlement){
    return (
      <tr >
        <td>&nbsp;</td>
        <td style={{verticalAlign: "top", paddingTop:"1.6em"}}><b style={{ marginRight: "1em"}} > Validity </b></td>
        <td style={{verticalAlign: "top"}}>
          <OwcTypography variant="caption">Valid From</OwcTypography>
          <OwcDatepicker autoClose label="Valid From"
            value={entitlement.validFrom} format={"dd-MMM-yyyy"}
            onValueChange={(ev) => this.handleEntitlementDateChange(ev.detail, entitlement.entitlementId, "validFrom")}
          >
            <span slot="assistive-text"></span>
          </OwcDatepicker>
          {
            this.checkDateWarning(entitlement)
            ? <OwcTypography style={{"color":"orange"}}variant="caption">Termination Date is less than Valid From</OwcTypography>
            : ""
          }
        </td>
        <td style={{verticalAlign: "top"}}>
          <OwcTypography variant="caption">Termination Date</OwcTypography>
          <OwcDatepicker autoClose label="Termination Date"
            value={entitlement.terminationDate} format={"dd-MMM-yyyy"}
            onValueChange={(ev) => this.handleEntitlementDateChange(ev.detail, entitlement.entitlementId, "terminationDate")}
          >
            <span slot="assistive-text"></span>
          </OwcDatepicker>
          {entitlement.terminationDate === null ?
              <OwcTypography style={{"color":"orange"}}variant="caption">If blank this means the entitlement never expires</OwcTypography> :
          "" }
        </td>
      </tr>
    )
  }

  /**
   * Renders the Pairs table with selction boxes for an entitlement
   * @param {*} entitlementType The type of pair to include in the table
   * @returns the JSX of the controls
   */
  renderSelectablePairs(entitlementType) {
    const pairList = this.state.pairs;
    return (
      <tr >
        <td>&nbsp;</td>
        <td style={{verticalAlign: "top"}}><b style={{ marginRight: "1em", verticalAlign: "top" }} > Maps To </b></td>
        <td colSpan="4">
          <table width="100%" style={{marginBottom:"0.5em"}}>
            <thead>
              <tr style={{ borderBottom: "1px solid #ddd", backgroundColor: "#eee" }}>
                <th align="left">&nbsp;</th>
                <th width="25%" align="left">Type</th>
                <th width="25%" align="left">Data Category</th>
                <th width="50%" align="left">Data Use / Processing Action</th>
              </tr>
            </thead>
            <tbody>
              {pairList.map(item => (
                <tr key={"row" + item.termPairingNo} style={{ fontSize:"0.8em"}}
                  onClick={(ev) => this.handlePairSelectChange(ev, item.termPairingNo)}
                >
                  <td style={{ borderBottom: "1px solid #ddd"}} key={"CheckboxCell" + item.termPairingNo}>
                    <OwcCheckbox key={"CheckboxPair" + item.termPairingNo}
                      checked={this.isPairSelected(item.termPairingNo)}
                    />
                  </td>
                  <td style={{ borderBottom: "1px solid #ddd" }} key={"dataTypeCell" + item.termPairingNo}>{item.pairingType}</td>
                  <td style={{ borderBottom: "1px solid #ddd" }} key={"dataCategoryCell" + item.termPairingNo}>{item.dataCategoryText}</td>
                  <td style={{ borderBottom: "1px solid #ddd" }} key={"dataUsageCell" + item.termPairingNo}>{item.dataUsageText}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    )
  }

  /**
   * Renders the Pairs table read only with cannot map controls
   * @param {*} entitlementType The type of pair to include in the table
   * @returns the JSX of the controls
   */
  renderDisplayPairs(entitlementType, isSelectable = false) {
    const pairList = this.state.pairs.filter(pair =>
      pair.pairingType === entitlementType
    );
    return (
      <OwcTable elevated size='small' style={{ height: 'auto', width:'auto'}} >
        <OwcTableHeader sticky>
          <OwcTableHeaderCell resizable>Data Category</OwcTableHeaderCell>
          <OwcTableHeaderCell resizable>{entitlementType}</OwcTableHeaderCell>
          <OwcTableHeaderCell width="20%" resizable>Status</OwcTableHeaderCell>
          <OwcTableHeaderCell resizable>Cannot Map</OwcTableHeaderCell>
        </OwcTableHeader>
        <OwcTableBody>
          {pairList.map(item => (
            <OwcTableRow key={"DisplayRow" + item.termPairingNo}>
              <OwcTableCell key={"DisplaydataCategoryCell" + item.termPairingNo} valign="middle"
                style={{wordBreak:"normal"}}>{item.dataCategoryText}</OwcTableCell>
              <OwcTableCell key={"DisplaydataUsageCell" + item.termPairingNo} valign="middle"
                style={{wordBreak:"normal"}}>{item.dataUsageText}</OwcTableCell>
              <OwcTableCell key={"DisplaystatusCell" + item.termPairingNo} valign="middle">
                {this.getPairStatusMessage(item)}
              </OwcTableCell>
              <OwcTableCell key={"DisplayCannotMapCell" + item.termPairingNo} valign="middle">
                <div style={{ display: 'flex' }}>
                  <OwcCheckbox key={"DisplayCannotMapCheckBox" + item.termPairingNo}
                    checked={item.notMapped}
                    onValueChange={(ev) => this.handleCannotMapCheckBoxChange(ev, item.termPairingNo)} />
                  <OwcInput
                    key={"DisplayCannotMapReason" + item.termPairingNo}
                    value={item.notMappedReason}
                    style={{ fontSize:"1em"}}
                    placeholder="Reason"
                    label="Reason"
                    disabled={!item.notMapped}
                    onValueChange={(ev) => this.handleCannotMapReasonChange(ev, item.termPairingNo)} />
                </div>
              </OwcTableCell>
            </OwcTableRow>
          ))}
        </OwcTableBody>
      </OwcTable>
    )
  }

  /**
   * Renders the app mapping button
   * @param {*} entitlement_type 
   * @returns The JSX of the control
   */
  renderAddMappingButtons(entitlement_type) {
    return (
      <OwcButton elevated style={{ width: "fit-content", display: "inline-block", marginTop:"0.5em" }}
          onclick={() => this.handleAddEntitlementClick(entitlement_type)}>
        Create Data {entitlement_type === configData.ENTITLEMENT_PROCESSING_ACTION ? "Processing" : "Use"} Entitlement
      </OwcButton>
    )
  }


  /**
   * Rnders the enetitlements table for a specific entitlement type
   * @param {*} entitlement_type The entitlement type specfified
   * @returns The JSX of the controls
   */
  renderEntitlements(entitlement_type) {
    const entitlementList = this.state.entitlements.filter(entitlement =>
      entitlement.entitlementType === entitlement_type
    );
    if (entitlementList !== undefined) {
      return (
        <table style={{ marginTop: "0.8em" }} key={"entitlementHorizontalAlignmentTable" + this.state.selectedAgreementId + entitlement_type}>
          <tbody key={"entitlementHorizontalAlignmentTableBody" + this.state.selectedAgreementId + entitlement_type}>
            {entitlementList.map(entitlement => (this.renderEntitlement(entitlement, entitlement_type)))}
          </tbody>
        </table>
      )
    }
  }

  /**
   * Renders a message detailing who many mapped pairs this entitlement relates to
   * @param {*} entitlement The entitlement
   * @returns A OwcTypography element containing the message
   */
  renderMappedPairsMessage(entitlement) {
    let message = "Select the item(s) below this maps to";
    if (entitlement.mappedPairs.length > 0) {
      message = "Maps to " + entitlement.mappedPairs.length +
        (entitlement.mappedPairs.length === 1 ? " item" : " items");
    };
    return (
      <OwcTypography key={"MappedPairsMessage" + entitlement.entitlementId}>{message}</OwcTypography>
    );
  }

  /**
   * Enders an entitlement row
   * @param {*} entitlement The entitlement
   * @param {*} entitlement_type The type of the entitlement
   * @returns The JSX of the controls
   */
  renderEntitlement(entitlement, entitlement_type) {
    if (this.state.referenceLists.dataCategory === undefined)
    {
      // cannot render without the reference data
      return;
    }
    let keyEnding = entitlement.customerAgreementId + "-" + entitlement.entitlementId + entitlement_type;
    // do not render deleted entitlements
    if (entitlement.isDeleted) {
      return;
    }

    const dataUsageListName = entitlement_type === configData.ENTITLEMENT_PROCESSING_ACTION ?
      "dataProcessingAction" :
      "dataUsage";
    const dataUsageList = this.state.referenceLists[dataUsageListName];
    // -1 allows for the blank option
    let selectedDataCategoryValue = null;
    if (entitlement.dataCategoryCode !== null)
    {
      if ("dataCategory" in this.state.referenceLists){
        const listDataCategoryEntry = this.state.referenceLists.dataCategory.find(({ refListId }) => refListId === entitlement.dataCategoryCode);
        if (listDataCategoryEntry !== undefined) {
          selectedDataCategoryValue = listDataCategoryEntry.description;
        } else {
          const deprectedRefdataEntry = this.state.referenceLists.complete.find(({ refListId }) => refListId === entitlement.dataCategoryCode);
          if (deprectedRefdataEntry !== undefined) {
            selectedDataCategoryValue = deprectedRefdataEntry.description + configData.REFDATA_DEPRECATED;
          }
        }
      }
    } 

    let selectedDataUsageValue = null;
    if (entitlement.dataUsageCode !== null)
    {
      if (dataUsageList){
        const listDataUsageEntry = dataUsageList.find(({ refListId }) => refListId === entitlement.dataUsageCode);
        if (listDataUsageEntry !== undefined) {
          selectedDataUsageValue = listDataUsageEntry.description;
        } else {
          const deprectedRefdataEntry = this.state.referenceLists.complete.find(({ refListId }) => refListId === entitlement.dataUsageCode);
          if (deprectedRefdataEntry !== undefined) {
            selectedDataUsageValue = deprectedRefdataEntry.description + configData.REFDATA_DEPRECATED;
          }
        }
      }
    }
    
    const dataCategoryBlankValue = "Pick a Data Category...";
    const dataUsageBlankValue = "Pick a " + (entitlement.entitlementType === configData.ENTITLEMENT_PROCESSING_ACTION ? "Processing Action" : "Data Use") + "...";
    return (
      <React.Fragment key={keyEnding}>
        <tr
          key={"entitlementHorizontalAlignmentTable" + keyEnding}
          onClick={(ev) => this.handleEntitlementSelectionChange(entitlement.entitlementId)}>
          <td key={"entitlementRadionBtnCell" + keyEnding}>
            <OwcRadio key={"entitlementSelection" + keyEnding}
              checked={entitlement.entitlementId === this.state.selectedEntitlement ? true : false} />
          </td>
          <td width="10%" key={"entitlementTypeCell" + keyEnding}>
            <b style={{ marginRight: "1em", display: "inline-block", verticalAlign: "top" }}
                key={"entitlementTypeCellBold" + keyEnding}>
              {entitlement.entitlementType === configData.ENTITLEMENT_PROCESSING_ACTION ? "Processing Entitlement" : "Data Use Entitlement"}
            </b>
          </td>
          <td width="20%" key={"entitlementDataCategoryCell" + keyEnding}>
            <select style={(selectedDataCategoryValue === null || 
                            selectedDataCategoryValue === dataCategoryBlankValue)?
                              { width: "100%", color:"grey" }:
                              { width: "100%"}}
              id={"entitlementdataCategory" + keyEnding}
              key={"entitlementdataCategory" + keyEnding}
              label="Data Category" value={selectedDataCategoryValue===null?"":selectedDataCategoryValue}
              onChange={(ev) => this.handleSelectChange(ev.target.value, entitlement.entitlementId, "dataCategory")}>
              <option key={"entitlementdataCategory" + keyEnding + "Null"} style={{color:"grey"}}>
                {dataCategoryBlankValue}</option>
              { // if the item point to a deprecated value
                selectedDataCategoryValue !== null && selectedDataCategoryValue !== undefined && 
                  selectedDataCategoryValue.endsWith(configData.REFDATA_DEPRECATED)
                ? <option key={"entitlementdataCategory" + selectedDataCategoryValue} style={{color:"black"}}>
                {selectedDataCategoryValue}</option>
                : ""
              }
              {this.state.referenceLists.dataCategory.map((item, index) => (
                <option
                  key={"entitlementdataCategory" + keyEnding + index} style={{color:"black"}}>
                  {item.description}
                </option>
              ))}
            </select>
          </td>
          <td width="35%" key={"entitlementDataUsageCell" + keyEnding}>
            <select style={(selectedDataUsageValue === null || 
                            selectedDataUsageValue === dataUsageBlankValue)?
                              { width: "100%", color:"grey"}:
                              { width: "100%"}}
              id={"entitlementdataUsage" + keyEnding}
              key={"entitlementdataUsage" + entitlement.customerAgreementId + "-" + entitlement.termPairingNo + entitlement_type}
              label={entitlement_type} value={selectedDataUsageValue===null?"":selectedDataUsageValue}
              onChange={(ev) => this.handleSelectChange(ev.target.value, entitlement.entitlementId, dataUsageListName)}>
              <option key={"entitlementdataUsage" + keyEnding + "Null"}  style={{color:"grey"}}>
                {dataUsageBlankValue}</option>
              { // if the item point to a deprecated value
                selectedDataUsageValue !== null && selectedDataUsageValue !== undefined && 
                selectedDataUsageValue.endsWith(configData.REFDATA_DEPRECATED)
                ? <option key={"entitlementdataUsage" + selectedDataUsageValue} style={{color:"black"}}>
                {selectedDataUsageValue}</option>
                : ""
              }
              {dataUsageList.map((item, index) => (
                <option style={{color:"black"}}
                  key={"entitlementdataUsage" + entitlement.customerAgreementId + "-" + entitlement.termPairingNo + entitlement_type + index}>
                  {item.description}
                </option>
              ))}
            </select>
          </td>
          <td width="20%" key={"entitlementRMappedPairsMessageCell" + keyEnding}>
            {this.renderMappedPairsMessage(entitlement)}
          </td>
          <td width="10%" key={"entitlementCancelBtnCell" + keyEnding}>
            <OwcIconButton id={"pairCancelBtn" + keyEnding}
              key={"pairCancelBtn" + keyEnding}
              icon="circle_clear" style={{ display: "inline-block", verticalAlign: "top" }}
              onclick={() => this.handleRemoveEntitlementClick(entitlement.customerAgreementId, entitlement.entitlementId)} />
            <DelayedTooltip key={"pairCancelBtnToolTip" + keyEnding}
              anchor={"pairCancelBtn" + keyEnding}
              placement="right">Remove this {entitlement.entitlementType}
            </DelayedTooltip>
          </td>
        </tr>
        {entitlement.entitlementId === this.state.selectedEntitlement ? 
          this.renderSelectablePairs(entitlement.entitlementType) : 
          null
        }
        {this.state.entitlementDateValidityVaries && entitlement.entitlementId === this.state.selectedEntitlement ? 
          this.renderEntitlementDateValidity(entitlement) : 
          null
        }
      </React.Fragment>
    )
  }


  /**
   * Renders the form
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    if (this.state.selectedAgreementLoading === true) {
      return (<OwcTypography variant="title6">Loading ...</OwcTypography>);
    } else {
      if (this.state.selectedAgreementId === null) {
        return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an agreement in "Capture Agreement" first</OwcTypography>);
      } else {
        return (
          <div key="dataAgreementContentDiv" style={{ display:"flex", flexDirection:"column" }}>
            {this.state.protected?<AgreementProtectedBanner/>:""}
            <OwcExpandable key="dataProcessing" variant="standard" round 
                expanded={this.state.isDataProcessingExpanded} 
                onExpandedChange={(ev) => this.setState({isDataProcessingExpanded: ev.detail})}>
              <span key="dataProcessingTitle" slot="title">Data Processing Entitlement Mapping</span>
              <span key="dataProcessingContent" slot="content">
                <OwcTypography style={{ fontWeight: "bold" }}>Create Data Processing Entitlements and map them to the related extracts</OwcTypography>
                {this.state.pairs.length === 0 ? "" : this.renderDisplayPairs(configData.ENTITLEMENT_PROCESSING_ACTION)}
                {this.state.entitlements.length === 0 ? (<br/>) : this.renderEntitlements(configData.ENTITLEMENT_PROCESSING_ACTION)}
                {this.state.selectedAgreementId === null ? "" : this.renderAddMappingButtons(configData.ENTITLEMENT_PROCESSING_ACTION)}
              </span>
            </OwcExpandable>
            <OwcExpandable key="dataUsage" variant="standard" round 
                expanded={this.state.isDataUseExpanded} 
                onExpandedChange={(ev) => this.setState({isDataUseExpanded: ev.detail})} >
              <span key="dataUsageTitle" slot="title">Data Use Entitlement Mapping</span>
              <span key="dataUsageContent" slot="content">
                <OwcTypography style={{ fontWeight: "bold" }}>Create Data Use Entitlements and map them to the related extracts</OwcTypography>
                {this.state.pairs.length === 0 ? "" : this.renderDisplayPairs(configData.ENTITLEMENT_DATA_USE)}
                {this.state.entitlements.length === 0 ? (<br/>) : this.renderEntitlements(configData.ENTITLEMENT_DATA_USE)}
                {this.state.selectedAgreementId === null ? "" :  this.renderAddMappingButtons(configData.ENTITLEMENT_DATA_USE)}
              </span>
            </OwcExpandable>
            <OwcExpandable key="mappingComments" variant="standard" round 
                expanded={this.state.isMappingCommentsExpanded} 
                onExpandedChange={(ev) => this.setState({isMappingCommentsExpanded: ev.detail})} >
              <span key="mappingCommentsTitle" slot="title">Mapping Comments</span>
              <span key="mappingCommentsContent" slot="content">
                <OwcInput
                    key={"mappingCommentsTextArea"}
                    rows={this.state.mappingComments == null ? 4 : Math.max(4, this.state.mappingComments.split(/\r\n|\r|\n/).length)}
                    cols="100"
                    label="Mapping Comments"
                    type="textarea"
                    resizable={false}
                    style={{display:"flex", width:"100%"}}
                    value={this.state.mappingComments}
                    onValueChange={(ev) => {
                      if (this.state.mappingComments !== ev.detail) {
                        this.setState({
                          mappingComments: ev.detail,
                          submissionState: this.UNSAVED_CHANGES_MESSAGE, });
                        this.props.onUnsavedChangesChange(true);
                      }
                    }}>
                  <OwcAssistiveText>
                    Free text comments for this agreement, Save Changes will be enabled after clicking outside of this box.
                  </OwcAssistiveText>
                </OwcInput>
              </span>
            </OwcExpandable>
            <table width="100%">
              <tbody>
                <tr>
                  <td align="left">
                    <OwcButton elevated style={{ width: "fit-content" }}
                        onclick={() => this.handleCancelClick()} >
                      Clear Unsaved Changes
                    </OwcButton>
                  </td>
                  <td align="right">
                    <OwcButton style={{ width: "fit-content" }}
                        onclick={() => this.handleSubmitClick()}
                        disabled={((this.state.protected === true)
                                    || ((this.state.submissionState !== "Saving ...") && (this.state.submissionState === null))
                                    ) ? true : false}>
                      {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                    </OwcButton>
                  </td>
                </tr>
              </tbody>
            </table>
            {this.state.protected === true?
              ""
            :
              <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
                {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
              </OwcTypography>
            }
          </div>
        );
      }
    }
  }
}

export default Harmonize;
