import React from 'react';
import {configData} from "../config.js";
import '../styles.scss';

import CustomerAgreementDetails from "../components/agreement/CustomerAgreementDetails.js";
import {formatDate, fetchSigned, joinWithDifferentLastSeperator} from "../shared/Utilities.js";
import DelayedTooltip from "../components/general/DelayedTooltip.js";
import TextWithLookup from "../components/general/TextWithLookup.js";
import AgreementProtectedBanner from "../components/agreement/AgreementProtectedBanner.js";

import {
  OwcButton, OwcExpandable,
  OwcTypography, OwcInput, OwcCheckbox,
  OwcTable, OwcTableHeader, OwcTableHeaderCell,
  OwcTableBody, OwcTableRow, OwcTableCell, OwcIconButton,
  OwcProgressSpinner, OwcAssistiveText
} from '@one/react';

/**
 * The interactive form for mapping cutomer acounts an agreement
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class MapAccounts extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";
  CONFIRMED_STATUS_CONFIRMED = 1;
  CONFIRMED_STATUS_UNCONFIRMED_CHANGE = 2;
  CONFIRMED_STATUS_NOT_CONFIRMED = 3;
  SEARCH_MATCH_ACCOUT_NO = "Lavender";
  SEARCH_MATCH_ACCOUT_NAME = "AntiqueWhite";
  SEARCH_MATCH_LOCATION = "AliceBlue";
  SEARCH_RADII = [1,5,10,50];
  DEFUALT_SEARCH_RADIUS = 1;
  SEARCH_RADIUS_SUFFIX = " km";
  RESULTS_COLOUR_MAPPING = { 
    "account": this.SEARCH_MATCH_ACCOUT_NO,
    "name": this.SEARCH_MATCH_ACCOUT_NAME,
    "location": this.SEARCH_MATCH_LOCATION,
  };
  RESULTS_TEXT_MAPPING = { 
    "account": "account number",
    "name": "account name",
    "location": "account address",
  };

  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedAgreementLoading: false,
      selectedAgreementId: this.props.customerAgreementId,
      submissionState: null,
      agreement: {},
      mappedAccounts: [],
      refListsComplete: {},
      searchName: null,
      searchAccountNo: null,
      searchedAccountNo: null,
      searchAddress: null,
      searchResults: [],
      searchOngoing: false,
      performSearch: true,
      prevLocatedAddress: null,
      selectedLocation: null,
      selectedLocationRelevance: null,
      selectedLocationLat: 0.0,
      selectedLocationLong: 0.0,
      refdataLoaded: false,
      selectedSearchRadius: this.DEFUALT_SEARCH_RADIUS,
      isCustomerAgreementExpanded: true,
      isSearchOptionsExpanded: true,
      isSearchResultsExpanded: true,
      isMappedAccountsExpanded: true,
      collapsedDetailRows: {},
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerAgreementId !== this.props.customerAgreementId) {
      this.updateForm(null, this.props.customerAgreementId, true);
    }
    if (this.state.searchAddress === '') {
      this.setState({
        searchAddress: null,
        searchResults: [],
        searchedAccountNo: null,
        searchLocations: [],
        selectedLocation: null,
        selectedLocationRelevance: null,
        selectedLocationLat: 0,
        selectedLocationLong: 0,
        performSearch: false})
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({ refListsComplete: result,
                        refdataLoaded: true });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({ submissionState: "Error loading reference data " + error });
      }
    )

    this.updateForm(null, this.props.customerAgreementId, true);

  }

  /** Handles the click event on the selection table row
   * @param {*} ev the click event
   * @param {number} agreementId the selected agreement id
   */
  updateForm(ev, agreementId, forceUpdate = false) {
    // if the agreementId is changed then
    if (forceUpdate || (agreementId !== this.state.selectedAgreementId)) {
      // wipe the last agreement
      this.setState({
        selectedAgreementId: agreementId,
        agreement: {},
        submissionState: null,
        selectedLocation: null,
        searchResults: [],
        searchedAccountNo: null,
        selectedLocationRelevance: null,
        mappedAccounts: [],
        searchName: null,
        searchAccountNo: null,
        searchAddress: null,
        searchOngoing: false,
        performSearch: true,
        prevLocatedAddress: null,
        selectedLocationLat: 0.0,
        selectedLocationLong: 0.0,
        selectedSearchRadius: this.DEFUALT_SEARCH_RADIUS,
        collapsedDetailRows: {},
      });
      this.props.onUnsavedChangesChange(false);
      // load the new agreement data
      this.loadAgreementData(agreementId);
      this.loadMappedAccountsData(agreementId);
    }
  }

  /**
   * Forces the current rows to store to the database and refresh
   * @param {*} ev the event object
   */
  handleSubmitClick(ev) {
    if (this.state.selectedAgreementId !== null) {
      this.submitChanges();
    }
  }

  /**
   * Checks if the search location may have been changed and needs to be udapted
   * @returns true if the search location may have been changed and needs to be udapted
   */
  isLocationUpdateNeeded() {
    if (this.state.searchAddress !== null &&
        this.state.searchAddress !== undefined) {
      if ((this.state.prevLocatedAddress !== this.state.searchAddress) ||
          ( this.state.selectedLocationLat === 0.0 &&
            this.state.selectedLocationLong === 0.0 &&
            this.state.searchAddress.length > 0)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Searches for mappable accounts based on the search options
   * @param {*} ev the event object
   */
  handleSearchClick() {
    //in case Account Address changed and Search was clicked without clicking Locate, 
    //call geolocation search which calls Search implicitly (as  performSearch TRUE)
    if (this.isLocationUpdateNeeded()) {
      this.setState({
        performSearch: true,
        prevLocatedAddress: this.state.searchAddress
      },
        () => this.geolocateSearchAddress());
    }
    // or just call search
    else {

      this.setState({
        searchResults: [],
        searchOngoing: true
      });

      //get the selected lat and long
      let lat = 0;
      let long = 0;
      const selectedLocation = this.state.selectedLocation;
      const result = this.state.searchLocations.find(({ label_returned }) => label_returned === selectedLocation);
      if (result !== undefined) {
        lat = result.latitude_returned;
        long = result.longitude_returned;
      }

      const searchOptions = {
        accountName: this.state.searchName === null? this.state.searchName : this.state.searchName.trim(),
        accountNo: this.state.searchAccountNo === null? this.state.searchAccountNo : this.state.searchAccountNo.trim(),
        latitude: lat,
        longitude: long,
        radius: this.state.selectedSearchRadius
      };

      //get the agreement details
      let url = configData.ACCOUNT_MAPPING_API_URL + "search/";
      fetchSigned(url, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(searchOptions)
      })
        .then(res => res.json())
        .then(
          (result) => {
            const reformattedResults = this.reformatSearchResults(result);
            const newState = {
              searchResults: reformattedResults,
              searchedAccountNo: searchOptions.accountNo,
              searchOngoing: false
            }
            if (this.state.submissionState !== null) {
              newState.submissionState = "";
            }
            this.setState(newState);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              submissionState: "Error searching for accounts " + error,
              searchResults: [],
              searchOngoing: false
            });
          }
        )
    }
  }

  /**
   * reformats the results to nest repeated observations of the same account number under the primary record
   * @param {*} results the original results
   */
  reformatSearchResults(results) {
    const refommatedResults = [];
    results.forEach(result => {
      // check if this entry is already in the reformatted results
      const newResultEntry = refommatedResults.find(({ accountNo }) => accountNo === result.accountNo);
      if (newResultEntry !== undefined) {
        // There is already a primary record add this as an additioan observation 
        newResultEntry.additionalObservations.push(result);
      } else {
        // This is the first observation of this account
        result.additionalObservations = []
        refommatedResults.push(result);
      }
    });

    return refommatedResults;
  }

  /**
   * Forces the current rows to refresh from the database
   * @param {*} ev the event object
   */
  handleCancelClick(ev) {
    if (this.state.selectedAgreementId !== null) {
      this.updateForm(ev, this.state.selectedAgreementId, true);
    }
  }

  /**
   * Resets the search options to the defaults
   */
  handleResetClick() {
    const agreement = this.state.agreement;
    let address = agreement.customerAddress;
    if (agreement.customerCountry) {
      address += ", " + agreement.customerCountry
    }
    this.setState({ 
      searchName: agreement.customerName,
      searchAccountNo: agreement.customerAccountNo,    
      selectedSearchRadius: this.DEFUALT_SEARCH_RADIUS,
      searchAddress: address},
      () => this.geolocateSearchAddress());
  }

  /**
   * Loads the agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   */
   loadAgreementData(agreementId) {
    this.setState({selectedAgreementLoading:true});
    //get the agreement details
    let url = configData.CONTRACTS_API_URL + agreementId + "/";
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          const row = result[0];
          this.setState({ agreement: row,
            performSearch: true,
            selectedAgreementLoading:false},
            () => this.handleResetClick());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            submissionState: "Error loading agreement data " + error,
          });
        }
      )
   }

  /**
   * Loads mapped accounts for agreement and stores the required data in the state
   * @param {*} agreementId the selected agreement id
   */
   loadMappedAccountsData(agreementId) {
    console.log("Loading mapped accounts for ", agreementId)
    this.setState({mappedAccounts: [],
                   selectedMappingsLoading:true});
    //get the agreement details
    let url = configData.ACCOUNT_MAPPING_API_URL + "mapped_accounts/" + agreementId + "/";
    fetchSigned(url)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({ 
            mappedAccounts: result,
            selectedMappingsLoading:false
          })
          },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            submissionState: "Error loading mapped accounts " + error,
          });
        }
      )
  }

  /**
   * Validates the form and submits it to the API if valid
   */
  submitChanges() {

    const submissionData = {
      mappings: this.state.mappedAccounts,
      agreement:  {customerAgreementId: this.state.selectedAgreementId,
        mappingComments: this.state.agreement.accountMappingComment
        }
    };

    console.log("Submitting ...", submissionData);

    this.setState({ submissionState: "Saving ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      return fetchSigned(configData.ACCOUNT_MAPPING_API_URL + "store/", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
        .then((response) => {
          console.log(response);
          if (response.status === 201) // inserted successfully
          {
            response.json().then((json) => {
              this.updateForm(null, this.state.selectedAgreementId);
              this.setState({ submissionState: "Account mappings successfully saved" })
              this.props.onAgreementListChanged(this.state.selectedAgreementId);
              console.log("API Response" + JSON.stringify(json));
              this.updateForm(null, this.state.selectedAgreementId, true);
            }).catch((error) => {
              this.setState({ submissionState: "Error saving account mappings " + error });
              console.error(error);
            });
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              this.setState({ submissionState: "Error saving account mappings " + json.errorText });
            }).catch((error) => {
              this.setState({ submissionState: "Error saving account mappings " + error })
              console.error(error);
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          let errorMsg = String(error);
          if (errorMsg.includes("Timeout")) {
            errorMsg = "- timed out after 30 seconds waiting for a response, the system will continue attempting to save for up to 15 min.  Clear Unsaved Changes will refresh with the current mapped accounts.";
          }
          this.setState({
            submissionState: "Error saving account mappings " + errorMsg
          });
        });
    };
    submitForm();
  }

  /**
   * handles toggling of the confirmed checkbox
   * @param {*} ev the event
   * @param {*} selectedAccountNumber the account number of the selected row
   */
  handleMappingCheckBoxChange(ev, selectedAccountNumber) {
    const newMappedAccounts = this.state.mappedAccounts.slice();
    const result = newMappedAccounts.find(({ accountNumber }) => accountNumber === selectedAccountNumber);
    if (result !== undefined) {
      if ((ev.type === "inputChange") && (ev.detail === null)) {
        // don't update anything - prevent null entries
        return;
      }
      // set the value
      result.confirmedStatusCode = ev.detail? this.CONFIRMED_STATUS_CONFIRMED : this.CONFIRMED_STATUS_NOT_CONFIRMED;
      result.confirmedStatusChangeDate = new Date(Date.now());
      result.confirmedStatusChangeBy = this.props.userName;

      if (result.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
        // save the original mapped values
        result.originalAccountName = result.mappedAccountName;
        result.originalAccountAddress = result.mappedAccountAddress;
        // set the mapped name and address to match the source name and address
        result.mappedAccountName = result.sourceAccountName;
        result.mappedAccountAddress = result.sourceAccountAddress;
      }
      if ((result.confirmedStatusCode === this.CONFIRMED_STATUS_NOT_CONFIRMED) &&
          ("originalAccountName" in result)) {
        // restore the orinal mapped values
        result.mappedAccountName = result.originalAccountName;
        result.mappedAccountAddress = result.originalAccountAddress;
      }

      this.setState({ mappedAccounts: newMappedAccounts,
                      submissionState: this.UNSAVED_CHANGES_MESSAGE});
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * handles removing a mapped account
   * @param {*} ev the event
   * @param {*} selectedAccountNumber the account number of the selected row
   */
  handleRemoveMappingClick(selectedAccountNumber) {
    const newMappedAccounts = this.state.mappedAccounts.slice();
    const index = newMappedAccounts.findIndex(({ accountNumber }) => accountNumber === selectedAccountNumber);
    if (index !== -1) {
      // remove the entry
      newMappedAccounts.splice(index,1);

      this.setState({ mappedAccounts: newMappedAccounts,
                      submissionState: this.UNSAVED_CHANGES_MESSAGE,  });
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * Handles requests to map an account
   * @param {*} row The row of the account to map
   */
  handleMapClick(row) {
    const newMappedAccounts = this.state.mappedAccounts.slice();
    // add a new mapped account
    const newMapping = { 
      customerAgreementId: this.state.selectedAgreementId,
      accountNumber: row.accountNo,
      mappedAccountName: row.accountName,
      sourceAccountName: row.accountName,
      mappedAccountAddress: row.textSearched,
      sourceAccountAddress: row.textSearched,
      confirmedStatusCode: this.CONFIRMED_STATUS_CONFIRMED,
      confirmedStatusChangeDate: new Date(Date.now()),
      confirmedStatusChangeBy: this.props.userName,
    }
    
    newMappedAccounts.push(newMapping);
    
    this.setState({mappedAccounts: newMappedAccounts,
        submissionState: this.UNSAVED_CHANGES_MESSAGE});    
    this.props.onUnsavedChangesChange(true);
  }

  /**
   * Handles requests to expand or collapse an expandable row.
   * @param {*} expanded boolean of if the row is to be expanded, false for collapsed
   * @param {*} accountNo The account number of the row
   */
  handleRowExpandChange(expanded, accountNo) {
    const collapsedRows = {...this.state.collapsedDetailRows};
    collapsedRows[accountNo] = expanded;
    this.setState({collapsedDetailRows:collapsedRows});
  }

  /**
   * Gets a formatted string of the mapped and source values
   * @param {*} mappedValue the value when it was mapped
   * @param {*} sourceValue the current value in the source data
   * @returns A formatted string
   */
  getMappedInfoMessage(mappedValue, sourceValue) {
    if (mappedValue === sourceValue) {
      return mappedValue;
    }
    else {
      return `As Mapped: ${mappedValue}\nNew Value: ${sourceValue}`
    }
  }
  
  /**
   * Gets a formatted string of the mapped and source values for use in a tooltip
   * @param {*} fieldName the name of the field for the tooltip
   * @param {*} mappedValue the value when it was mapped
   * @param {*} sourceValue the current value in the source data
   * @returns A formated string
   */
   getMappedInfoTooltip(fieldName, mappedValue, sourceValue) {
    if (mappedValue === sourceValue) {
      return mappedValue;
    }
    else {
      return `${fieldName} has changed at source since it was mapped`
    }
  }

  /**
   * Gets a formatted string of the confirmed status for use in a tooltip
   * @param {*} confirmedStatusCode the confirmed status code
   * @param {*} confirmedStatusChangeDate the date the status changed
   * @param {*} confirmedStatusChangeBy who made the change
   * @returns A string of the confirmed status
   */
  getConfirmedStatusTooltip(confirmedStatusCode, confirmedStatusChangeDate, confirmedStatusChangeBy) {
    let dateString = formatDate(confirmedStatusChangeDate, false);
    if (confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
      return `Confirmed on ${dateString} by ${confirmedStatusChangeBy}`;
    }
    else {
      if (confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE) {
        return `Unconfirmed change in the customer agreement on ${dateString}, the mapping needs to be re-confirmed`;
      } else {
        return `Not Confirmed set on ${dateString} by ${confirmedStatusChangeBy}`;
      }
    }
  }

  /**
   * geolocates the entered search address
   */
  geolocateSearchAddress() {
    const addressToLocate = this.state.searchAddress;
    const performSearchImmediately = this.state.performSearch;
    const url = configData.ACCOUNT_MAPPING_API_URL + "location/?locationText=" + encodeURIComponent(addressToLocate);

    fetchSigned(url)
      .then(res => res.json())
      .then(
        (locations) => {
          let selectedLocation = null;
          let selectedLocationRelevance = null;
          let selectedLocationLat = 0;
          let selectedLocationLong = 0;
          if ((locations !== undefined) && (locations.length > 0))
          {
            selectedLocation = locations[0].label_returned;
            selectedLocationRelevance = locations[0].relevance;
            selectedLocationLat = locations[0].latitude_returned;
            selectedLocationLong = locations[0].longitude_returned;
          }
          this.setState({searchLocations: locations,
            selectedLocation: selectedLocation,
            selectedLocationRelevance: selectedLocationRelevance,
            selectedLocationLat: selectedLocationLat,
            selectedLocationLong: selectedLocationLong,
            prevLocatedAddress: addressToLocate,
            performSearch: false},
            () => {
              if (performSearchImmediately) {
                this.handleSearchClick();
              }
            });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Renders the Customer agreement details
   * @returns The JSX of the controls
   */
   renderMappedAccounts() {
    const mappedAccounts = this.state.mappedAccounts;
    if (mappedAccounts.length === 0) {
      return (
        <OwcTypography style={{ fontWeight: "bold" }}>No Mapped Accounts</OwcTypography>
      );
    }
    
    return (
      <OwcTable size='small'>
        <OwcTableHeader elevated sticky shrink>
          <OwcTableHeaderCell width="10%" resizable>Confirmed</OwcTableHeaderCell>
          <OwcTableHeaderCell width="15%" resizable>Account No</OwcTableHeaderCell>
          <OwcTableHeaderCell width="30%" resizable>Account Name</OwcTableHeaderCell>
          <OwcTableHeaderCell width="40%" resizable>Account Address</OwcTableHeaderCell>
          <OwcTableHeaderCell width="5%"></OwcTableHeaderCell>
        </OwcTableHeader>
        <OwcTableBody>
          {mappedAccounts.map(item => this.renderMappingRow(item))}
        </OwcTableBody>
      </OwcTable>
    );
  }

  /**
   * Renders the mapped account row
   * @param {*} item the data for this mapped account
   * @returns The JSX of the row
   */
  renderMappingRow(item) {
    if (("removed" in item) && (item.removed === true)) {
      return;
    } else {
      // determine the status and therefore colour of the row
      let rowColour = configData.COLOUR_WHITE
      if (item.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED) {
        rowColour = configData.COLOUR_GREEN
      }
      if ((item.mappedAccountName !== item.sourceAccountName) || 
          (item.mappedAccountAddress !== item.sourceAccountAddress) ||
          (item.confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE)) {
        rowColour = configData.COLOUR_YELLOW
      }
      let addressText = this.getMappedInfoMessage(item.mappedAccountAddress, item.sourceAccountAddress);
      // if null replace with empty string
      addressText=(addressText===null?"":addressText);

      let accountNameText = this.getMappedInfoMessage(item.mappedAccountName, item.sourceAccountName);
      // if null replace with empty string
      accountNameText=(accountNameText===null?"":accountNameText);
      
      return(
        <OwcTableRow key={"MappedAccountRow" + item.accountNumber}>
          <OwcTableCell key={"ConfirmedCell" + item.accountNumber} valign="middle" style={{backgroundColor:rowColour}}>
            <OwcCheckbox key={"ConfirmedCellCheckBox" + item.accountNumber}
              id={"ConfirmedCellCheckBox" + item.accountNumber}
              checked={item.confirmedStatusCode === this.CONFIRMED_STATUS_CONFIRMED}
              indeterminate={item.confirmedStatusCode === this.CONFIRMED_STATUS_UNCONFIRMED_CHANGE}
              onValueChange={(ev) => this.handleMappingCheckBoxChange(ev, item.accountNumber)} />
            <DelayedTooltip key={"AccountNameToolTip" + item.accountNumber}
                anchor={"ConfirmedCellCheckBox" + item.accountNumber}
                placement="left">
              {this.getConfirmedStatusTooltip(item.confirmedStatusCode, item.confirmedStatusChangeDate, item.confirmedStatusChangeBy)}
            </DelayedTooltip>
          </OwcTableCell>
          <OwcTableCell key={"AccountNoCell" + item.accountNumber} valign="middle" style={{backgroundColor:rowColour}}>
            <TextWithLookup text={item.accountNumber} 
              onClick={() => this.props.onAccountSearchClick(item.accountNumber)} 
              iconTooltip="Search for agreements for this account"
            />
          </OwcTableCell>
          <OwcTableCell key={"AccountNameCell" + item.accountNumber} valign="middle" style={{backgroundColor:rowColour, wordBreak:"normal"}}>
            {accountNameText.split(/\r\n|\r|\n/).length === 1?
              accountNameText
            :
              <pre key={"AccountNameCellText" + item.accountNumber}
                id={"AccountNameCellText" + item.accountNumber}>
                {accountNameText}
              </pre>
            }
          </OwcTableCell>
          <DelayedTooltip key={"AccountNameToolTip" + item.accountNumber}
              anchor={"AccountNameCellText" + item.accountNumber}>
            {this.getMappedInfoTooltip("Account Name", item.mappedAccountName, item.sourceAccountName)}
          </DelayedTooltip>
          <OwcTableCell key={"AccountAddressCell" + item.accountNumber} 
            valign="middle" style={{backgroundColor:rowColour, wordBreak:"normal"}}
          >
            {addressText.split(/\r\n|\r|\n/).length === 1?
              addressText
            :
              <pre key={"AccountAddressCellText" + item.accountNumber}
                id={"AccountAddressCellText" + item.accountNumber}>
                {addressText}
              </pre>
            }
          </OwcTableCell>
          <DelayedTooltip key={"AccountAddressToolTip" + item.accountNumber}
            anchor={"AccountAddressCellText" + item.accountNumber}>
            {this.getMappedInfoTooltip("Address", item.mappedAccountAddress, item.sourceAccountAddress)}
          </DelayedTooltip>
          <OwcTableCell key={"AccountRemoveCell" + item.accountNumber} valign="middle" style={{backgroundColor:rowColour}}>
            <OwcIconButton id={"AccountRemovBtn" + item.accountNumber}
              key={"AccountRemovBtn" + item.accountNumber}
              icon="circle_clear" style={{ display: "inline-block", verticalAlign: "top" }}
              onclick={() => this.handleRemoveMappingClick(item.accountNumber)} />
            <DelayedTooltip key={"AccountRemovBtnToolTip" + item.accountNumber}
              anchor={"AccountRemovBtn" + item.accountNumber}
              placement="right">
              Remove this mapped account
            </DelayedTooltip>
          </OwcTableCell>

        </OwcTableRow>
      );
    }
  }
  
  /**
   * Renders the current mapped accounts table
   * @returns The JSX of the controls
   *         onKeyDown={(ev) => {this.setState({submissionState: this.UNSAVED_CHANGES_MESSAGE})
        console.log("key Down");
        console.log(ev);}}
   */
   renderMappingComments() {
    return (
      <OwcInput
          key="mappingCommentsTextArea"
          rows={
            this.state.agreement.accountMappingComment == null 
              ? 4
              : Math.max(4, this.state.agreement.accountMappingComment.split(/\r\n|\r|\n/).length)
          }
          cols="100"
          label="Mapping Comments"
          style={{display:"flex", width:"100%"}}
          value={this.state.agreement.accountMappingComment}
          onValueChange={(ev) => {
            const updatedAgreement = {...this.state.agreement};
            updatedAgreement.accountMappingComment = ev.detail;
            this.setState({
              agreement: updatedAgreement,
              submissionState: this.UNSAVED_CHANGES_MESSAGE,
            });
            this.props.onUnsavedChangesChange(true);
          }}
          type="textarea"
          no-clear-icon resizable="false">
        <OwcAssistiveText>
          Free text comments for mapping this agreement to customer accounts
        </OwcAssistiveText>
      </OwcInput>
    );
  }

  /**
   * Renders the current geolocated address
   * @returns The JSX of the controls
   */
  renderLocatedAddress() {
    if ((this.state.searchLocations === undefined) || (this.state.searchLocations.length === 0)) {
      return;
    }
    
    if (this.state.searchLocations.length === 1) {
      return (<OwcTypography key="SearchLocationsLabel">{this.state.searchLocations[0].label_returned}</OwcTypography>);
    }
    
    return (
      <select key="SearchLocationsDropDown" value={this.state.selectedLocation}
        onChange={(ev) => {
            const result = this.state.searchLocations.find(({ label_returned }) => label_returned === ev.target.value);
            //get the selected lat and long
            let lat = 0;
            let long = 0;
            if (result !== undefined) {      
              lat = result.latitude_returned;
              long = result.longitude_returned;
              this.setState({selectedLocation: ev.detail,
                             selectedLocationRelevance: result.relevance,
                             selectedLocationLat: lat,
                             selectedLocationLong: long});
            }
            else {
              this.setState({selectedLocation: null,
                selectedLocationRelevance: 0,
                selectedLocationLat: 0,
                selectedLocationLong: 0});
            }
          }
        }
      >
        {this.state.searchLocations.map((location, index) => (
          <option key={"SearchLocations" + location.label_returned + index}>{location.label_returned}</option>
        ))}
      </select>
    );
    
  }

  /**
   * Renders the search options controls
   * @returns The JSX of the controls
   */
   renderSearchOptions() {
    console.log(`prev ='${this.state.prevLocatedAddress}' searchAddress  ='${this.state.searchAddress}'`);
    return (
      <div>
      <table width="100%">
        <tbody>
          <tr valign="centre" id="CustomerNameRow">
            <td style={ ((this.state.searchName === undefined) || (this.state.searchName === null) || (this.state.searchName.length === 0))
              ? {"fontWeight":"bold", color: "grey"}
              : {"fontWeight":"bold"}
              }>Account Name</td>
            <td colSpan="4">
              <OwcInput id="CustomerNameInput" style={{width:"auto"}}
                value={this.state.searchName}
                placeholder="type to include in search"
                onValueChange={(ev) => this.setState({searchName:ev.detail})} />
            </td>
          </tr>
          <tr valign="centre">
            <td style={ ((this.state.searchAccountNo === undefined) || (this.state.searchAccountNo === null) || (this.state.searchAccountNo.length === 0))
              ? {"fontWeight":"bold", color: "grey"}
              : {"fontWeight":"bold"}
              }>Account Number</td>
            <td colSpan="4">
              <OwcInput id="CustomerAccountInput" style={{width:"auto"}}
                value={this.state.searchAccountNo}
                placeholder="type to include in search"
                onValueChange={(ev) => this.setState({searchAccountNo:ev.detail})} />
            </td>
          </tr>
          <tr valign="centre">
            <td style={ (this.state.searchAddress === undefined) || ((this.state.searchAddress === null) || (this.state.searchAddress.length === 0))
              ? {"fontWeight":"bold", color: "grey"}
              : {"fontWeight":"bold"}
            }>
              Account Address
            </td>
            <td colSpan="3">              
              <OwcInput id="CustomerAddressInput" style={{width:"auto"}}
                  value={this.state.searchAddress}
                  placeholder="type to include in search"
                  onValueChange={(ev) => this.setState({searchAddress: ev.detail})} />
            </td>
            <td> 
              <OwcButton
                  style={{width: "fit-content"}} 
                  onClick = {() => this.geolocateSearchAddress()} 
                  disabled={this.isLocationUpdateNeeded() === false ? true : false} >
                Locate
              </OwcButton>
            </td>
          </tr>
          <tr valign="centre">
            <td width="20%" style={{"fontWeight":"bold"}}>Located Address</td>
            <td width="30%">{this.renderLocatedAddress()}</td>
            <td width="20%" style={{"fontWeight":"bold"}}>Search Radius</td>
            <td width="30%">
              <select value={this.state.selectedSearchRadius + this.SEARCH_RADIUS_SUFFIX}
                onChange={(ev) => {
                    const number = ev.target.value.slice(0, -this.SEARCH_RADIUS_SUFFIX.length);
                    this.setState({selectedSearchRadius: parseInt(number)});
                  }
                }
              >
                {this.SEARCH_RADII.map(value => (
                  <option key={"SearchRadius" + value}>{value + this.SEARCH_RADIUS_SUFFIX}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr valign="centre">
            <td colSpan="2">&nbsp;</td>
            <td><b>Latitude:</b> {this.state.selectedLocationLat.toFixed(5)}</td>
            <td><b>Longitude:</b> {this.state.selectedLocationLong.toFixed(5)}</td>
          </tr>
        </tbody>
      </table>
      <DelayedTooltip
          anchor={"CustomerNameInput"}
          placement="right">
        Matches based on similarity.
      </DelayedTooltip>
      <DelayedTooltip
          anchor={"CustomerAccountInput"}
          placement="right">
        Support wildcards: % matches any sequence of zero or more characters, _ matches any singly character. \% or \_ matches % and _ respectively.
      </DelayedTooltip>
      <table width="100%">
        <tbody>
          <tr>
            <td align="left">
              <OwcButton style={{ width: "fit-content" }}
                onclick={() => this.handleResetClick()}
              >
                Reset to default
              </OwcButton>
            </td>
            <td align="right">
              <OwcButton style={{ width: "fit-content" }}
                onclick={() => this.handleSearchClick()}
                disabled={this.state.searchOngoing || this.state.performSearch }
              >
                {this.state.searchOngoing || this.state.performSearch ? "Searching ..." : "Search"}
              </OwcButton>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    );
  }

  /**
   * Renders the search results section
   * @returns The JSX of the controls
   */
   renderSearchResults() {
    return (
      <div>
        <OwcTypography style={{ fontWeight: "bold" }}>Possible accounts to map from the accounts data source, matched by:</OwcTypography>
        <table width="100%" border="1" cellSpacing="0">
          <tbody>
            <tr>
              <td width="33%" align="center" style={{backgroundColor:this.SEARCH_MATCH_ACCOUT_NO, fontWeight:"bold"}}>
                Account Number
              </td>
              <td width="33%" align="center"  style={{backgroundColor:this.SEARCH_MATCH_ACCOUT_NAME, fontWeight:"bold"}}>
                Account name
              </td>
              <td width="33%" align="center"  style={{backgroundColor:this.SEARCH_MATCH_LOCATION, fontWeight:"bold"}}>
                Location
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
        {this.renderSearchResultsTable()}
      </div>
    );
  }

  /**
   * Renders the search results table
   * @returns The JSX of the controls
   */
  renderSearchResultsTable() {
    if (this.state.searchOngoing || this.state.performSearch) {
      return (
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
			    <OwcProgressSpinner style={{marginTop:'30px'}}/>
		    </div>
      );
    }

    const searchResults = this.state.searchResults;
    if ((searchResults === undefined) || (searchResults === null) || (searchResults.length === 0))
    {
      return (
        <OwcTypography>No results found, change the search options and search again.</OwcTypography>
      );
    }
    return (
      <OwcTable style={{ display: "block" }} size='dense' height="auto" elevated showRowExpanderColumn>
          <OwcTableHeader elevated sticky shrink>
            <OwcTableHeaderCell width="15%" resizable>Account Number</OwcTableHeaderCell>
            <OwcTableHeaderCell width="23%" resizable>Account Name</OwcTableHeaderCell>
            <OwcTableHeaderCell width="29%" resizable>Geolocated Address</OwcTableHeaderCell>
            <OwcTableHeaderCell width="15%" resizable>Distance (km)</OwcTableHeaderCell>
            <OwcTableHeaderCell></OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody>
            {searchResults.map(row => this.renderSearchResultsRow(row))}
          </OwcTableBody>
      </OwcTable>
    );
  }

  /**
   * returns true if the account number is already in the mapped accounts table (and not deleted)
   * @param {*} searchAccountNo 
   */
  isMapped(searchAccountNo) {
    let retVal = false;
    if (this.state.mappedAccounts) {
      const mappedAccountEntry = this.state.mappedAccounts.find(({ accountNumber }) => accountNumber === searchAccountNo);
      if (mappedAccountEntry !== undefined) {
        if (("removed" in mappedAccountEntry) && (mappedAccountEntry.removed === true)) {
          retVal = false;
        } else {
          retVal = true;
        }
      }
    }
    return retVal;
  }


  /**
   * returns the text for a tooltip for the search results item
   * @param {*} row the row of the search results
   */
  getSearchResultsTooltip(row) {
    if (row.matchedBy === "account") {
      const textToDisplay = this.getAccountObservationTextList(row);
      if (textToDisplay.length > 0) {
        return textToDisplay[0].text; 
      }
    } else if (row.matchedBy === "name") {
      return(
        <>
        Matched by <span style={{fontWeight:800}}>{this.RESULTS_TEXT_MAPPING[row.matchedBy]}</span> in the search based on similarity
        </>
        );
    } else if (row.matchedBy === "location") {
      return(
        <>
          has a geolocated address ({row.address}) 
          { ((row.distance !== null) && (this.state.searchAddress)) ? 
            (
              <>
               <br/>that is&nbsp;
              <span style={
                parseFloat(row.distance) > parseFloat(this.state.selectedSearchRadius)?
                  {color:"red", fontWeight:800}
                :
                  {fontWeight:800}
                }>
                  {row.distance.toFixed(2)}km
                </span> away
                </>
            ) :
            <></>
           }
           &nbsp;in the search.
        </>
        );
    }
  }

  /**
   * Renders a row of search results
   * @returns The JSX of the controls
   */
  renderSearchResultsRow(row) {
    let colour = "white";
    if (row.matchedBy in this.RESULTS_COLOUR_MAPPING) {
      colour = this.RESULTS_COLOUR_MAPPING[row.matchedBy];
    }

    if (!this.isMapped(row.accountNo))
    {
      const rowKey = row.accountNo + "|" + row.address + "|" + row.distance
      return(
        <OwcTableRow 
            key={"ResultsRow" + rowKey} 
            expandable 
            expanded={this.state.collapsedDetailRows[row.accountNo] === undefined ? true : this.state.collapsedDetailRows[row.accountNo] } 
            onExpandedChange={(ev) => this.handleRowExpandChange(ev.detail, row.accountNo)}
            height="auto">
          <OwcTableCell key={"ResultsAccountCell" + rowKey} id={"ResultsAccountCell" + rowKey} valign="middle" style={{backgroundColor: colour}}>
            {row.accountNo}
            <DelayedTooltip key={"ResultsAccountCellToolTip" + rowKey} anchor={"ResultsAccountCell" + rowKey}>
              {this.getSearchResultsTooltip(row)}
            </DelayedTooltip>
          </OwcTableCell>
          <OwcTableCell key={"ResultNameCell" + rowKey} valign="middle" style={{backgroundColor: colour, wordBreak:"normal"}}>
            {row.accountName}
          </OwcTableCell>
          <OwcTableCell key={"ResultsAddressCell" + rowKey} id={"ResultsAddressCell" + rowKey} valign="middle" style={{backgroundColor: colour, wordBreak:"normal"}}>
            {row.address}
            <DelayedTooltip key={"ResultsAccountCellToolTip" + rowKey} anchor={"ResultsAddressCell" + rowKey}>
              {"Account Address Searched: " + row.textSearched}
            </DelayedTooltip>
          </OwcTableCell>
          <OwcTableCell key={"ResultsDistanceCell" + rowKey} 
              valign="middle"
              style={
                parseFloat(row.distance) > parseFloat(this.state.selectedSearchRadius)?
                  {color:"red", backgroundColor: colour}:
                  {backgroundColor: colour}
              }>
            {row.distance === null ? null : this.state.searchAddress ? row.distance.toFixed(2): 'n.a.'}
          </OwcTableCell>
          <OwcTableCell key={"ResultsMappingCell" + rowKey} valign="middle" style={{backgroundColor: colour}}>
            <OwcButton style={{width: "fit-content"}} onclick={() => this.handleMapClick(row)}>
              Map
            </OwcButton>
          </OwcTableCell>
          <div key={"expandedObservationsRow" + rowKey} slot="expanded">
            {this.renderSearchEvidence(row)}
          </div>
        </OwcTableRow>
      );
    }
  }

  /**
   * Gets the text that describes how this account relates to the search terms through accounts
   * @param {*} row The search data row for this match
   * @returns The text to display
   */
  getAccountObservationTextList(row) {
    let observations = [row];
    observations = observations.concat(row.additionalObservations);
    const textToDisplay = [];

    // process the matches by account
    const accountObservations = observations.filter(value =>
      value.matchedBy === "account"
    );

    const accountObservationsGrouped = {};
    let isExactMatch = false;
    for (const observation of accountObservations) {
      //if we get an exact match
      if (observation.searchedBy === row.accountNo){
        isExactMatch = true;
      }

      //group by the account searched by and the relations found in the installed base
      let relationList = []
      if (observation.searchedBy in accountObservationsGrouped) {
        relationList = accountObservationsGrouped[observation.searchedBy]
      }
      if (relationList.includes(observation.relation) === false) {
        relationList.push(observation.relation);
      }
      accountObservationsGrouped[observation.searchedBy] = relationList;
    }

    if(isExactMatch) {
      textToDisplay.push({text: (
        <>
        Matched by <span style={{fontWeight:800}}>{this.RESULTS_TEXT_MAPPING[row.matchedBy]} {row.accountNo}</span> in the search
        </>
        ),
        matchedBy: "account"
      });

    }

    for (const [searchedBy, relationList] of Object.entries(accountObservationsGrouped)) {
      textToDisplay.push({text: (
        <>
        The searched account <span style={{fontWeight:800}}>{searchedBy}</span> relates 
        to a device that has <span style={{fontWeight:800}}>{row.accountNo}</span> as <span style={{fontWeight:800}}>{joinWithDifferentLastSeperator(relationList)}</span> account
        </>
        ),
        matchedBy: "account"
      });
    }

    return textToDisplay;
  }

  /**
   * Renders the search evidence table
   * @returns The JSX of the controls
   */
   renderSearchEvidence(row) {
    let observations = [row];
    observations = observations.concat(row.additionalObservations);
    const textToDisplay = this.getAccountObservationTextList(row);

    // then process the matches by name
    const nameObservations = observations.filter(value =>
      value.matchedBy === "name"
    );
    for (const observation of nameObservations) {
      textToDisplay.push({
        text: this.getSearchResultsTooltip(observation),
        matchedBy: observation.matchedBy
      });
    }

    // then process the matches by location
    const locationObservations = observations.filter(value =>
      value.matchedBy === "location"
    );
    for (const observation of locationObservations) {
      textToDisplay.push({
        text: this.getSearchResultsTooltip(observation),
        matchedBy: observation.matchedBy
      });
    }

    return (
      <div key={"SearchEvidenceTableDiv"} style={{display:"block", maxWidth:"90%"}}>
        <table key={"SearchEvidenceTable" + row.accountNo}  style={{marginLeft:"5%", width:"100%"}}>
          <tbody>
          {
            textToDisplay.map((entry, index) => this.renderSearchEvidenceRow(
              entry.text, 
              row.accountNo + "|" + index, 
              entry.matchedBy
            ))
          }
          </tbody>
        </table>
      </div>);
  }

  /**
   * Renders the evidence items for this search item
   * @param {*} text The text to display
   * @param {*} key the key to specify this as a unique row
   * @param {*} matchedBy How this search item was matched to the search criteria
   * @returns The JSX of the row
   */
  renderSearchEvidenceRow(text, key, matchedBy) {
    return (
      <tr key={"ObsRow" + key} id={"ObsRow" + key} 
          style={{
            borderBottom: "1px solid #ddd", 
            backgroundColor:this.RESULTS_COLOUR_MAPPING[matchedBy] || "white"
          }}>
        <td key={"ObsAddressCell" + key} 
            id={"ObsAddressCell" + key}
            style={{paddingRight:"1em"}}>
          {text}
        </td>
      </tr>
    );
  }


  /**
   * Renders the form
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    if (this.state.selectedAgreementId === null) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an agreement in "Capture Agreement" first</OwcTypography>);
    } 

    if (this.state.selectedAgreementLoading === true || this.state.refdataLoaded === false) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Loading ...</OwcTypography>);
    } 
  
    return (
      <div style={{ display:"flex", flexDirection:"column" }}>
        {this.state.agreement.protected?<AgreementProtectedBanner/>:""}
        <OwcExpandable variant="filled" roundedControl 
            expanded={this.state.isCustomerAgreementExpanded} 
            onExpandedChange={(ev) => this.setState({isCustomerAgreementExpanded: ev.detail})}>
          <span slot="title">Customer Agreement</span>
          <span slot="content">
            <CustomerAgreementDetails agreement={this.state.agreement} 
              refLists={this.state.refListsComplete} 
              onAccountSearchClick={(arg) => this.props.onAccountSearchClick(arg)}
              />
          </span>
        </OwcExpandable>
        <OwcExpandable variant="filled" roundedControl 
            expanded={this.state.isMappedAccountsExpanded} 
            onExpandedChange={(ev) => this.setState({isMappedAccountsExpanded: ev.detail})}>
          <span slot="title">Mapped Accounts</span>
          <span slot="content">
            <div>
              {this.renderMappedAccounts()}
              <br/>
              {this.renderMappingComments()}
            </div>
          </span>
        </OwcExpandable>
        <OwcExpandable variant="filled" roundedControl 
            expanded={this.state.isSearchOptionsExpanded} 
            onExpandedChange={(ev) => this.setState({isSearchOptionsExpanded: ev.detail})} >
          <span key="dataUsageTitle" slot="title">Account Search Options</span>
          <span key="dataUsageContent" slot="content">
            <OwcTypography style={{ fontWeight: "bold" }}>Search for accounts matching ANY of:</OwcTypography>
            {this.renderSearchOptions()}
          </span>
        </OwcExpandable>
        <OwcExpandable key="mappingComments" variant="standard" round
            expanded={this.state.isSearchResultsExpanded} 
            onExpandedChange={(ev) => this.setState({isSearchResultsExpanded: ev.detail})} >
          <span key="mappingCommentsTitle" slot="title">Account Search Results</span>
          <span key="mappingCommentsContent" slot="content">
            {this.renderSearchResults()}
          </span>
        </OwcExpandable>
        <table width="100%">
          <tbody>
            <tr>
              <td align="left">
                <OwcButton elevated style={{ width: "fit-content" }}
                    onclick={() => this.handleCancelClick()}>
                  Clear Unsaved Changes
                </OwcButton>
              </td>
              <td align="right">
                <OwcButton style={{ width: "fit-content" }}
                  onclick={() => this.handleSubmitClick()}
                  disabled={((this.state.agreement.protected === true)
                              || ((this.state.submissionState !== "Saving ...") && (this.state.submissionState === null)))
                            ? true : false}
                >
                  {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                </OwcButton>
              </td>
            </tr>
          </tbody>
        </table>
        {this.state.agreement.protected === true?
          ""
        :
          <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
            {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
          </OwcTypography>
        }
      </div>
    );
    
  }
}


export default MapAccounts;
