import React from 'react';
import { OwcTable, OwcTableCell, OwcTableHeader, OwcTableHeaderCell, 
         OwcTableBody, OwcTableRow, OwcTypography,
         OwcIcon } from '@one/react';
import DelayedTooltip from '../general/DelayedTooltip';
import TextWithLookup from '../general/TextWithLookup';
import '../../styles.scss';

import {configData} from "../../config.js";
import {formatDate, fetchSigned, joinWithNoEmptyValues, isRecentMessage} from "../../shared/Utilities.js"

/**
 * Renders the device history table
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class DeviceAccounts extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      agreementMappingLoaded: false,
      agreementMapping: [],
      agreementMappingDetails: [],
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deviceId !== this.props.deviceId || prevProps.forceUpdate !== this.props.forceUpdate) {
      if (isRecentMessage(this.props.forceUpdate)) {
        if (this.props.deviceId !== null) {
          this.loadDeviceAgreementMapping(this.props.deviceId);
        } else {
          this.setState({
            agreementMappingLoaded: false,
            agreementMapping: [],
          });
        }
      }
    }
  }

  
  /**
   * Loads the agreement mapping data to display for a device
   * @param {*} deviceId the device_id to load
   */
  loadDeviceAgreementMapping(deviceId) {
    console.log("loading device agreement mapping for ", deviceId);
    this.setState({ agreementMappingLoaded: false});

    fetchSigned(configData.DEVICE_DETAILS_API_URL + "agreement-mapping/" + deviceId + "/")
    .then(res => res.json())
    .then(
      (result) => {
          this.setState({ agreementMapping: result,  
                          agreementMappingLoaded: true});
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )

    fetchSigned(configData.DEVICE_MAPPING_API_URL + "device/" + deviceId + "/")
    .then(res => res.json())
    .then(
      (result) => {
          this.setState({ agreementMappingDetails: result});
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  /**
   * Handles click eventrs on the customer agreement
   * Pass through method to this.props.onAgreementClick(customerAgreementId)
   */
  handleAgreementClick(customerAgreementId) {
    console.log(`Agreement ${customerAgreementId} clicked`);
    this.props.onAgreementClick(customerAgreementId);
  }

  /**
   * Renders the controls of the Customer AGreement Row within the table
   * @returns The JSX of the controls
   */
  renderAgreementRow(item) {
    let rowColour = configData.COLOUR_WHITE;
    const mappingDetails = this.state.agreementMappingDetails.find(entry => entry.customerAgreementId === item.customerAgreementId);

    return (
      <OwcTableRow
          key={"row" + item.customerAgreementId}
          id={"row" + item.customerAgreementId} 
          expandable 
          style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour}: {}}
      >
        <OwcTableCell 
            style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"titleCell" + item.customerAgreementId}
        >
          {this.props.disableNavigation===true?
            item.title
          :
            <TextWithLookup
              id={item.customerAgreementId}
              text={item.title}
              iconTooltip="Go to the map devices screen for this agreement"
              onClick={()=>this.handleAgreementClick(item.customerAgreementId)}
            />
          }
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"customerNameCell" + item.customerAgreementId}>
          {item.customerName}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"rocheNameCell" + item.customerAgreementId}>
          {item.rocheName}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"rocheStatusCell" + item.customerAgreementId}>            
            {item.publishedVersionNo !== null?
              <>
                <OwcIcon key={"iconPublishedStatus" + item.customerAgreementId} 
                  id={"iconPublishedStatus" + item.customerAgreementId} 
                  name="certificate" variant="success"
                  style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} />
                <DelayedTooltip key={"iconPublishedStatusTooltip" + item.customerAgreementId} 
                    anchor={"iconPublishedStatus" + item.customerAgreementId}
                    placement="right">
                  Agreement entilements version {item.publishedVersionNo} have been published
                </DelayedTooltip>          
              </>
            :               
              <div key={"iconPublishedStatusSpacer" + item.customerAgreementId} style={{width:"1.5em"}} />
            }
            {item.deviceVerifiedDate !== null?
              <>
                <OwcIcon key={"iconVerifiedStatus" + item.customerAgreementId} 
                  id={"iconVerifiedStatus" + item.customerAgreementId} 
                  name="cobas_liat" variant="success"
                  style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} />
                <DelayedTooltip key={"iconVerifiedStatusTooltip" + item.customerAgreementId} 
                    anchor={"iconVerifiedStatus" + item.customerAgreementId}
                    placement="right">
                  Device mappings have been verified on {formatDate(item.deviceVerifiedDate)}
                </DelayedTooltip>
              </>
            :               
              <div key={"iconVerifiedStatusSpacer" + item.customerAgreementId} style={{width:"1.5em"}} />
            }
            
            {item.protected === true
            ?<>
                <OwcIcon key={"iconProtectedStatus" + item.customerAgreementId} 
                  id={"iconProtectedStatus" + item.customerAgreementId} 
                  name="lock_on" variant="success"
                  style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} />
                <DelayedTooltip key={"iconProtectedStatusTooltip" + item.customerAgreementId} anchor={"iconProtectedStatus" + item.customerAgreementId}>
                  This agreement is protected from editing</DelayedTooltip>
              </>
            :<div key={"iconProtectedStatusSpacer" + item.customerAgreementId} style={{width:"1.5em"}} />
            }
        </OwcTableCell>
        <div key={"expandedRow" + item.customerAgreementId} slot="expanded">
          <b>Customer:</b> {joinWithNoEmptyValues([item.customerName, item.customerAddress, item.customerCountryName])}<br />
          {item.customerAccountNo===null?"":<><b>Customer account number</b>: {item.customerAccountNo}<br /></>}
          <b>Roche:</b> {joinWithNoEmptyValues([item.rocheName, item.rocheAddress, item.rocheCountryName])}<br/>
          <b>Valid from:</b> {item.validFrom === null ? "undefined date" : formatDate(item.validFrom)}, 
          <b>To:</b> {item.terminationDate === null ? "undefined date" : formatDate(item.terminationDate)} <br />
          {
            (mappingDetails!==undefined && (mappingDetails.validityStartDate!==null || mappingDetails.validityEndDate !== null))?
              (
                <><b>Coverage from:</b> {mappingDetails.validityStartDate===null?"start":formatDate(mappingDetails.validityStartDate)} 
                <b> To:</b> {mappingDetails.validityEndDate === null ? "end" : formatDate(mappingDetails.validityEndDate)} 
                </>
              )
            :
            ""
          }
        </div>
      </OwcTableRow>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    const agreements = this.state.agreementMapping;
    const style = { maxWidth:"97%", marginLeft:"1em" };

    return (
      <OwcTable key={"DeviceAgreementsTable" + this.props.deviceId} size='dense' height="auto" elevated
          cardProps={{ style: style }}>
        <OwcTableHeader sticky>
          <OwcTableHeaderCell key={"ChangeDateHeader"} width="35%" resizable>Title</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"LabIDHeader"} width="25%" resizable>Customer</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"MacAddressHeader"} width="25%" resizable>Roche</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"DMSerialNoHeader"} width="10%" resizable>Status</OwcTableHeaderCell>
        </OwcTableHeader>
        <OwcTableBody loading={!this.state.agreementMappingLoaded}>
          {agreements.map(row => this.renderAgreementRow(row))}
        </OwcTableBody>
        <div slot="header" style={{ alignItems: 'center' }}>
          {this.props.displayExcludedWarning===true && agreements.length>0?
            (              
            <OwcTypography style={{ flexGrow: 1, backgroundColor: configData.COLOUR_EXCLUDED}}>
              Mapped to {agreements.length} agreement{agreements.length===1?"":"s"}, these mappings will be unconfirmed by excluding the device once saved, please reconfirm or delete.
            </OwcTypography>
            )
          :
            (
              <OwcTypography style={{ flexGrow: 1}}>
                Mapped to {agreements.length} agreement{agreements.length===1?"":"s"}
              </OwcTypography>
            )
          }
        </div>
      </OwcTable>
    );
  }
}

DeviceAccounts.defaultProps = {
  deviceId: null,
  disableNavigation: false,
  displayExcludedWarning: false,
  forceUpdate: null,
  onAgreementClick:() => {},
}

export default DeviceAccounts
