import React from 'react';
import '../styles.scss';
import { configData } from "../config.js";
import { fetchSigned } from '../shared/Utilities';

import {
  OwcLogo, OwcIconButton, OwcTypography, OwcButton, OwcSwitch, 
  OwcInput, OwcExpandable, OwcModalDialog
} from '@one/react';
import DelayedTooltip from "../components/general/DelayedTooltip.js";

import { partialParse } from "../shared/partial-json-parser";

/** 
 * @copyright Roche 2022
 * @author Nick Draper
 */

/**
 * Data Product Query component
 */
class DataProduct extends React.Component {

  REQUEST_IN_PROGRESS = "Waiting for a response ...";
  NO_DATA_PRODUCT_SELECTED = "Select a data product";
  MAX_CONTENT_LENGTH = 40000; // If the data product is larger than this then only display the start of it

  constructor(props) {
    super(props);
    // get a reference that will be used to scroll to the response section
    this.responseRef = React.createRef();
    const selectedDataProduct = this.NO_DATA_PRODUCT_SELECTED;
    this.state = {
      loadingDataProducts: true,
      selectedDataProduct: selectedDataProduct,
      groups: props.groups,
      dataProducts: {},
      url: null,
      params: null,
      responseText: null,
      responseDict: {},
      responseType: null,
      indentResponse: true,
      howToExpanded: false,
      showAbout: false,
      intendedUseExpanded: false,
      dataDictionaryExpanded: false,
      descriptionExpanded: false,
      waiting: true,
      label: {},
      partialResponse: false,
      requestStatus: "",
      cancelRequested: false,
      resultUrl: "",
    }
  }

  scrollToResponse = () => this.responseRef.current.scrollIntoView({behavior:"smooth"});

  /**
   * Simple delay function
   * @param {*} milliseconds The number of seconds to wait for
   * @returns A promise that will resolve after the delay
   */
  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  getDataProducts(groups) {
    try {
      fetchSigned(configData.DATA_PRODUCT_URL + "/data/list/")
        .then(async response => {
          let responseDict = {};
          if (response !== undefined) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              responseDict = await response.json();
            } else {
              console.log(response)
            }
          }
          console.log(`Found data products: ${JSON.stringify(responseDict)}`)

          // for each data product check this user can access it, by getting the label
          const entries_to_remove = [];
          for (const [name, endpoint_suffix] of Object.entries(responseDict)) {
            const url = configData.DATA_PRODUCT_URL + endpoint_suffix + "test-access/";
            try {
              const responseDP = await fetchSigned(url, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({groups: groups})
              });
              if ((responseDP === undefined) ||
                  (!(responseDP.ok && (responseDP.status === 200)))) {
                throw new Error("Data product retrieval failed.");
              } else {
                const body = await responseDP.json();
                if (body.authorized !== true) {
                  console.log(`Data product retrieval was disallowed - body.authorized = ${body.authorized}`);
                  throw new Error("Data product retrieval was disallowed.");
                }
              }
            } catch (e) {
              console.log(`Error was encountered when fetching url ${url} - ${e}`);
              entries_to_remove.push(name);
            }
          }

          for (const name of entries_to_remove) {
              delete responseDict[name];
          }

          this.setState({
            dataProducts: responseDict,
            loadingDataProducts: false
          });
        })
        .catch(error => {
          this.setState({
            responseText: error.toString(),
            loadingDataProducts: false
          });
          console.error('There was an error!', error);
        });
    } catch (e) {
      console.log(`Unexpected error occurred: ${e}`);
      this.setState({
        waiting: false,
        groups: null
      });
    };
  }

  async componentDidMount() {
    this.getDataProducts(this.props.groups);      
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groups !== this.props.groups) {
      this.setState({ selectedDataProduct: this.NO_DATA_PRODUCT_SELECTED });
      this.getDataProducts(this.props.groups);
    }
  }

  /**
   * Handles changes in the dat product
   * @param {*} value The name of the data product
   */
  handleSelectChange(value) {
    this.setState({
        selectedDataProduct: value,
        url: this.getBaseURL(value),
        responseText: null,
        responseDict: {},
        responseType: "unknown"
      },
      () => this.loadDataProductLabel()
    );
  }

  toggleAbout() {
    const isVisible = this.state.showAbout;
    this.setState({ showAbout: !isVisible })
  }

  getBaseURL(dataProductName) {
    let url = null;
    if (this.state.dataProducts[dataProductName] !== undefined) {
      url = configData.DATA_PRODUCT_URL + this.state.dataProducts[dataProductName]
    }
    return url;
  }

  /**
   * Updates the state of the url
   * @param {*} value the url value
   */
  handleInputChange(value) {
    if ((value !== undefined) && (value !== null)) {
      value = value.trim()
      if (value !== "") {
        // the first charcter should be ? add it if it is not present
        if (value[0] !== "?") {
          value = "?" + value;
        }
      }
    }
    this.setState({ params: value });
  }

  /**
   * An internal method to allow instanteous downloads
   * @param {*} blob the data to download
   * @param {*} filename the filename to use
   */
  saveFile = async (blob, filename) => {
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  /**
   * Download a file of the response
   */
  downloadFile() {
    let extension = ".txt";
    let contentType = "text/plain";
    if (this.state.responseType === "xml") {
      extension = ".xml";
      contentType = "text/xml";
    } else if (this.state.responseType === "json") {
      extension = ".json";
      contentType = "application/json";
    }
    const fileName = this.state.selectedDataProduct.replaceAll(" ","-") + extension;
    if (this.state.partialResponse === false) {
      const blob = new Blob([this.getResponseText()], { type: contentType });
      this.saveFile(blob, fileName);
    } else {
      window.location.replace(this.state.resultUrl);
    }
  }


  /**
   * Indents XML
   * @param {*} xml the xml to indent
   * @param {*} tab the optional indentation value, default is tab (\t) 
   * @returns 
   */
  formatXml(xml, tab) {
    var formatted = '', indent = '';
    tab = tab || '\t';
    xml.split(/>\s*</).forEach(function (node) {
      if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
      formatted += indent + '<' + node + '>\r\n';
      if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab;              // increase indent
    });
    return formatted.substring(1, formatted.length - 3);
  }

  /**
   * submits the url when the enter key is pressed
   * @param {*} ev The keypress event
   */
  handlekeyDown(ev) {
    if (ev.keyCode === 13) {
      this.handleSubmitClick();
    }
  }


  /**
   * Signs and sumbits the request and populates the response
   */
  handleSubmitClick() {
    this.setState({
      responseText: this.REQUEST_IN_PROGRESS,
      responseDict: {},
      responseType: "unknown",
      partialResponse: false,
      requestStatus: "Requesting Data Product",
      cancelRequested: false,
      resultUrl: "",
    });

    let urlToFetch = this.state.url
    if ((this.state.params !== null) && (this.state.params !== undefined)) {
      urlToFetch += this.state.params;
    }

    fetchSigned(urlToFetch)
      .then(async response => {
        let responseDict = {};
        if (response === undefined || !response.ok) {
          this.setState({
            responseText: "There was an error requesting the data product"
          }, () => {
              if(this.responseRef.current){
                this.scrollToResponse();
              }
            }
          );
        }
        else {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            responseDict = await response.json();
            if (this.state.cancelRequested === false) {
              this.checkForCompletion(responseDict);
            }
          } else {
            this.setState({
              responseText: `The request to generate the data product failed with the message '${await response.text()}'`,
            }, () => {
                if(this.responseRef.current){
                  this.scrollToResponse();
                }
              }
            );
          }
        }   
      })
      .catch(error => {
        this.setState({ responseText: error.toString() });
        console.error('There was an error!', error);
      });
  }

  /**
   * Polls for a when the data product is completed
   * @param {*} dataProductARN the ARN of the job that is creating the data product
   */
  async checkForCompletion(dataProductARN) {
    const statusUrl = this.getBaseURL(this.state.selectedDataProduct) + "status/" + dataProductARN;
    const delay = 1000;
    const maxTries = 15 * 60;
    let jobCompleted = false;
    let loopIndex = 0;
    while (jobCompleted === false && loopIndex < maxTries && this.state.cancelRequested === false) {
      const statusResponse = await fetchSigned(statusUrl);
      if (statusResponse !== undefined) {
        const statusResponsejson = await statusResponse.json();
        console.log(`Job status ${statusResponsejson.status}, check ${loopIndex} of ${maxTries}`);

        if (statusResponsejson.status !== "RUNNING") {
          jobCompleted = true;
          if (statusResponsejson.status === "SUCCEEDED") {
            this.setState({"resultUrl": statusResponsejson.url}, 
                          ()=>this.displayResults(statusResponsejson.url));
          } else {
            this.setState({
              responseText: `The request to generate the data product failed with the status '${statusResponsejson.status}'`,
              requestStatus: `Data Product generation failed with the status '${statusResponsejson.status}'`,
            }, () => {
                if(this.responseRef.current){
                  this.scrollToResponse();
                }
              }
            );
          }
        } else {
          if (this.state.cancelRequested === false) {
            this.setState({requestStatus: "Generating Data Product"});
            await this.sleep(delay);
          }
        }
        loopIndex++;
      } else {
        loopIndex++;
        console.log(`No response from ${statusUrl} attempt ${loopIndex}`);
        await this.sleep(delay);
      }
    }
  }

  /**
   * Loads the data product and displays it
   * @param {*} resultsURL 
   */
  displayResults(resultsURL){
    this.setState({requestStatus: "Reading Data Product"});
    fetch(resultsURL,{
      method: "GET",
      headers: {
        'Accept': '*/*'
      } ,
      mode: "cors",
      cache: "no-cache",
      referrer: "client",
    })
    .then(async response => {
      const contentLength = response.headers.get('Content-Length');
      console.log(`Content_length = ${contentLength}`)
      
      if (contentLength > this.MAX_CONTENT_LENGTH) {
        this.displayPartialResults(response);
      } else {
        let responseDict = {};
        let responseText = "";
        let responseType = "unknown";

        if (response === undefined || !response.ok) {
          responseText = `There was an error (${response.status}) retrieving from the url ` + resultsURL + response.statusText;
        }
        else {
          responseText = await response.text();
          try {
            responseDict = JSON.parse(responseText); 
            responseText = "";
            responseType = "json";
          } catch (e) {
              // this is not a json file
              if (responseText.startsWith("<?xml")) {
                responseType = "xml";
              }
          };

          this.setState({
            responseText: responseText,
            responseDict: responseDict,
            responseType: responseType,
            requestStatus: "",
          }, () => {
              if(this.responseRef.current){
                this.scrollToResponse();
              }
            }
          );   
        }   
      }
    })
    .catch(error => {
      this.setState({ responseText: error.toString() });
      console.error('There was an error!', error);
    });
  }

  /**
   * Loads the a partial amount of the data
   * @param {*} response the response object
   */
  async displayPartialResults(response) {
    const reader = response.body.getReader();
    const contentLength = + response.headers.get('Content-Length');
    let receivedLength = 0; // received that many bytes at the moment
    let chunks = []; // array of received binary chunks (comprises the body)
    while(this.state.cancelRequested === false) {
      const {done, value} = await reader.read();
      if (done || receivedLength > this.MAX_CONTENT_LENGTH) {
        break;
      }

      chunks.push(value);
      receivedLength += value.length;
      console.log(`Received ${receivedLength} of ${contentLength}`)
    }

    let chunksAll = new Uint8Array(receivedLength); // (4.1)
    let position = 0;
    for(let chunk of chunks) {
      chunksAll.set(chunk, position); // (4.2)
      position += chunk.length;
    }

    let result = new TextDecoder("utf-8").decode(chunksAll);

    // handle partial reading of the file
    let responseDict = {};
    let responseText = `Data Product is too large ${contentLength} bytes, roughly ${Math.round(contentLength/70)} lines`;
    let responseType = "unknown";
    responseText = result;
    if (responseText.startsWith("<?xml")) {
      responseType = "xml";
    }
    if(responseText.startsWith("{")) {
      responseType = "json";
      try {
        responseDict = JSON.parse(responseText);
      } catch (e) {
        // this is not a complete json file
        try {
          responseDict = partialParse(responseText)
        } catch (e) {
          // this is json file at all
          responseType = "unknown";
        }
      };
    }

    this.setState({
      responseText: responseText,
      responseDict: responseDict,
      responseType: responseType,
      partialResponse: true,
      requestStatus: "",
    }, () => {
        if(this.responseRef.current){
          this.scrollToResponse();
        }
      }
    );
  }

  /**
   * Extracts the response text formated as requested
   * @returns 
   */
  getResponseText() {
    let data = this.state.responseText;
    if (this.state.indentResponse) {
      if (this.state.responseType === "json") {
        data = JSON.stringify(this.state.responseDict, null, 2);
      }
      else if (this.state.responseType === "xml") {
        data = this.formatXml(data);
      }
    }
    else {
      if (this.state.responseType === "json") {
        data = JSON.stringify(this.state.responseDict);
      }
    }
    return (data);
  }

  loadDataProductLabel() {
    const baseUrl = this.getBaseURL(this.state.selectedDataProduct);
    this.setState({ label: {} });
    if (baseUrl !== null) {
      const labelUrl = this.getBaseURL(this.state.selectedDataProduct) + "label/";
      fetchSigned(labelUrl)
        .then(async response => {
          let responseDict = {};

          if (response !== undefined) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              responseDict = await response.json();
            } else {
              console.log(response)
            }
          }
          this.setState({ label: responseDict });
        })
        .catch(error => {
          this.setState({ responseText: error.toString() });
          console.error('There was an error!', error);
        });
    }
  }

  /**
   * Renders the response
   * @returns formatted JSX
   */
  renderResponse() {
    const responseText = this.getResponseText();
    if (responseText) {
      return (
        <>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "2em" }} ref={this.responseRef}>
            <OwcTypography style={{ marginTop: "0.1em", flexGrow: "4" }} variant="title5">
              {this.state.partialResponse===true?"Truncated Response (Click Download File for the entire file)":"Response"}
            </OwcTypography>
            <OwcSwitch style={{ marginBottom: "1em" }}
                checked={this.state.indentResponse}
                onValueChange={() => this.setState({ indentResponse: !this.state.indentResponse })}>
              Indented
            </OwcSwitch>
            <OwcButton style={{ marginBottom: "1em" }}
                onClick={() => this.downloadFile()}
                disabled={this.state.resultUrl === ""}>
              Download File
            </OwcButton>
          </div>
          {this.state.responseText === this.REQUEST_IN_PROGRESS ?
            (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <owc-progress-spinner style={{ marginTop: '30px' }} />
              </div>
            ) :
            (
              <div style={{ backgroundColor: "#eee", overflowY: "scroll", overflowX: "scroll", padding: "1em", whiteSpace: "pre-wrap" }}>
                {responseText}
              </div>
            )}
        </>
      );
    }
  }

  /**
  * Renders the request section for the Data Product
  * @returns formatted JSX
  */
  renderDataProduct() {
    if (this.state.selectedDataProduct) {
      let howToQuery = "";
      let description = "";
      let intendedUse = "";
      let dataDictionary = "";
      if (Object.keys(this.state.label).length > 0) {
        const dataProductAttributes = this.state.label[configData.LABEL_KEYS.DATA_PRODUCT_ATTRIBUTES_KEY][configData.LABEL_KEYS.CHILDREN_KEY];
        if (dataProductAttributes !== undefined) {
          if (dataProductAttributes[configData.LABEL_KEYS.DESCRIPTION_KEY] !== undefined) {
            description = dataProductAttributes[configData.LABEL_KEYS.DESCRIPTION_KEY][configData.LABEL_KEYS.VALUE_KEY];
          }
          if (dataProductAttributes[configData.LABEL_KEYS.HOW_TO_QUERY_DATA_KEY] !== undefined) {
            howToQuery = dataProductAttributes[configData.LABEL_KEYS.HOW_TO_QUERY_DATA_KEY][configData.LABEL_KEYS.VALUE_KEY];
          }
          
          if (dataProductAttributes[configData.LABEL_KEYS.INTENDED_USE_KEY] !== undefined) {
            intendedUse = dataProductAttributes[configData.LABEL_KEYS.INTENDED_USE_KEY][configData.LABEL_KEYS.VALUE_KEY];
          }
          
          if (dataProductAttributes[configData.LABEL_KEYS.DATA_DICTIONARY_KEY] !== undefined) {
            dataDictionary = dataProductAttributes[configData.LABEL_KEYS.DATA_DICTIONARY_KEY][configData.LABEL_KEYS.VALUE_KEY];
          }
        }
      }
      return (
        <div style={{ marginTop: "2em", marginLeft: "2em", marginRight: "2em"}}>
          <OwcTypography variant="title5">Request</OwcTypography>
          <div style={{ display: 'flex', flexDirection: "row" }}>
            <OwcTypography style={{ marginTop: "0.75em", marginRight: "1em" }} variant="body">URL</OwcTypography>
            <OwcTypography style={{ marginTop: "0.75em", wordBreak: "break-all"}} variant="body">
              {this.state.url}
            </OwcTypography>
            <div style={{ display: 'flex', flexDirection: "column", flexGrow: "4", marginRight: "1em",}}>
              <div style={{ display: 'flex', flexDirection: "row"}}>
                <OwcInput style={{ flexGrow: "4", alignSelf:"center" }} size="small"
                  value={this.state.params}
                  onValueChange={(ev) => this.handleInputChange(ev.detail)}
                  onKeyDown={(ev) => this.handlekeyDown(ev)}>
                </OwcInput>
              </div>
              <OwcTypography style={{ marginTop: "0.3em", alignSelf: "flex-end" }}
                  variant="title6">
                {this.state.requestStatus}
              </OwcTypography>
            </div>
            <div>
              <OwcButton id="submit" onClick={() => this.handleSubmitClick()}>Sign & Submit</OwcButton>
              <DelayedTooltip anchor="submit" >
                Crytographically signs your request and submits it to the server
              </DelayedTooltip>
              {this.state.requestStatus === ""?
              (
                <>
                <OwcButton id="reset" style={{ marginTop: "0.4em", width:"100%" }}
                    variant="primary"
                    onClick={() => {
                      this.setState({
                        url: this.getBaseURL(this.state.selectedDataProduct),
                        params: null,
                        responseText: "",
                        responseDict: {},
                        partialResponse: false,
                        responseType: "unknown",
                        resultUrl: "",
                      });
                    }}>
                  Reset
                </OwcButton>
                <DelayedTooltip anchor="reset" >
                  Resets the url to the default and removes the current response
                </DelayedTooltip>
                </>
              )
              :
              (
                <>
                <OwcButton id="cancel" style={{ marginTop: "0.4em", width:"100%" }}
                    variant="warning"
                    onClick={() => {
                      this.setState({
                        cancelRequested: true,
                        requestStatus: "",
                        responseType: "",
                        responseText: "",
                        resultUrl: "",
                      });
                    }}>
                  Cancel Request
                </OwcButton>
                <DelayedTooltip anchor="cancel" >
                  Cancel the current request
                </DelayedTooltip>
                </>
              )
                }
            </div>
          </div>
          {description !== "" 
            ? (
              <OwcExpandable style={{ marginTop: "0.5em" }} roundedControl variant="transparent"
                  expanded={this.state.descriptionExpanded}
                  onExpandedChange={(ev) => { this.setState({ descriptionExpanded: ev.detail }) }}>
                <span slot="title">
                  <OwcTypography variant="title6">
                    {configData.LABEL_KEYS.DESCRIPTION_KEY}
                  </OwcTypography>
                </span>
                <span slot="content">
                  <OwcTypography style={{ whiteSpace: "pre-wrap", paddingLeft: "50px" }}>
                    {description}
                  </OwcTypography></span>
              </OwcExpandable>
            )
            : ""
          }
          {howToQuery !== ""
            ? (
              <OwcExpandable style={{ marginTop: "0.5em" }} roundedControl variant="transparent"
                  expanded={this.state.howToExpanded}
                  onExpandedChange={(ev) => { this.setState({ howToExpanded: ev.detail }) }}>
                <span slot="title">
                  <OwcTypography variant="title6">
                    {configData.LABEL_KEYS.HOW_TO_QUERY_DATA_KEY}
                  </OwcTypography>
                </span>
                <span slot="content">
                  <OwcTypography style={{ whiteSpace: "pre-wrap", paddingLeft: "50px" }}>
                    {howToQuery}
                  </OwcTypography>
                </span>
              </OwcExpandable>
            )
            : ""
          }
          {intendedUse !== "" 
            ? (
              <OwcExpandable style={{ marginTop: "0.5em" }} roundedControl variant="transparent"
                  expanded={this.state.intendedUseExpanded}
                  onExpandedChange={(ev) => { this.setState({ intendedUseExpanded: ev.detail }) }}>
                <span slot="title">
                  <OwcTypography variant="title6">
                    {configData.LABEL_KEYS.INTENDED_USE_KEY}
                  </OwcTypography>
                </span>
                <span slot="content">
                  <OwcTypography style={{ whiteSpace: "pre-wrap", paddingLeft: "50px"  }}>
                    {intendedUse}
                  </OwcTypography>
                </span>
              </OwcExpandable>
            )
            : ""
          }
          {dataDictionary !== ""
            ? (
              <OwcExpandable style={{ marginTop: "0.5em" }} roundedControl variant="transparent"
                  expanded={this.state.dataDictionaryExpanded}
                  onExpandedChange={(ev) => { this.setState({ dataDictionaryExpanded: ev.detail }) }}>
                <span slot="title">
                  <OwcTypography variant="title6">
                    {configData.LABEL_KEYS.DATA_DICTIONARY_KEY}
                  </OwcTypography>
                </span>
                <span slot="content">
                  <a href={dataDictionary} 
                      target="_blank" rel="noreferrer noopener" 
                      style={{ paddingLeft: "50px"  }}>
                    {dataDictionary}
                  </a>
                  <OwcTypography style={{ whiteSpace: "pre-wrap", paddingLeft: "50px"  }}>
                    (opens in a new window)
                  </OwcTypography>
                </span>
              </OwcExpandable>
            )
            : ""
          }

          {this.renderResponse()}
        </div>
      );
    }
  }

  renderDataProductSelector() {
    if (this.state.loadingDataProducts) {
      return (
        <div style={{
          display: "flex", flexDirection: "column",
          alignItems: "center"
        }}>
          <owc-progress-spinner style={{ marginTop: '30vh', marginBottom: "40vh" }} />
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1em" }}>
          <OwcIconButton id="menu_anchor" icon="menu" onClick={(ev) => this.toggleAbout(ev.detail)}  />
          <OwcTypography style={{ marginRight: "1em" }} variant="title6">Please select a Data Product:</OwcTypography>
          <select
            id={"dataProductSelect"}
            key={"dataProductSelect"}
            label="Data Product" value={this.state.selectedDataProduct}
            onChange={(ev) => this.handleSelectChange(ev.target.value)}>
            <option>{this.NO_DATA_PRODUCT_SELECTED}</option>
            {Object.entries(this.state.dataProducts).map(([key, value]) => (
              <option
                key={"dataProductOption" + key} style={{ color: "black" }}>
                {key}
              </option>
            ))}
          </select>
        </div>
      );
    }
  }

  renderAbout() {
    return (
      <OwcModalDialog
          visible={this.state.showAbout} 
          onVisibleChange={() => this.toggleAbout()}>
        <div className="about-screen-content-wrapper" 
            style={{
              backgroundColor: 'var(--one-color-white)',
              height: 'calc(100% - 80px)',
              display: 'flex',
              flexDirection: 'column',
              padding: '80px 40px',
              fontFamily: 'var(--one-text-font-family-default)'
            }}>
          <div className="title-container" 
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'var(--one-color-background-brand-base)'
              }}>
            <span className="title" 
                style={{
                  fontWeight: 'bold',
                  fontSize: '33px',
                  lineHeight: '49px'
                }}>
              Data Products
            </span>
            <OwcLogo name="roche_logo" style={{ width: '77px', fontSize: '48px', color: 'var(--one-color-background-brand-base)'}} />
          </div>
          <span className="subtitle" 
              style={{
                fontStyle: 'italic',
                fontSize: '32px',
                lineHeight: '1',
                color: 'var(--one-color-background-brand-base)',
                margin: '8px 0'
              }}>
            About
          </span>
          <div className="content" style={{ flexGrow: 1, overflow: 'auto', margin: '48px 0 38px 0' }}>
            The application provides secure access to approved data products to the consumer.
            <br/><br/>
            On the landing page (after signing in), the consumer will be able to select the desired data product
            that they wish to request. The screen will have a pre-populated URL and metadata of the product. 
            Users can add additional parameters next to the URL to refine the data product output. 
            Kindly refer to the "How to query" section to learn more about URL queries.
            <br/><br/>
            Use the “Sign & Submit” button to execute the query and to securely view the data product. 
            The data product response can be downloaded as a file if required.
          </div>
        </div>
      </OwcModalDialog>
    );
  }

  render() {
    return (
      <div style={{ display: 'flex', width: "100%", flexDirection: "column" }}>
        {this.renderDataProductSelector()}
        {this.state.selectedDataProduct !== this.NO_DATA_PRODUCT_SELECTED
          ? this.renderDataProduct()
          : ""
        }
        {this.state.showAbout ? this.renderAbout() : ""}
      </div>
    );
  }

}

export default DataProduct;
