import React from 'react';
import { OwcTable, OwcTableCell, OwcTableHeader, OwcTableHeaderCell, OwcTableBody, OwcTableRow, OwcTypography } from '@one/react';
import DeviceMoveIcon from './DeviceMoveIcon.js';
import DelayedTooltip from '../general/DelayedTooltip.js';
import '../../styles.scss';

import {configData} from "../../config.js";
import {formatDate, convertToDate, fetchSigned, isRecentMessage} from "../../shared/Utilities.js"
import { renderToString } from 'react-dom/server'

/**
 * Renders the device history table
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class DeviceHistory extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      historyLoaded: false,
      history: [],
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deviceId !== this.props.deviceId || prevProps.forceUpdate !== this.props.forceUpdate) {
      if (isRecentMessage(this.props.forceUpdate)) {
        if (this.props.deviceId !== null) {
          this.loadDeviceHistory(this.props.deviceId);
        } else {
          this.setState({
            historyLoaded: false,
            history: [],
          });
        }
      }

    }
  }

  
  /**
   * Loads the history data to display for a device
   * @param {*} deviceId the device_id to load
   */
  loadDeviceHistory(deviceId) {
    console.log("loading device Life Cycle for ", deviceId);
    this.setState({ historyLoaded: false});

    fetchSigned(configData.DEVICE_DETAILS_API_URL + "lifecycle/" + deviceId + "/")
    .then(res => res.json())
    .then(
      (result) => {
        result = this.accumulateSerialNos(result);
        result.forEach(row=> {row.formattedAccounts = this.getAccountsText(row)});
        this.setState({ history: result,  
                        historyLoaded: true});
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }


  /**
   * acculumates the list of serial numbers
   */
  accumulateSerialNos(history){
    let accumulatedSerialNumbers = {};

    //iterate backawards over the history rows
    for (let i = history.length - 1; i >= 0; i--) {
      let historyRow = history[i];
      let source = configData.SHORT_SOURCE_NAME_DICT[historyRow.creationTrigger];
      if (!(source in accumulatedSerialNumbers)) {
        accumulatedSerialNumbers[source] = {};
      }
      accumulatedSerialNumbers[source][historyRow.sourceSerialNo] = i;

      //take a copy and add it to the histroyRow
      historyRow.accumulatedSerialNumbers = {...accumulatedSerialNumbers};
      historyRow.accumulatedSerialNumbersText = this.getSerialNumbersText(historyRow, accumulatedSerialNumbers);
    }
    return history;
  }

  /**
   * Generates and returns the text to be used to describe the accounts related to this device
   */
  getSerialNumbersText(historyRow, accumulatedSerialNumbers){
    const sourceList = [];

    for (const [source, serialDict] of Object.entries(accumulatedSerialNumbers)) {
      // Create items array
      var items = Object.keys(serialDict).map((key) => {
        return [key, serialDict[key]];
      });

      // Sort the array based on the second element andreverse
      items.sort(function(first, second) {
        return second[1] - first[1];
      }).reverse();

      //extract just the sorted serial numbers
      let serialNumbers = items.map((item) => {
        if (item[0] === historyRow.installedBaseSerialNo) {
          return `[${item[0]}]`;
        } else {
          return String(item[0]);
        }
      });
      sourceList.push(`${source}: ${serialNumbers.join(", ")}`);
    }
    return(
      <span>
        {sourceList.map((message, index) =>(<pre  key={historyRow.deviceId + historyRow.changeDate + index} style={{marginTop:"0",marginBottom:"0"}}>{message}</pre>))}
      </span>
    );
  }

  /**
   * Generates and returns the text to be used to describe the accounts related to this device
   */
  getAccountsText(item) {
    const messageList = [];
    if (item.shipToAccountNo !== null) {
      messageList.push("shipTo: " + item.shipToAccountNo);
    }
    if (item.soldToAccountNo !== null) {
      messageList.push("soldTo: " + item.soldToAccountNo);
    }
    if (item.billToAccountNo !== null) {
      messageList.push("billTo: " + item.billToAccountNo);
    }
    if (item.instrumentLocationAccountNo !== null) {
      messageList.push("Location: " + item.instrumentLocationAccountNo);
    }
    if (messageList.length > 0){
      return(
        <span>
          {messageList.map((message, index) =>(<pre  key={item.deviceId + index} style={{marginTop:"0",marginBottom:"0"}}>{message}</pre>))}
        </span>);
    } else {
      return(
        <span style={{overflowWrap:"normal"}}>
          None
        </span>
      );
    }

  }

  /**
   * Detemines the background colour for a row
   */
  applyColour(row, previousRow, fieldKey, styleDict={}) {
    if (row.baselineConfirmationDate!==null)
    {
      styleDict.backgroundColor = configData.COLOUR_GREEN;
      
    } else {
      if ((previousRow !== null) &&
          (renderToString(row[fieldKey]) !== renderToString(previousRow[fieldKey]))) {
            styleDict.backgroundColor = configData.COLOUR_YELLOW;
      }
    }
    return styleDict;
  }

  /**
   * Determines if a row should have a device move signal displayed and returns the device move signal ison
   * @param row The device row for this timepoint
   * @param baselineRow The data row for the most recent baseline for this device
   * @returns JSX for a device move signal if one should be displayed
   */
  renderDeviceMoveSignal(row, baselineRow) {
    if (baselineRow !== null && baselineRow.baselineConfirmationDate !== null) {
      if (convertToDate(row.changeDate) > convertToDate(baselineRow.baselineConfirmationDate)) {
        if (baselineRow.instrumentLocationAccountNo !== row.instrumentLocationAccountNo && 
            baselineRow.labId !== row.labId) {
          return (
            <DeviceMoveIcon
              id={row.changeDate}
              iconTooltip={`Device move signal since the last location baseline of ${formatDate(baselineRow.baselineConfirmationDate)}`}
            />
          );
        }
      }
    }
  }

  /**
   * Render the section displaying the device identity
   */
  renderHistoryRow(row, index, baselineRow) {
    const keyEnding = row.changeDate;
    let prevRow = null;
    const prevIndex = index + 1;
    if (prevIndex > 0 && prevIndex < this.state.history.length) {
      prevRow = this.state.history[prevIndex];
    }

    return (
      <OwcTableRow key={"HistoryRow" + keyEnding}>
        <OwcTableCell key={"ChangeDateCell" + keyEnding} 
            id={"ChangeDateCell" + keyEnding} 
            valign="middle"
            style={this.applyColour(row, null, "changeDate", {wordBreak:"normal"})}>
          <span key={"ChangeDateCellText" + keyEnding} id={"ChangeDateCellText" + keyEnding}>{formatDate(row.changeDate)}</span>
          {this.renderDeviceMoveSignal(row, baselineRow)}
        </OwcTableCell>
        <DelayedTooltip
          key={"ChangeDateCellTooltip" + keyEnding}
          anchor={"ChangeDateCellText" + keyEnding}
        >
          {row.baselineConfirmationDate!==null?
            `Change triggered by ${configData.SHORT_SOURCE_NAME_DICT[row.creationTrigger]} (${row.sourceSerialNo}); Baseline confirmed on ${formatDate(row.baselineConfirmationDate)}`
          :
            `Change triggered by ${configData.SHORT_SOURCE_NAME_DICT[row.creationTrigger]} (${row.sourceSerialNo})`
          }
        </DelayedTooltip>
        <OwcTableCell key={"SourceSerialCell" + keyEnding}
          id={"SourceSerialCell" + keyEnding}
          valign="middle">{row.accumulatedSerialNumbersText}</OwcTableCell>
        <DelayedTooltip
          key={"SourceSerialCellTooltip" + keyEnding}
          anchor={"SourceSerialCell" + keyEnding}
        >
          Serial numbers by source, ordered by the most recent.  If enriched the serial number that matched the installed base is shown in brackets.
        </DelayedTooltip>
        <OwcTableCell key={"LabIdCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "labId", {})}>{row.labId}</OwcTableCell>
        <OwcTableCell key={"MacAddressCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "macAddress", {wordBreak:"normal"})}>{row.macAddress}</OwcTableCell>
        <OwcTableCell key={"DMSerialNoCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "dataManagerSerialNo", {wordBreak:"normal"})}>{row.dataManagerSerialNo}</OwcTableCell>
        <OwcTableCell key={"RSDserialNoCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "remoteServiceDeviceSerialNo", {wordBreak:"normal"})}>{row.remoteServiceDeviceSerialNo}</OwcTableCell>
        <OwcTableCell key={"MaterialNameCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "materialName", {wordBreak:"normal"})}>{row.materialName}</OwcTableCell>
        <OwcTableCell key={"AccountNoCell" + keyEnding} id={"AccountNoCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "formattedAccounts", {wordBreak:"normal"})}>{row.formattedAccounts}</OwcTableCell>
        <DelayedTooltip key={"accountNoCellTooltip" + keyEnding}
          anchor={"AccountNoCell" + keyEnding} 
          disableTriggers = {(row.installedBaseMatchCount === 1)}>
            {row.installedBaseMatchCount === 1?
              ""
            :
              (<>Device details not enriched as this mapped to {row.installedBaseMatchCount} installed base records</>)
            }
        </DelayedTooltip>
        <OwcTableCell key={"AccountNameCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "locationAccountName", {wordBreak:"normal"})}>{row.locationAccountName}</OwcTableCell>
        <OwcTableCell key={"InstrumentAddressCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "instrumentAddress", {wordBreak:"normal"})}>{row.instrumentAddress}</OwcTableCell>
        <OwcTableCell key={"InstallDateCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "installDate", {wordBreak:"normal"})}>{formatDate(row.installDate)}</OwcTableCell>
        <OwcTableCell key={"PlacementTypeCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "placementType", {wordBreak:"normal"})}>{row.placementType}</OwcTableCell>
        <OwcTableCell key={"UserStatusCell" + keyEnding} valign="middle"
        style={this.applyColour(row, prevRow, "userStatus", {wordBreak:"normal"})}>{row.userStatus}</OwcTableCell>
      </OwcTableRow>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    let history = this.state.history;
    if (history.length > this.props.historyLengthLimit) {
      history = history.slice(0, this.props.historyLengthLimit);
    }

    let baselineRow=null;
    history.forEach(row=>{
      if (row.baselineConfirmationDate !== null) {
        if (baselineRow === null) {
          baselineRow=row;
        } else {
          if (convertToDate(row.baselineConfirmationDate) > convertToDate(baselineRow.baselineConfirmationDate)) {
            baselineRow=row;
          }
        }
      }
    })

    const style = { maxWidth:"97%", marginLeft:"1em", height:"20em" };

    return (
      <OwcTable key={"DeviceHistoryTable" + this.props.deviceId} size='small' elevated
          cardProps={{ style: style }}>
        <OwcTableHeader sticky>
          <OwcTableHeaderCell key={"ChangeDateHeader"} resizable>Change Date</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"SourceSerialHeader"} resizable>Serial Numbers</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"LabIDHeader"} resizable>Lab Id</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"MacAddressHeader"} resizable>Mac Address</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"DMSerialNoHeader"} resizable>Data Manager Serial No</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"RSDSerialNoHeader"} resizable>Remote Service Device Serial No</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"MaterialNameHeader"} resizable>Material Name</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"AccountNoHeader"} resizable>Account No</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"AccountNameHeader"} resizable>Account Name</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"InstAddressHeader"} resizable>Device Location</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"InstallDateHeader"} resizable>Install Date</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"PlacementTypeHeader"} resizable>Placement Type</OwcTableHeaderCell>
          <OwcTableHeaderCell key={"UserStatusHeader"} resizable>User Status</OwcTableHeaderCell>
        </OwcTableHeader>
        <OwcTableBody loading={!this.state.historyLoaded}>
          {history.map((row,index) => this.renderHistoryRow(row, index, baselineRow))}
        </OwcTableBody>
        <div slot="header" style={{ alignItems: 'center' }}>
          <OwcTypography>
            {this.state.history.length > this.props.historyLengthLimit
              ?(`Most Recent ${history.length} of ${this.state.history.length>999?">":""}${this.state.history.length} Recorded Lifecycle Event${this.state.history.length===1?"":"s"}`)
              :(`${history.length} Recorded Lifecycle Event${history.length===1?"":"s"}`)
            }
          </OwcTypography>
        </div>
      </OwcTable>
    );

  }
}

DeviceHistory.defaultProps = {
  deviceId: null,
  forceUpdate: null,
  historyLengthLimit: 100,
}

export default DeviceHistory
