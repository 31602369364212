import { OwcBanner } from '@one/react';
import DelayedTooltip from '../general/DelayedTooltip';
import React from 'react';
import '../../styles.scss';

/**
 * Renders a banner warning that this agreement is protected from editing
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class AgreementProtectedBanner extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    return (
      <OwcBanner key={"AgreementProtectedBanner"} 
        id={"AgreementProtectedBanner"}
        status="warning">
        {this.props.text}
        <DelayedTooltip key={"AgreementProtectedBannerTooltip"} 
            anchor={"AgreementProtectedBanner"}
            placement="bottom">
          {this.props.tooltiptext}
        </DelayedTooltip>
      </OwcBanner>      
    );
  }
}

AgreementProtectedBanner.defaultProps = {
  text: "This agreement is protected from editing",
  tooltiptext: "Edits to this agreement cannot be saved. The protection can be changed on the capture agreement screen",
}

export default AgreementProtectedBanner;
