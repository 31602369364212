import { OwcModalDialog, OwcButton, OwcTypography, OwcInput, OwcCheckbox, OwcComponentHeader, OwcComponentFooter} from '@one/react';
import DeviceHistory from './DeviceHistory.js';
import DeviceAccounts from './DeviceAccounts.js';
import DelayedTooltip from '../general/DelayedTooltip.js';
import React from 'react';
import '../../styles.scss';

import {configData} from "../../config.js";
import {formatDate, fetchSigned, isRecentMessage} from "../../shared/Utilities.js"

/**
 * Renders the device details screen
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class DeviceDetails extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      deviceId: props.deviceId,
      deviceIdentityLoaded: false,
      deviceIdentity: {},
      deviceJustExcluded: false,
      originalDeviceExcluded: null,
      originalComment: null,
      submissionState: "",
      visible: false,
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    const statechange = {};
    if (prevProps.deviceId !== this.props.deviceId) {
      if (this.props.deviceId !== null) {
        statechange.deviceId = this.props.deviceId;
      }

    }
    if (this.props.showNow !== null && prevProps.showNow !== this.props.showNow && isRecentMessage(this.props.showNow)) {
      statechange.visible = true;
    }
    if (Object.keys(statechange).length > 0) {
      this.setState(statechange,  () => this.loadDeviceIdentity(this.props.deviceId));
    }
    
  }


  /**
   * Loads the identity data to display for a device
   * @param {*} deviceId the device_id to load
   */
  loadDeviceIdentity(deviceId) {
    console.log("loading device identity for ", deviceId);
    this.setState({ deviceIdentityLoaded: false});

    fetchSigned(configData.DEVICE_DETAILS_API_URL + "identity/" + deviceId + "/")
    .then(res => res.json())
    .then(
      (result) => {
          if (result[0].comment === null) {
            result[0].comment = "";
          }
          this.setState({ deviceIdentity: result[0],  
                          originalDeviceExcluded:  result[0].excluded,
                          originalDeviceComment:  result[0].comment === null? "":result[0].comment,
                          deviceIdentityLoaded: true,
                          submissionState: "",
                          deviceJustExcluded: false});
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  /**
   * Updates the device identitiy record in the database with the comment and eclusion status
   */
  updateDeviceIdentity() {
    const identity = this.state.deviceIdentity;
    console.log(`Updating device_identity id ${identity.deviceId}, excluded = ${identity.excluded}, comment = '${identity.comment}'`);

    if (identity !== undefined && identity.deviceId  !== undefined)
    {
      if (identity.comment !== null &&
          identity.comment !== undefined &&
          identity.comment.trim() === "") {
        identity.comment = null;
      }
      this.setState({submissionState: "Saving ..."});

      const submitForm = () => {
        const url = configData.DEVICE_DETAILS_API_URL + "identity/" + identity.deviceId + "/";
        const method = 'PUT';
        return fetchSigned(url, {
          method: method,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(identity)
        })
      };

      submitForm().then((response) => {
        response.json().then((json) => {
          console.log(`API Response: ${JSON.stringify(json)}`);
          if (response.status === 200) // inserted successfully
          {
            console.log(`Update to device_identity saved successfully`);
            this.props.onExclusionChange(identity.deviceId, identity.excluded, identity.comment);
            this.setState({originalDeviceExcluded:identity.excluded,
                           originalDeviceComment: identity.comment,
                           submissionState: "Saved",
                           deviceJustExcluded: false})
          }
        });
      }).catch((error) => {
        console.error(`Error when saving update to device_identity ${error}`);
      });
    }
  }

  /**
   * Handles click events on the customer agreement
   * Passthrough method to this.props.onAgreementClick
   */
  handleAgreementClick(customerAgreementId) {
    console.log(`agreement onClick ${customerAgreementId} passed on by DeviceDetails`)
    this.props.onAgreementClick(customerAgreementId);
  }

  /**
   * Render the section displaying the device identity
   */
  renderIdentitySection() {
    const identity =this.state.deviceIdentity;
    const baseStyle = {display:"flex", flexDirection:"column", flexGrow:"2"};
    const excludedStyle = {...baseStyle};
    excludedStyle.backgroundColor=configData.COLOUR_EXCLUDED;
    return (
      <div style={{marginLeft:"1em", display:"flex", flexDirection:"row", justifyContent:"space-between", width:"calc(98% - 1em)"}}>
        <div style={{ display:"flex", flexDirection:"column", marginRight:"1em", marginTop:"0.8em"}}>
          <div style={{display:"flex", flexDirection:"row"}}>
            <div style={{ display:"flex", flexDirection:"column", marginRight:"1em"}}>
              <OwcTypography style={{ "fontWeight": "bold" }}>System Type:</OwcTypography>
              <OwcTypography style={{ "fontWeight": "bold" }}>Serial No:</OwcTypography>
            </div>
            <div style={{ display:"flex", flexDirection:"column"}}>
              <OwcTypography>{this.state.deviceIdentityLoaded === false? "" : identity.referenceSystemType + (identity.referenceSystemTypeIsActive===false?configData.REFDATA_DEPRECATED:"")}</OwcTypography>
              <OwcTypography>{this.state.deviceIdentityLoaded === false? "" : identity.serialNo}</OwcTypography>
            </div>
          </div>
        </div>
        <div style={{marginRight:"1em", marginTop:"0.8em"}}>
          <OwcTypography style={{ "fontWeight": "bold" }}>Last Upload Date:</OwcTypography>
        </div>
        <div style={{flexGrow:"2", marginTop:"0.8em"}}>
          <OwcTypography>{this.state.deviceIdentityLoaded === false? "" : formatDate(identity.lastUploadDate)}</OwcTypography>
        </div>
        <div style={(this.state.deviceIdentityLoaded === true && identity.excluded===true)?excludedStyle:baseStyle}>
          <div style={{display:"flex",  flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
            <OwcCheckbox
                key={"ExcludedCheckBox" }
                style={{"fontWeight": "bold"}}
                checked={this.state.deviceIdentityLoaded === false? false : identity.excluded}
                disabled={this.state.deviceIdentityLoaded === false}
                onValueChange={(ev) => {
                  if ((ev.type === "inputChange") && (ev.detail === null)) {
                    // don't update anything - prevent null entries
                    return;
                  }
                  if (String(ev.detail) !== String(this.state.deviceIdentity.excluded))
                  {
                    // set the value
                    let newIdentity = {...this.state.deviceIdentity};
                    newIdentity.excluded = ev.detail;
                    const excludedJustSet = (this.state.deviceIdentity.excluded === false && newIdentity.excluded === true);
                    const submissionState = (newIdentity.excluded === this.state.originalDeviceExcluded 
                                            && newIdentity.comment === this.state.originalDeviceComment)?
                                                ""
                                              :
                                              "Unsaved Changes";
                    this.setState({deviceIdentity: newIdentity,
                                  deviceJustExcluded: excludedJustSet,
                                  submissionState:submissionState});
                  }
                }} >
              <OwcTypography style={{ "fontWeight": "bold" }}>Excluded from mapping to agreements</OwcTypography>
            </OwcCheckbox>
            <OwcButton style={{ width: "fit-content", marginRight: "0.2em" }}
                onclick={()=>this.updateDeviceIdentity()} 
                disabled={this.state.submissionState !== "Unsaved Changes" ? true : false}>
              {this.state.submissionState === "Saving ..." ||  this.state.submissionState === "Saved"? this.state.submissionState : "Save Changes"}
            </OwcButton>
          </div>
          <div style={{display:"flex",  flexDirection:"row", alignItems:"center", marginTop: "-0.2em", padding:"5px 0 5px 0"}}>
            <OwcTypography style={{ "fontWeight": "bold", marginLeft: "1em", marginRight: "1em"}}>Comment:</OwcTypography>
            <OwcInput
              key={"DeviceComments"}
              value={this.state.deviceIdentityLoaded === false? "" : identity.comment}
              disabled={this.state.deviceIdentityLoaded === false}
              label="Comment"
              size="small"
              style={{width:"100%", marginRight: "0.2em"}}
              onBlur={(ev) => {
                if (ev.target.value !== null) {
                  const newComment =  ev.target.value.trim();
                  if (newComment !== this.state.deviceIdentity.comment) {
                    let newIdentity = {...this.state.deviceIdentity};
                    newIdentity.comment = newComment;
                    const submissionState = (
                      newIdentity.excluded === this.state.originalDeviceExcluded 
                      && newIdentity.comment === this.state.originalDeviceComment
                    ) ? "" : "Unsaved Changes";
                    this.setState({deviceIdentity: newIdentity,
                                  submissionState: submissionState});
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
 

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    const modalStyle = { maxWidth: '100%' };
    return (
      <OwcModalDialog 
          visible={this.state.visible}
          size="large"
          cardProps={{ style: modalStyle }}
          disableBackdropClick={this.props.disableBackdropClick} 
          disableEscapeKeydown={this.props.disableEscapeKeydown} 
          disableScrollLock={this.props.disableScrollLock} 
          onVisibleChange={(ev)=>{
              if (ev.detail === false) 
                {this.setState({visible: false})};
            }}>
        <div style={{display:"flex", alignItems: "stretch", flexDirection:"column"}}>
          {this.renderIdentitySection()}
          <DeviceHistory deviceId={this.props.deviceId} forceUpdate={this.props.showNow} />
          <br/>
          <DeviceAccounts deviceId={this.props.deviceId}
            onAgreementClick={(customerAgreementId)=>this.handleAgreementClick(customerAgreementId)}
            disableNavigation={this.props.disableNavigation}
            displayExcludedWarning={this.state.deviceJustExcluded && this.props.submissionState === "Unsaved Changes"}
            forceUpdate={this.props.showNow} />
        </div>

        <OwcComponentHeader slot="header">
          <OwcTypography style={{lineHeight:"0.2em"}} variant="title6">Device Details</OwcTypography>
        </OwcComponentHeader>
      
        <OwcComponentFooter slot="footer" style={{ display: 'flex', justifyContent: 'flex-end', padding:'30px 10px 10px 10px'}}>
          {this.props.closeButtonText
          ?(<div>
            <OwcButton style={{ marginLeft: "2em" }} 
                id={"DeviceDetailsCloseBtn"} 
                flat
                onClick={(ev) => {this.setState({visible: false})}}>
              {this.props.closeButtonText}
            </OwcButton>
            {
              this.props.closeButtonTooltip
              ? <DelayedTooltip 
                    key={"DeviceDetailsCloseBtnTooltip"} 
                    anchor={"DeviceDetailsCloseBtn"}
                    placement="right"
                    offset={[-15,-200]}>
                  {this.props.closeButtonTooltip}
                </DelayedTooltip>
              : ""
            }
            </div>)
          : ""
          }
        </OwcComponentFooter>
      </OwcModalDialog>
    );

  }
}

DeviceDetails.defaultProps = {
  deviceId: null,
  closeButtonText: "Close",
  closeButtonTooltip: "Close this overlay and return to the screen below",
  visible: true,
  disableBackdropClick: false,
  disableEscapeKeydown: false,
  disableScrollLock: false,
  disableNavigation: false,
  onAgreementClick:() => {},
  onExclusionChange:() => {}
}

export default DeviceDetails
